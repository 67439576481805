import axios from "axios";
import { useMutation } from "react-query";
import { END_POINT } from "config/environment";
import { changePasswordProps } from "../types";

export const createDate = async (props: changePasswordProps) => {
  const url = `${END_POINT.BASE_URL}/v1/change-password`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  const data: any = {
    old_password: props.old_password,
    password: props.password,
    password_confirmation: props.password_confirmation,
  };

  const response = await axios.post(url, data, config);
  return response;
};

export const useChangePassword = () => {
  return useMutation(createDate);
};
