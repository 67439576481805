import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import "antd/dist/antd.min.css";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
/*if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then(registration => console.log('Service Worker registered: ', registration.scope))
      .catch(error => console.log('Service Worker registration failed: ', error));
  });
}*/
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
