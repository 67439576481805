import { DashboardLayout } from "admin/components/layout/components/DashboardLayout";
import { Navbar } from "admin/components/layout/components/Navbar";
import checkIcon from "../assets/images/CheckIcon.svg";
import { AppButton } from "components/button/AppButton";

export const AdminPricing = () => {
  return (
    <DashboardLayout>
      <Navbar
        title="Pricing"
        description="We believe Arno should be accessible to everyone"
      />

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        <div className="rounded-md border shadow px-5 pb-7 pt-10 text-center bg-white">
          <h2 className="font-bold text-xl text-primary">NGN 1000/mth</h2>
          <div className="my-3">
            <h4 className="font-semibold text-base text-primary">Basic plan</h4>
            <h3 className="text-xs">Billed annually</h3>
          </div>
          <ul className="flex flex-col gap-2">
            {[1, 2, 3, 4, 5, 6, 7].map(() => (
              <li className="flex items-center gap-x-3 justify-center">
                <img src={checkIcon} alt="check icon" />
                <h3 className="text-sm">Access to all basic features</h3>
              </li>
            ))}
          </ul>
          <div className="mt-8">
            <AppButton
              label="Current plan"
              additionalClassNames={[
                "text-green-700 font-semibold w-full bg-green-100 py-2 rounded",
              ]}
            />
          </div>
        </div>
        <div className="rounded-md border shadow px-5 pb-7 pt-10 text-center bg-white">
          <h2 className="font-bold text-xl text-primary">NGN 1000/mth</h2>
          <div className="my-3">
            <h4 className="font-semibold text-base text-primary">Standard plan</h4>
            <h3 className="text-xs">Billed annually</h3>
          </div>
          <ul className="flex flex-col gap-2">
            {[1, 2, 3, 4, 5, 6, 7].map(() => (
              <li className="flex items-center gap-x-3 justify-center">
                <img src={checkIcon} alt="check icon" />
                <h3 className="text-sm">Access to all basic features</h3>
              </li>
            ))}
          </ul>
          <div className="mt-8">
          <AppButton
              label="Upgrade"
              additionalClassNames={[
                "text-white font-semibold w-full bg-primary py-2 rounded",
              ]}
            />
          </div>
        </div>
        <div className="rounded-md border shadow px-5 pb-7 pt-10 text-center bg-white">
          <h2 className="font-bold text-xl text-primary">NGN 1000/mth</h2>
          <div className="my-3">
            <h4 className="font-semibold text-base text-primary">Premium plan</h4>
            <h3 className="text-xs">Billed annually</h3>
          </div>
          <ul className="flex flex-col gap-2">
            {[1, 2, 3, 4, 5, 6, 7].map(() => (
              <li className="flex items-center gap-x-3 justify-center">
                <img src={checkIcon} alt="check icon" />
                <h3 className="text-sm">Access to all basic features</h3>
              </li>
            ))}
          </ul>
          <div className="mt-8">
            <AppButton
              label="Upgrade"
              additionalClassNames={[
                "text-white font-semibold w-full bg-primary py-2 rounded",
              ]}
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
