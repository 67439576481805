import axios from "axios";
import { useMutation } from "react-query";
import { END_POINT } from "config/environment";

export const banUser = async (props: any) => {
  const url = `${END_POINT.BASE_URL}/v1/administrator/banUpdate/${props.user_id}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  const data: any = {
    banned: props.banned,
  };

  const response = await axios.put(url, data, config);
  return response;
};

export const useBanUser = () => {
  return useMutation(banUser);
};