import axios from "axios";
import { useQuery } from "react-query";
import { END_POINT } from "config/environment";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { ITalent } from "../types";

// TO DO : need to exist in the general data entities and refactored
interface IGetDataProps {
  pagination?: {
    pageSize?: number;
    current?: number;
  };
  token?: string;
  sector?: string | null;
  experience?: string | null;
  gender?: string | null;
}

export const QUERY_KEY_FOR_TALENTS = "Talents";

const getTalents = async (
  props: IGetDataProps
): Promise<{ data: ITalent[]; total: number }> => {
  const { pagination, sector, experience, gender } = props;

  const url = `${END_POINT.BASE_URL}/v1/talents`;

  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
    params: {
      page: pagination?.current,
      limit: pagination?.pageSize,
      sector,
      experience,
      gender,
    },
  };

  const res = await axios.get(url, config);
  const fetchedData = res.data.data;

  const data: ITalent[] = fetchedData.map(
    (item: ITalent): ITalent => ({ ...item })
  );

  const ans = {
    data,
    total: res.data.meta.total,
  };
  
  return ans;
};

export const useFetchTalent = (props: IGetDataProps) => {
  const { token } = useGetUserInfo();
  const queryData = useQuery(
    [QUERY_KEY_FOR_TALENTS, props],
    () =>
      getTalents({
        ...props,
        token,
      }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
    }
  );

  return queryData;
};
