import axios from "axios";
import { useMutation } from "react-query";
import { ISwitchTeamsProps } from "types";
import { END_POINT } from "config/environment";

export const switchTeams = async (props: ISwitchTeamsProps) => {
  const url = `${END_POINT.BASE_URL}/v1/switch-teams`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  // necessary to make immediate changes when in  a central place when schema changes
  const data: any = {
    team_id: props.team_id
  };

  const response = await axios.post(url, data, config);
  return response;
};

export const useSwitchTeams = () => {
  return useMutation(switchTeams);
};
