import { useState, useEffect } from "react";
import "../assets/style.css";
import { END_POINT } from "config/environment";
import {useLocation} from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { useNavigate } from 'react-router-dom';
import { openNotification } from "utils/notification";
import { useGetUserInfo } from "hooks/useGetUserInfo";

export const ZoomOauthCallback = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { userInfo, token } = useGetUserInfo();

  useEffect(() => {
    fetch(`${END_POINT.BASE_URL}/v1/oauth/zoom-callback${location.search}`, {
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    })
    .then((response) => {
        setIsLoading(false);
        if (response.ok) {
            return response.json();
        }
        openNotification({
            title: 'Error',
            state: 'error',
            description: 'Something went wrong!',
            duration: 4.5,
        });
        navigate('/');
    })
    .then((data) => {
        openNotification({
            title: "Success",
            state: "success",
            description: "Authorized successfully",
            duration: 4.5,
        });
        navigate('/');
    })
    .catch((error) => {
        console.error(error);
        navigate('/');
    });
  }, []);

  return (
    <div className="flex justify-center items-center h-screen">
    {isLoading ? <ScaleLoader color="var(--app-color-primary)" /> : null}
  </div>
  );
};
