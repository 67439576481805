import { Form, Modal, Select, DatePicker, Input, Radio } from "antd";
import TextArea from "antd/es/input/TextArea";
import type { RadioChangeEvent } from 'antd';
import { AppButton } from "components/button/AppButton";
import { useEffect } from "react";
import { IdentifierProps } from "types";
import {
  generalValidationRules,
  textInputValidationRulesOpt,
} from "utils/formHelpers/validations";
import { IJobInvite } from "../types";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { openNotification } from "utils/notification";
import { useSentJobInvite } from "../hooks/useSentJobInvite";
import { useGetJobs } from "features/jobs/hooks/useGetJobs";

const options = [
  { label: 'Google meet', value: 'meet' },
  { label: 'Zoom meeting', value: 'zoom' },
];

export const SendInvite = ({ open,  handleClose, id }: IdentifierProps) => {
  const [form] = Form.useForm();
  const { token, userInfo } = useGetUserInfo();
  const { mutate, isLoading } = useSentJobInvite();
  const { data, isLoading: jobDataLoading } = useGetJobs({});
  // console.log(data?.data);

  const handleFormSubmit = (values: any) => {
    const date = values.interviewDate.map((item: any) =>
      item.date.format("YYYY-MM-DD hh:mm:ss")
    );

    const props: IJobInvite = {
      token,
      talent_id: id as unknown as string,
      listing_id: values.listing_id,
      interview_date: date,
      meeting_platform: values.meeting_platform,
      note: values.note,
      userInfo
    };
    mutate(props, {
      onError: (err: any) => {
        let errorTitle = "Error";
        let errorDescription = "An error occurred, please try again.";

        if (err.response && err.response.data &&  err.response.data.error) {
          errorTitle = "Error: " + err.response.data.error;
          errorDescription = err.response.data.description || "Please try again.";
        }

        openNotification({
          title: errorTitle,
          state: "error",
          description: errorDescription,
          duration: 6,
        });
      },
      onSuccess: (res: any) => {
        openNotification({
          title: "Success",
          state: "success",
          description: "Job invite sent Successfully",
          duration: 4.5,
        });
        form.resetFields();
        handleClose();
      },
    });
  };

  useEffect(() => {
    const defaultField = { date: "", time: "" };
    form.setFieldsValue({ interviewDate: [defaultField] });
  }, [form]);

  const handleAddField = () => {
    const fields = form.getFieldValue("interviewDate") || [];
    const newField = { date: "", time: "" };
    form.setFieldsValue({ interviewDate: [...fields, newField] });
  };

  const handleRemoveField = (index: number) => {
    const fields = form.getFieldValue("interviewDate") || [];
    form.setFieldsValue({
      interviewDate: fields.filter((_: any, i: number) => i !== index),
    });
  };
  return (
    <Modal
      style={{ top: 15 }}
      footer={false}
      open={open}
      onCancel={() => handleClose()}
      title="Send Invite"
    >
      <Form
        onFinish={handleFormSubmit}
        form={form}
        layout="vertical"
        requiredMark="optional"
      >
        <Form.Item name="talentId" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name="listing_id"
          label="Select Job"
          rules={generalValidationRules}
        >
          <Select
            loading={jobDataLoading}
            placeholder="Select"
            allowClear
            options={data?.data.map((item) => ({
              value: item.id,
              label: item.title,
            }))}
          />
        </Form.Item>

        <Form.Item
          name="meeting_platform"
          label="Meeting Platform"
          rules={generalValidationRules}
        >
          <Radio.Group
            options={options}
            onChange={()=>{}}
            //optionType="button"
            //buttonStyle="solid"
          />
        </Form.Item>

        <Form.List name="interviewDate">
          {(fields) => (
            <>
              {fields.map((field, index) => (
                <div className="flex items-center gap-x-2">
                  <div
                    key={field.key}
                    className="grid grid-cols-1 md:grid-cols-2 gap-x-5 w-full"
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, "date"]}
                      label="Date"
                      rules={generalValidationRules}
                    >
                      <DatePicker showTime style={{ width: "100%" }} />
                    </Form.Item>

                    {/* <Form.Item
                      {...field}
                      name={[field.name, "time"]}
                      label="Time"
                      rules={generalValidationRules}
                    >
                      <TimePicker style={{ width: "100%" }} />
                    </Form.Item> */}
                  </div>
                  <i
                    className="ri-delete-bin-line text-xl cursor-pointer"
                    onClick={() => handleRemoveField(index)}
                  ></i>
                </div>
              ))}

              <AppButton
                label="+ Add new date"
                handleClick={() => handleAddField()}
                variant="transparent"
              />
            </>
          )}
        </Form.List>

        <Form.Item
          name="note"
          label="Additional Note"
          rules={textInputValidationRulesOpt}
          className="mt-5"
        >
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item name="attachment" noStyle>
          <Input type="hidden" />
        </Form.Item>

        <div className="flex gap-x-3 justify-end mt-3">
          <AppButton
            handleClick={() => handleClose()}
            label="Cancel"
            variant="transparent"
          />
          <AppButton label="Send Invite" type="submit" isLoading={isLoading} />
        </div>
      </Form>
    </Modal>
  );
};
