/*import { useState, useEffect } from "react";
import "../assets/style.css";
import { END_POINT } from "config/environment";
import {useLocation} from "react-router-dom";*/
import { ScaleLoader } from "react-spinners";
/*import { useNavigate } from 'react-router-dom';
import { openNotification } from "utils/notification";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import axios from "axios";
*/
export const GoogleOauthCallback = () => {
 /* const location = useLocation();
  const navigate = useNavigate();
  const { userInfo, token } = useGetUserInfo();
  const [renderCount, setRenderCount] = useState(0);


  useEffect(() => {
    setRenderCount(prevCount => prevCount + 1);
  },[]);

  useEffect(() => {
    if (renderCount === 2) {
        const handleAuthCallbackWrapper = async () => {
            await handleAuthCallback();
          };
          handleAuthCallbackWrapper();
    }
  }, [renderCount]);


  const handleAuthCallback = async () => {
    try {
      const response = await axios.get(`${END_POINT.BASE_URL}/v1/oauth/google-callback${location.search}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
  
      const data = await response.data;
        openNotification({
          title: "Success",
          state: "success",
          description: "Authorized successfully",
          duration: 4.5,
        });
      navigate('/');
    } catch (error: any) {
        openNotification({
          title: 'Error',
          state: 'error',
          description: 'Something went wrong!',
          duration: 4.5,
        });
        navigate('/');
      
      
    } finally {
    }
  };
  
*/
  return (
    <div className="flex justify-center items-center h-screen">
    <ScaleLoader color="var(--app-color-primary)" />
  </div>
  );
};
