import chartLow from "../assets/images/ChartLow.svg";
import chartHigh from "../assets/images/ChartHigh.svg";

interface IProps {
  title: string;
  count: number;
  percentage: number;
}

export const HomeCard = ({ count, percentage, title }: IProps) => {
  const chatImage = percentage <= 10 ? chartLow : chartHigh;
  const color = percentage <= 10 ? "text-red-600" : "text-green-600";
  return (
    <div className="bg-white border rounded-md p-3 text-accent">
      <div className="flex justify-between items-center">
        <h3 className="text-base font-medium">{title}</h3>
        <i className="ri-more-2-fill"></i>
      </div>
      <h2 className="font-extrabold text-2xl pt-5">{count.toLocaleString()}</h2>

      <div className="flex justify-between items-end">
        <div className="flex items-center gap-3">
          <div className={`${color} flex text-sm items-center gap-2`}>
            <i className="ri-arrow-up-line"></i>
            <span>{percentage}%</span>
          </div>
          <div className="text-sm">
            vs last <br />
            month
          </div>
        </div>

        <div>
          <img src={chatImage} alt="chat" loading="lazy" />
        </div>
      </div>
    </div>
  );
};
