import axios from "axios";
import { useQuery } from "react-query";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { END_POINT } from "config/environment";
import { TGateways } from "admin/finance/types";

export const QUERY_KEY_FOR_GATWAYS = "gatways";

const getData = async (props: {
  token: string;
}): Promise<TGateways[]> => {
  const url = `${END_POINT.BASE_URL}/v1/payment-gateways?status=1`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  const res = await axios.get(url, config);
  const item: TGateways[] = res.data;
  return item;
};

export const useGetGateways = () => {
  const { token, userInfo } = useGetUserInfo();
  const queryData = useQuery(
    [QUERY_KEY_FOR_GATWAYS],
    () => getData({
        token,
    }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
    }
  );
  return queryData;
};
