import { DashboardLayout } from "admin/components/layout/components/DashboardLayout";
import { Navbar } from "admin/components/layout/components/Navbar";
import bg from "../assets/images/Image wrap outer.svg";
import { Tabs } from "antd";
import "../assets/style.css";
import { AppButton } from "components/button/AppButton";
import { useState } from "react";
import { ReportTalent } from "../components/ReportTalent";
import { useParams } from "react-router-dom";
import { useGetSingleTalent } from "../hooks/useFetchTalentDetails";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import avatar from "assets/images/default.webp";
import { BioInfo } from "features/talents/components/BioInfo";
import { ExSummary } from "features/talents/components/ExSummary";
import { EducationHistory } from "features/talents/components/EducationHistory";
import { InviteHistory } from "features/talents/components/InviteHistory";
import { WorkHistory } from "features/talents/components/WorkHistory";

export const AdminTalentDetails = () => {
  const params = useParams();
  const id = params.id;
  const { token } = useGetUserInfo();
  const [inviteModal, setInviteModal] = useState(false);
  const [reportModaL, setReportModaL] = useState(false);
  const { data: talentData, isLoading } = useGetSingleTalent({
    token,
    id: id as unknown as string,
  });
  const data = talentData?.data;
  
  return (
    <DashboardLayout container={false}>
      <ReportTalent
        open={reportModaL}
        handleClose={() => setReportModaL(false)}
      />
      <img src={data?.user?.cover_photo != null ? data?.user?.cover_photo : bg}  alt="bg" className="w-full" />
      <div className="Container">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-5">
          <div className="flex items-center gap-3">
            <img
              src={data?.dp != null ? data.dp : avatar}
              alt="avatar"
              className="h-28 w-28 rounded-full object-cover object-top border-2 border-white -mt-7"
            />
            <div>
              <h3 className="font-semibold text-lg pb-1">
                {data?.first_name} {data?.last_name}
              </h3>
              <h3 className="text-xs">{data?.email}</h3>
            </div>
          </div>
          <div>
            {/*<div className="flex gap-x-3 justify-start md:justify-end mt-3">
              <AppButton
                label="Report"
                variant="transparent"
                handleClick={() => setReportModaL(true)}
              />
              <AppButton
                label="Send Invite"
                handleClick={() => setInviteModal(true)}
              />
            </div>*/}
          </div>
        </div>
        <Tabs defaultActiveKey="1" className="" size="large">
          <Tabs.TabPane tab="Bio Information" key="1" className="tabBg">
            <BioInfo isLoading={isLoading} data={data ? data : null} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Executive Summary" key="2" className="tabBg">
            <ExSummary  isLoading={isLoading} data={data ? data : null}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Work History" key="3" className="tabBg">
            <WorkHistory
              isLoading={isLoading}
              data={data ? data?.work_histories : []} 
              volunteer={data ? data?.volunteer_experiences : []}            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Education History" key="4" className="tabBg">
            <EducationHistory
              isLoading={isLoading}
              data={data ? data?.education_histories : []}
              certifications={data ? data?.certifications :[]}            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Invite History" key="5" className="tabBg">
            <InviteHistory data={data ? data : null}/>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </DashboardLayout>
  );
};
