import axios from "axios";
import { useMutation } from "react-query";
import { END_POINT } from "config/environment";
import { IAcceptInvite } from "../types";

export const acceptInvite = async (props: IAcceptInvite) => {
  const url = `${END_POINT.BASE_URL}/v1/accept-invite`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  // necessary to make immediate changes when in  a central place when schema changes
  const data: any = {
    listing_invite_id: props.listing_invite_id,
    invite_date_id: props.invite_date_id,
    status: props.status,
  };

  const response = await axios.post(url, data, config);
  return response;
};

export const useAcceptInvite = () => {
  return useMutation(acceptInvite);
};
