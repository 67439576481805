import { Drawer, Form, InputNumber, Select } from "antd";
import { AppButton } from "components/button/AppButton";
import { GENDERS } from "constants/general";
//import { useGetSectors } from "features/jobs/hooks/useGetSectors";
import { useGlobalContext } from "stateManagers/GlobalContext";
import { IdentifierProps } from "types";
import { openNotification } from "utils/notification";

export const FilterModal = ({ open, handleClose }: IdentifierProps) => {
  //const { data: sectorData, isLoading: loadingSector } = useGetSectors();
  const { filterTalent, setFilterTalent } = useGlobalContext();
  const handleSubmit = (data: any) => {
    if (data.yearsOfEx /*|| data.sector*/ || data.gender) {
      setFilterTalent({
        ...filterTalent,
        //sector: data.sector,
        experience: data.yearsOfEx,
        gender: data.gender,
      });
    } else {
      openNotification({
        state: "error",
        title: "Error",
        description: "Please filter with at least 1 value",
        duration: 4.5,
      });
    }
  };
  return (
    <Drawer open={open} onClose={() => handleClose()} title="Filter Talent">
      <Form layout="vertical" onFinish={handleSubmit}>
        {/*<Form.Item name="sector" label="Sector">
          <Select
            loading={loadingSector}
            allowClear
            placeholder="Select"
            options={sectorData?.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
          />
        </Form.Item>*/}
        <Form.Item name="yearsOfEx" label="Years of Experience">
          <InputNumber className="w-full" placeholder="2" min={1} />
        </Form.Item>
        <Form.Item name="gender" label="Gender">
          <Select allowClear placeholder="Select" options={GENDERS} />
        </Form.Item>

        <div className="flex items-center justify-end gap-4">
          <AppButton type="reset" label="Reset" variant="transparent" />
          <AppButton type="submit" label="Apply" />
        </div>
      </Form>
    </Drawer>
  );
};
