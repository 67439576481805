import { Tabs } from "antd";
import { DashboardLayout } from "admin/components/layout/components/DashboardLayout";
import { Navbar } from "admin/components/layout/components/Navbar";
import { Pending } from "../components/talentInvites/Pending";
import { Progressed } from "../components/talentInvites/Progressed";
import { JobOffers } from "../components/talentInvites/JobOffers";
import { Declined } from "../components/talentInvites/Declined";
import { useFetchTalentInvites } from "../hooks/useFetchTalentInvites";
import { ScaleLoader } from "react-spinners";

export const AdminInvite = () => {
  const { data, isLoading } = useFetchTalentInvites();

  // Filter invites based on their status
  const pendingInvites = data
    ? data.filter((invite) => invite.status === 0)
    : [];
  const progressedInvites = data
    ? data.filter((invite) => invite.status === 1)
    : [];
  const declinedInvites = data
    ? data.filter((invite) => invite.status === 2)
    : [];

  return (
    <DashboardLayout>
      <Navbar title="Settings" description="" />
      
      <div className="flex justify-center mt-8">
        {isLoading ? <ScaleLoader color="var(--app-color-primary)" /> : null}
      </div>
    </DashboardLayout>
  );
};
