import { Modal, Radio } from "antd";
import { AppButton } from "components/button/AppButton";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { IdentifierProps } from "types";
import { openNotification } from "utils/notification";
import { useGetGateways } from "../hooks/useGetGateways";
import { BeatLoader } from "react-spinners";
import { usePaymentInit } from "../hooks/usePaymentInit";
import { useState } from "react";
import CurrencyFormatter from "utils/CurrencyFormatter";

export const SelectPaymentGateway = ({ open, handleClose, data }: any) => {
  const { mutate, isLoading } = usePaymentInit();
  const [planDuration, setPlanDuration] = useState('monthly');
  const [paymentGateways, setPaymentGateways] = useState<any>();
  const { token, userInfo  } = useGetUserInfo();
  const { data: gateways } = useGetGateways();

  const handleSubmit = () => {
    mutate(
      {
        plan: data.data.id,
        gateway: paymentGateways,
        upgrade: data.upgrade,
        plan_duration: planDuration,
        token,
      },
      {
        onError: (err: any) => {
          openNotification({
            title: "Error",
            state: "error",
            description: "Error occurred try again",
            duration: 4.5,
          });
        },
        onSuccess: (res: any) => {
          console.log(res);
          window.location.assign(res.data.url)
          handleClose(true);
        },
      }
    );
  };

  const handlePlanDurationChange = (e: any) => {
    setPlanDuration(e.target.value);
  };

  return (
    <Modal
      title="Select payment gateway"
      open={open}
      onCancel={() => handleClose(false)}
      footer={null}
      style={{ top: 15 }}
      width="40rem"
    >
      <p className="text-center">{isLoading && <BeatLoader color="#222" />}</p>
      
      <div className="p-4 border border-gray-200 rounded-md">
        <Radio.Group onChange={handlePlanDurationChange} value={planDuration}>
            {userInfo?.recruiter?.is_corporate !== 1 && <Radio value="monthly">Monthly</Radio>}
            <Radio value="quarterly">Quarterly</Radio>
            <Radio value="biannually">Biannually</Radio>
            <Radio value="annually">Annually</Radio>
        </Radio.Group> 
      </div>
        
      <h3 className="mt-8 font-semibold">Selected Payment gateway</h3>

        <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-2 text-center justify-center items-center w-1/2">
            {
            gateways?.map((item:any, i ) => (
              <div key={i} onClick={()=>setPaymentGateways(item)} className={`mt-6 shadow-sm w-full cursor-pointer rounded-md ${paymentGateways?.name === item.name && 'border-2 border-green-500 rounded-md'}`}>
                <img src={item.image} alt={item.name} className=" rounded-md w-full" />
              </div>
            ))
            }
        </div>

        <div className="mt-4 text-right">
          <h3>Total Amount: <span className="font-semibold text-xl"><CurrencyFormatter value={Number(data.data[planDuration])} decimalPrecision={0} /></span></h3>
        </div>

        <div className="mt-4 text-right">
            <AppButton disabled={!paymentGateways} type="submit" handleClick={()=> handleSubmit()} />
        </div>
    </Modal>
  );
};
function useGetQueryClient() {
  throw new Error("Function not implemented.");
}
