import { useMutation, useQueryClient } from "react-query";
import { useGetUserInfo } from "./useGetUserInfo";
import { handleDelete } from "utils/apiHelpers/handleDelete";
import { openNotification } from "utils/notification";

interface IDProps {
  deleteEndPointUrl: string;
  queryKey: string;
}

export const useDelete = ({ deleteEndPointUrl, queryKey }: IDProps) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(handleDelete);
  const { token } = useGetUserInfo();

  const removeData = (id: any) => {
    mutate(
      { id, token, deleteEndPointUrl },
      {
        onError: (err: any) => {
          openNotification({
            title: "Error",
            state: "error",
            description: err.response.data.message,
            duration: 4.5,
          });
        },
        onSuccess: (res: any) => {
          openNotification({
            title: "Success",
            state: "success",
            description: "Deleted Successfully",
            duration: 4.5,
          });

          queryClient.invalidateQueries({ queryKey: [queryKey] });
        },
      }
    );
  };

  return { removeData };
};
