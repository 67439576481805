import { Dropdown, Menu, Popconfirm } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { usePagination } from "hooks/usePagination";
import { useState } from "react";
import { QUERY_KEY_FOR_JOBS, useGetJobs } from "../hooks/useGetJobs";
import { IJobsProps } from "../types";
import { useDelete } from "hooks/useDelete";
import { routePath } from "config/routeMgt/routePath";
import { Link } from "react-router-dom";
import { useGlobalContext } from "stateManagers/GlobalContext";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useLikeJob } from "../hooks/useLikeJob";
import { openNotification } from "utils/notification";
import { useQueryClient } from "react-query";

interface JobsTableProps {
  handleJob: (id: number) => void;
  searchTerm: string;
}

const deleteEndPointUrl = "delete-job/";
export const JobsTable: React.FC<JobsTableProps> = ({
  handleJob,
  searchTerm,
}) => {
  const { filterJobState } = useGlobalContext();
  const { removeData } = useDelete({
    queryKey: QUERY_KEY_FOR_JOBS,
    deleteEndPointUrl,
  });
  const queryClient = useQueryClient();
  const { pagination, onChange } = usePagination();
  const { token, userInfo } = useGetUserInfo();
  const { mutate, isLoading:loading } = useLikeJob();
  const { data, isLoading } = useGetJobs({
    pagination,
    search: searchTerm,
    end_date: filterJobState.end_date,
    start_date: filterJobState.start_date,
    work_model: filterJobState.work_model,
    location_state: filterJobState.location_state,
    location_city: filterJobState.location_city,
  });


  const likeJob = (id: any)=>{

    const props: any = {
      token,
      id: id
    };

    mutate(props, {
      onError: (err: any) => {
        let errorTitle = "Error";
        let errorDescription = "An error occurred, please try again.";

        if (err.response && err.response.data &&  err.response.data.error) {
          errorTitle = "Error: " + err.response.data.error;
          errorDescription = err.response.data.description || "Please try again.";
        }

        openNotification({
          title: errorTitle,
          state: "error",
          description: errorDescription,
          duration: 6,
        });
      },
      onSuccess: (res: any) => {
        openNotification({
          title: "Success",
          state: "success",
          description: "success",
          duration: 4.5,
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_FOR_JOBS],
        });
      },
    });
  };


  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const columns: ColumnsType<IJobsProps> = [
    {
      title: "Name",
      dataIndex: "title",
      render: (_, val) => (
        <div>
          <Link
            to={routePath.jobDetails(val.id).path}
            className="font-medium text-primary block"
          >
            {val.title}
          </Link>
          <span>{val.level}</span>
        </div>
      ),
    },
    {
      title: "Company",
      dataIndex: "company_name",
    },
    {
      title: "Location",
      dataIndex: "location_city",
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (_, val) => <span className="capitalize">{val.type}</span>,
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
    },
    {
      title: "Years of Experience",
      dataIndex: "years_of_experience",
    },
    {
      title: "Action",
      dataIndex: "action",

      render: (_, val) => (
        <div>
          { userInfo?.recruiter ?
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                <Menu.Item key="1" onClick={() => handleJob(val.id as number)}>
                  Edit
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to={routePath.jobDetails(val.id).path}>View</Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Popconfirm
                  okButtonProps={{
                    style: { backgroundColor: '#f5222d', color: '#ffffff' }, // Custom styles
                  }} 
                    title="Delete job"
                    description="Are you sure to delete this job?"
                    onConfirm={() => removeData(val.id as number)}
                  >
                    Delete
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            }
          >
            <i className="ri-more-2-fill text-lg cursor-pointer"></i>
          </Dropdown>
          :
          <i onClick={()=>likeJob(val.id)} className={`${ val.has_liked === true ?'ri-heart-fill text-red-500' : 'ri-heart-line'} text-lg cursor-pointer`}></i>
          }
        </div>
      ),
    },
  ];

  return (
    <div>
      {hasSelected && (
        <div className="mt-7 flex items-center gap-3">
          <button className="button" style={{ background: "#D92D20" }}>
            Delete
          </button>
        </div>
      )}

    { userInfo?.recruiter ?

      <Table
        className="bg-white rounded-md shadow border mt-8"
        columns={columns}
        dataSource={data?.data}
        rowSelection={rowSelection}
        loading={isLoading}
        scroll={{ x: 550 }}
        pagination={{ ...pagination, total: data?.total }}
        onChange={onChange}
      />

      :
      <Table
        className="bg-white rounded-md shadow border mt-8"
        columns={columns}
        dataSource={data?.data}
        loading={isLoading}
        scroll={{ x: 550 }}
        pagination={{ ...pagination, total: data?.total }}
        onChange={onChange}
      />
    }
    </div>
  );
};
