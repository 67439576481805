import { JobsTable } from "./JobsTable";
import { useState } from "react";

export const JobList = (id:any)=>{

  const [jobId, setJobId] = useState<number>();
  const [jobFilter, setJobFilter] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleJob = (id: number) => {
    setJobId(id);
  };
  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  return (
    <JobsTable handleJob={handleJob} id={id} searchTerm={searchTerm} />
  );
};
