import axios from "axios";
import { useMutation } from "react-query";
import { END_POINT } from "config/environment";
import { IJobsProps } from "../types";

export const createJob = async (props: IJobsProps) => {

  const corporate_id = props?.userInfo?.current_team ? props?.userInfo?.current_team.corporate_id :'';

  const url = `${END_POINT.BASE_URL}/v1/jobs`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  // necessary to make immediate changes when in  a central place when schema changes
  const data: IJobsProps = {
    id: props.id,
    recruiter_id: props.recruiter_id,
    corporate_id: corporate_id,
    description: props.description,
    title: props.title,
    sector_id: props.sector_id,
    company_name: props.company_name,
    type: props.type,
    level: props.level,
    expiry_date: props.expiry_date,
    years_of_experience: props.years_of_experience,
    location_id: props.location_id,
    location_city: props.location_city,
    location_state: props.location_state,
    skills: props.skills,
    slot: props.slot
  };

  const response = await axios.post(url, data, config);
  return response;
};

export const useCreateJob = () => {
  return useMutation(createJob);
};
