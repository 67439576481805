import axios from "axios";
import { ISignInUserProps } from "../types";
import { END_POINT } from "config/environment";

export const loginUser = async (props: ISignInUserProps) => {
  const url = `${END_POINT.AUTH_URL}/administrator/login`;
  const data = { ...props };
  const response = await axios.post(url, data);
  return response;
};
