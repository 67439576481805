import { Modal, Steps, Progress } from "antd";
import { Link } from "react-router-dom";
import { IdentifierProps } from "types";

export const TalentProgress = ({ open, handleClose, data }: IdentifierProps) => {
  
  return (
    <Modal
      title="Get Started"
      open={open}
      onCancel={() => handleClose(false)}
      footer={null}
      width="45rem"
      centered
    >

    <p>Complete your registration to verify your account.</p>
    <div className="pt-4">
        <div className="py-4">
            <Progress percent={data?.total} />
        </div>
    
        <Steps
            direction="vertical"
            current={ data?.profile_section === 100 ? 1 :data?.profile_section === 100 && data?.documents == 100 ? 2 :  0}
            className="text-primary"
            items={[
              {
                title: (
                  <Link to="/settings/profile">
                    Complete your profile
                  </Link>
                ),
                description: "",
                status: data?.profile_section === 100 ? 'finish' : 'wait'
              },
              {
                title: (
                  <Link to="/settings/documents">
                    Upload your Documents
                  </Link>
                ),
                description: "",
                status: data?.documents === 100 ? 'finish' : 'wait'
              },
              {
                title: (
                  <Link to="/settings/personality-test">
                    Take Personality Test
                  </Link>
                ),
                description: "",
                status: data?.profile_section === 100 ? 'finish' : 'wait'
              },
            ]}
        />
        </div>
    </Modal>
    
  );
};
function useGetQueryClient() {
  throw new Error("Function not implemented.");
}
