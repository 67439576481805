import { Dropdown, Menu, Popconfirm } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { DashboardLayout } from "admin/components/layout/components/DashboardLayout";
import { Navbar } from "admin/components/layout/components/Navbar";
import { routePath } from "config/routeMgt/routePath";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  QUERY_KEY_FOR_SINGLE_FOLDER,
  useGetSingleFolder,
} from "../hooks/useGetSingleFolder";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { IAddTalent, talentList } from "../types";
import userDp from "assets/images/placeholderImage.jpeg";
import { openNotification } from "utils/notification";
import { useQueryClient } from "react-query";
import { LoadingOutlined } from "@ant-design/icons";
import { useAddAndRemoveTalent } from "../hooks/useAddAndRemoveTalent";
import { SendInvite } from "features/talents/components/SendInvite";

export const AdminSingleFolder = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [inviteModal, setInviteModal] = useState(false);
  const [talentId, setTalentId] = useState<string>();
  const params = useParams();
  const { mutate } = useAddAndRemoveTalent();
  const queryClient = useQueryClient();
  const id = params.id;
  const { token } = useGetUserInfo();
  const { data, isLoading } = useGetSingleFolder({
    id: id as unknown as number,
    token,
  });

  const sendInvite = (id: string) => {
    setInviteModal(true);
    setTalentId(id);
  };

  const IdToBePassed = (data?: string): string[] => {
    if (selectedRowKeys.length > 0) {
      return selectedRowKeys;
    }
    if (!data) {
      return [];
    }
    return [data];
  };
  const handleSubmit = (data?: string) => {
    const props: IAddTalent = {
      token,
      talent_id: IdToBePassed(data),
      folder_id: id as unknown as number,
      request_url: "remove-talents-from-folder",
    };
    openNotification({
      state: "info",
      title: "Wait a second ...",
      description: <LoadingOutlined />,
    });
    mutate(props, {
      onError: (err: any) => {
        let errorTitle = "Error";
        let errorDescription = "An error occurred, please try again.";

        if (err.response && err.response.data &&  err.response.data.error) {
          errorTitle = "Error: " + err.response.data.error;
          errorDescription = err.response.data.description || "Please try again.";
        }

        openNotification({
          title: errorTitle,
          state: "error",
          description: errorDescription,
          duration: 6,
        });
      },
      onSuccess: (res: any) => {
        openNotification({
          title: "Success",
          state: "success",
          description: "Talent removed from folder successfully",
          duration: 4.5,
        });
        queryClient.invalidateQueries([QUERY_KEY_FOR_SINGLE_FOLDER]);
        setSelectedRowKeys([]);
      },
    });
  };

  const hasSelected = selectedRowKeys.length > 0;
  const columns: ColumnsType<talentList> = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, val) => (
        <h2 className="font-medium">
          {val?.first_name} {val?.last_name}
        </h2>
      ),
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Avatar",
      dataIndex: "avatar",
      render: (_, val) => (
        <div>
          <img
            src={val?.dp ? val?.dp : userDp}
            alt="avatar"
            className="rounded-full h-10 w-10 object-cover object-top"
          />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",

      render: (_, val) => (
        <div>
          <Dropdown
            disabled={selectedRowKeys.length > 0}
            trigger={["click"]}
            overlay={
              <Menu>
                <Menu.Item key="1">
                  <Link to={routePath.talentDetails(1).path}>View profile</Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Popconfirm
                  okButtonProps={{
                    style: { backgroundColor: '#f5222d', color: '#ffffff' }, // Custom styles
                  }} 
                    title="Remove Talent"
                    description="Are you sure to delete this talent?"
                    onConfirm={() => handleSubmit(val.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    Remove from folder
                  </Popconfirm>
                </Menu.Item>
                <Menu.Item key="3" onClick={() => sendInvite(val.id)}>
                  Send meeting invite
                </Menu.Item>
              </Menu>
            }
          >
            <i className="ri-more-2-fill text-lg cursor-pointer"></i>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <SendInvite
        id={talentId}
        open={inviteModal}
        handleClose={() => setInviteModal(false)}
      />
      <Navbar
        title={isLoading ? "Loading.." : `${data?.name} folder`}
        description={isLoading ? "Loading.." : `${data?.name} folder details`}
      />
      <div className="bg-gray-100 px-4 py-3 mt-8 rounded-md flex justify-between items-center">
        <button
          className="transparentButton flex items-center gap-x-2"
          style={{ background: "white" }}
        >
          <i className="ri-download-cloud-2-line text-base"></i>
          <span>Download CSV</span>
        </button>

        <button
          className="transparentButton flex items-center gap-x-2"
          style={{ background: "white" }}
        >
          <i className="ri-filter-3-line text-base"></i>
          <span>Filters</span>
        </button>
      </div>
      {hasSelected && (
        <div className="mt-7 flex items-center gap-3">
          <Popconfirm
                  okButtonProps={{
                    style: { backgroundColor: '#f5222d', color: '#ffffff' }, // Custom styles
                  }} 
            title={
              IdToBePassed().length > 1 ? "Remove Talents" : "Remove Talent"
            }
            description={
              IdToBePassed().length > 1
                ? "Are you sure to delete these talents?"
                : "Are you sure to delete this talent?"
            }
            onConfirm={() => handleSubmit()}
            okText="Yes"
            cancelText="No"
          >
            <button className="button" style={{ background: "#D92D20" }}>
              Remove from folder
            </button>
          </Popconfirm>
        </div>
      )}

      <Table
        className="bg-white rounded-md shadow border mt-8"
        columns={columns}
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys: React.Key[], rows) => {
            setSelectedRowKeys(rows.map((row) => row.id));
          },
        }}
        dataSource={
          data?.talents
            ? data?.talents.map((item) => ({ ...item, key: item.id }))
            : []
        }
        scroll={{ x: 550 }}
        loading={isLoading}
      />
    </DashboardLayout>
  );
};
