import { Form, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { AppButton } from "components/button/AppButton";
import { IdentifierProps } from "types";
import {
  generalValidationRules,
  textInputValidationRulesOpt,
} from "utils/formHelpers/validations";

export const ReportTalent = ({ open, handleClose }: IdentifierProps) => {
  return (
    <Modal
      style={{ top: 20 }}
      footer={false}
      open={open}
      onCancel={() => handleClose()}
      title="Report Talent"
    >
      <Form layout="vertical" requiredMark="optional" className="mt-5">
        <Form.Item
          name="reasons"
          label="Reason for report"
          rules={generalValidationRules}
        >
          <Select
            allowClear
            placeholder="Select"
            options={[
              { value: 1, label: "Harassment or bullying" },
              { value: 2, label: "Impersonation or fake accounts" },
            ]}
          />
        </Form.Item>

        <Form.Item
          name="note"
          label="Explanation"
          rules={textInputValidationRulesOpt}
        >
          <TextArea rows={3} />
        </Form.Item>
      </Form>

      <AppButton type="submit" />
    </Modal>
  );
};
