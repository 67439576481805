import { MinusCircleOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Tooltip } from "antd";
import { AppButton } from "components/button/AppButton";
import { useCreateCareerInterest } from "features/settings/hooks/useCreateCareerInterest";
import { QUERY_KEY_FOR_CAREER_INTEREST } from "features/settings/hooks/useGetCareerInterest";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useQueryClient } from "react-query";
import { IdentifierProps } from "types";
import { textInputValidationRules } from "utils/formHelpers/validations";
import { openNotification } from "utils/notification";

export const AddCareerInterest = ({ open, handleClose }: IdentifierProps) => {
  const { mutate, isLoading } = useCreateCareerInterest();
  const { token, userInfo } = useGetUserInfo();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const handleSubmit = (data: any) => {
    const name = data?.name.map((item: any) => item.name);
    mutate(
      {
        name,
        token,
        talent_id: userInfo?.talent?.id,
      },
      {
        onError: (err: any) => {
          let errorTitle = "Error";
          let errorDescription = "An error occurred, please try again.";
  
          if (err.response && err.response.data &&  err.response.data.error) {
            errorTitle = "Error: " + err.response.data.error;
            errorDescription = err.response.data.description || "Please try again.";
          }
  
          openNotification({
            title: errorTitle,
            state: "error",
            description: errorDescription,
            duration: 6,
          });
        },
        onSuccess: (res: any) => {
          openNotification({
            title: "Success",
            state: "success",
            description: "Career Interest created successfully",
            duration: 4.5,
          });
          queryClient.invalidateQueries([QUERY_KEY_FOR_CAREER_INTEREST]);
          form.resetFields();
          handleClose();
        },
      }
    );
  };
  return (
    <Modal
      title="Add Career Interest"
      open={open}
      onCancel={() => handleClose(false)}
      footer={null}
      style={{ top: 15 }}
    >
      <Form
        layout="vertical"
        className="mt-4"
        form={form}
        onFinish={handleSubmit}
      >
        <Form.List name="name">
          {(fields, { add, remove }) => (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-4">
              {fields.map(({ key, name, ...restField }) => (
                <div key={key} className="flex items-baseline gap-1">
                  <Form.Item
                    {...restField}
                    name={[name, "name"]}
                    rules={textInputValidationRules}
                  >
                    <Input placeholder="Enter name" className="w-full" />
                  </Form.Item>

                  <Tooltip title="Remove">
                    <MinusCircleOutlined
                      className="text-base pl-1"
                      onClick={() => remove(name)}
                    />
                  </Tooltip>
                </div>
              ))}
              <Form.Item>
                <AppButton
                  variant="transparent"
                  label="Add +"
                  handleClick={() => add()}
                />
              </Form.Item>
            </div>
          )}
        </Form.List>

        <AppButton type="submit" isLoading={isLoading} />
      </Form>
    </Modal>
  );
};
function useGetQueryClient() {
  throw new Error("Function not implemented.");
}
