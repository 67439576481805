import { Form, Input, Modal, Select } from "antd";
import { AppButton } from "components/button/AppButton";
import { IdentifierProps } from "types";
import {
    emailValidationRules,
  generalValidationRules,
  textInputValidationRules,
} from "utils/formHelpers/validations";
import { useGetRoles } from "../hooks/useGetRoles";
import { openNotification } from "utils/notification";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useAddUser } from "../hooks/useAddUser";
import { IUsers } from "../types";
import { QUERY_KEY_FOR_USERS } from "../hooks/useFetchUsers";
import { useQueryClient } from "react-query";

export const AddUser = ({ open, handleClose }: IdentifierProps) => {
  const { data, isLoading: rolesDataLoading } = useGetRoles({});
  const [form] = Form.useForm();
  const { token } = useGetUserInfo();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useAddUser();
  
  const handleFormSubmit = (values: any) => {
    const props: IUsers = {
      token,
      name: values.name,
      email: values.email,
      roles: values.role
    };
    mutate(props, {
      onError: () => {
        openNotification({
          title: "Error",
          state: "error",
          description: "Error occurred try again",
          duration: 6.0,
        });
      },
      onSuccess: () => {
        openNotification({
          title: "Success",
          state: "success",
          description: "Added Successfully",
          duration: 4.5,
        });
        form.resetFields();
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_FOR_USERS],
        });
        handleClose();
      },
    });
  };

  return (
    <Modal
      style={{ top: 20 }}
      footer={false}
      open={open}
      onCancel={() => handleClose()}
      title="Add new Admin"
    >
      <Form layout="vertical" requiredMark="optional" className="mt-5"
        onFinish={handleFormSubmit}
        form={form}
      >
    
        <Form.Item
            name="name"
            label="Full Name"
            rules={generalValidationRules}
        >
            <Input placeholder="Please enter full name" />
        </Form.Item>

        <Form.Item
            name="email"
            label="Email"
            rules={emailValidationRules}
        >
            <Input placeholder="Please enter user's email address" />
        </Form.Item>
            
        <Form.Item
          name="role"
          label="Select role"
          rules={generalValidationRules}
        >
          <Select
            loading={rolesDataLoading}
            placeholder="Select"
            allowClear
            options={data?.data.map((item:any) => ({
              value: item.name,
              label: item.name,
            }))}
          />
        </Form.Item>
        <AppButton label="Submit" type="submit" isLoading={isLoading}  />
      </Form>
    </Modal>
  );
};