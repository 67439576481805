import axios from "axios";
import { useMutation } from "react-query";
import { ICreateProject } from "../types";
import { END_POINT } from "config/environment";

export const createProject = async (props: ICreateProject) => {
  const url = `${END_POINT.BASE_URL}/v1/save-project`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  // necessary to make immediate changes when in  a central place when schema changes
  const data: any = {
    talent_id: props.talent_id,
    title: props.title,
    description: props.description,
    link: props.link,
  };

  const response = await axios.post(url, data, config);
  return response;
};

export const useCreateProject = () => {
  return useMutation(createProject);
};
