import axios from "axios";
import { useMutation } from "react-query";
import { ICreateWorkHistory } from "../types";
import { END_POINT } from "config/environment";

export const createVolunteerExperience = async (props: ICreateWorkHistory) => {
  const url = `${END_POINT.BASE_URL}/v1/save-volunteer-experience`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  // necessary to make immediate changes when in  a central place when schema changes
  const data: any = {
    id: props.id,
    talent_id: props.talent_id,
    title: props.title,
    sector_id: props.sector_id,
    company: props.company,
    start_date: props.start_date,
    end_date: props.end_date,
    is_current: props.is_current,
    description: props.description,
  };

  const response = await axios.post(url, data, config);
  return response;
};

export const useCreateVolunteerExperience = () => {
  return useMutation(createVolunteerExperience);
};
