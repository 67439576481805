import axios from "axios";
import { useQuery } from "react-query";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { END_POINT } from "config/environment";
import { IJobsProps } from "admin/jobs/types";
export const QUERY_KEY_FOR_JOBS = "jobs";

// TO DO : need to exist in the general data entities and refactored
interface IGetDataProps {
  pagination?: {
    pageSize?: number;
    current?: number;
  };
  token?: string;
  recruiter_id: any;
  search?: string;
  start_date?: string | null;
  end_date?: string | null;
  work_model?: string | null;
}

const getData = async (
  props: IGetDataProps
): Promise<{ data: IJobsProps[]; total: number }> => {
  const { pagination, recruiter_id, search, end_date, start_date, work_model } =
    props;
  const url = `${END_POINT.BASE_URL}/v1/administrator/recruiter-jobs/${recruiter_id?.id}`;

  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
    params: {
      q: search,
      page: pagination?.current,
      limit: pagination?.pageSize,
      end_date,
      start_date,
      work_model,
    },
  };

  const res = await axios.get(url, config);

  const fetchedData = res.data.data;
  const data: IJobsProps[] = fetchedData.map(
    (item: IJobsProps): IJobsProps => ({ ...item })
  );
  const ans = {
    data,
    total: res.data.total,
  };
  return ans;
};

export const useGetJobs = ({
  pagination,
  recruiter_id,
  search,
  end_date,
  start_date,
  work_model,
}: IGetDataProps) => {
  const { token, userInfo } = useGetUserInfo();
  const queryData = useQuery(
    [QUERY_KEY_FOR_JOBS, pagination, recruiter_id, search, end_date, start_date, work_model],
    () =>
      getData({
        search,
        pagination,
        token,
        recruiter_id,
        end_date,
        start_date,
        work_model,
      }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
    }
  );

  return queryData;
};
