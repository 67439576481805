import { IRecruiter } from "../types";
import { Skeleton } from "antd";

export const CompanyInfo: React.FC<{
  data: any;
  isLoading?: boolean;
}> = ({ data, isLoading }) => {
  return (
    <Skeleton active loading={isLoading}>
      <div className="flex flex-col gap-4">
        {/* <div>
        <h3 className="text-base font-medium">Phone number</h3>
        <h4>+234 904747893</h4>
      </div> */}
        <div>
          <h3 className="text-base font-medium">Company Name</h3>
          <h4>{data?.company_name}</h4>
        </div>

        <div>
          <h3 className="text-base font-medium">RC Number</h3>
          <h4>{data?.rc_number}</h4>
        </div>

        {/*<div>
          <h3 className="text-base font-medium">Gender</h3>
          <h4>{data?.gender}</h4>
        </div>
         <div>
        <h3 className="text-base font-medium">Birthday</h3>
        <h4>June 5, 2022</h4>
      </div> */}
        {/* <div>
        <h3 className="text-base font-medium">Address</h3>
        <h4>No2 united Estate, Sangotedo.</h4>
      </div>
        <div>
          <h3 className="text-base font-medium pb-2">Career Interest</h3>
          {data?.career_interests.map((item: any) => (
            <div className="mb-4 flex items-center gap-2">
              <button className="bg-white shadow-sm border rounded px-3 py-1 font-medium">
                {item.name}
              </button>
            </div>
          ))}
        </div> */}
      </div>
    </Skeleton>
  );
};
