import { IRecruiter } from "../types";
import { Skeleton } from "antd";

export const BioInfo: React.FC<{
  data: any;
  isLoading?: boolean;
}> = ({ data, isLoading }) => {
  return (
    <Skeleton active loading={isLoading}>
      <div className="flex flex-col gap-4">
        {/* <div>
        <h3 className="text-base font-medium">Phone number</h3>
        <h4>+234 904747893</h4>
      </div> */}
        <div>
          <h3 className="text-base font-medium">Email Address</h3>
          <h4>{data?.email}</h4>
        </div>
        
        { data?.phone &&
          <div>
            <h3 className="text-base font-medium">Phone Number</h3>
            <h4>{data?.phone}</h4>
          </div>
        }

        { data?.country &&
          <div>
            <h3 className="text-base font-medium">Country</h3>
            <h4>{data?.country}</h4>
          </div>
        }

        { data?.state &&
          <div>
            <h3 className="text-base font-medium">State</h3>
            <h4>{data?.state}</h4>
          </div>
        }

        { data?.city &&
          <div>
            <h3 className="text-base font-medium">City</h3>
            <h4>{data?.city}</h4>
          </div>
        }

        { data?.city &&
          <div>
            <h3 className="text-base font-medium">City</h3>
            <h4>{data?.city}</h4>
          </div>
        }

        { data?.linkedin &&
          <div>
            <h3 className="text-base font-medium">Linkedin</h3>
            <h4>{data?.linkedin}</h4>
          </div>
        }

        { data?.facebook &&
          <div>
            <h3 className="text-base font-medium">Facebook</h3>
            <h4>{data?.facebook}</h4>
          </div>
        }

        { data?.x &&
          <div>
            <h3 className="text-base font-medium">x (twitter)</h3>
            <h4>{data?.x}</h4>
          </div>
        }

        { data?.github &&
          <div>
            <h3 className="text-base font-medium">Github</h3>
            <h4>{data?.github}</h4>
          </div>
        }

        { data?.behance &&
          <div>
            <h3 className="text-base font-medium">Behance</h3>
            <h4>{data?.behance}</h4>
          </div>
        }     
        { data?.instagram &&
          <div>
            <h3 className="text-base font-medium">Instagram</h3>
            <h4>{data?.instagram}</h4>
          </div>
        } 

        {/* <div>
        <h3 className="text-base font-medium">Birthday</h3>
        <h4>June 5, 2022</h4>
      </div> */}
        {/* <div>
        <h3 className="text-base font-medium">Address</h3>
        <h4>No2 united Estate, Sangotedo.</h4>
      </div> */}
      </div>
    </Skeleton>
  );
};
