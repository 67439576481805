import { DashboardLayout } from "components/layout/components/DashboardLayout";
import { Navbar } from "components/layout/components/Navbar";
import { PricingComponent } from "../components/PricingComponent";

export const Pricing = () => {
  return (
    <DashboardLayout>
      <Navbar
        title="Pricing"
        description="We believe Arno should be accessible to everyone"
      />
      <PricingComponent/>
    </DashboardLayout>
  );
};
