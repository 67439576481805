import axios from "axios";
import { useMutation } from "react-query";
import { END_POINT } from "config/environment";

export const mark = async (props: any) => {

  const url = `${END_POINT.BASE_URL}/v1/notifications/${props.id}/mark-as-read`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  const response = await axios.get(url, config);
  return response;
};

export const useMarkNotificationRead = () => {
  return useMutation(mark);
};
