import { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { Empty } from "antd";

export const RecruiterChart = ({stats}:any) => {
  const { userInfo } = useGetUserInfo();
  const height = 350;
  const series = [
    
    {
      "name":"Sent Messages",
      type: 'column',
      data: [90, 70, 85, 60, 80, 70, 90, 75, 60, 80, 50, 80],
    },
    {
      "name":"Received Messages",
      type: 'column',
      data: [50, 80, 96, 72, 82, 65, 87, 70, 80, 79, 67, 84],
    }
    /*{
      "name":"2023",
      data: [96, 72, 82, 65, 87, 70, 80, 79, 67, 84],
    },
    {
      "name":"2024",
      data: [88, 71, 80, 84, 84, 71, 86, 77, 75, 89],
    },*/
  ];

  const interaction = [
    {
      "name":"Views",
      data: stats?.stats.monthly_interations.views,
    },

    {
      "name":"Likes",
      data: stats?.stats.monthly_interations.likes,
    }

  ];
  
  const options : any = {
    chart: {
      toolbar: {
        show: false,
      },
      stacked: false
    },

    plotOptions: {
      bar: {
        columnWidth: "60%"
      }
    },
    
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center'
    },
    /*stroke: {
      curve: "smooth",
      width: 3,
    },*/
    colors: ["#28d14c", "#4669FA", "#0ce7fa", "#ffb300", "#bb00ff"],
    tooltip: {
      theme: "dark",
    },
    grid: {
      show: false,
      borderColor: "#e2e8f0",
      strokeDashArray: 10,
      position: "back",
    },
    fill: {
      type: "gradient",
      colors: ["#28d14c", "#4669FA", "#0ce7fa",  "#ffb300", "#bb00ff"],
    },
    yaxis: {
      show: false,
      labels: {
        style: {
          colors: "#475569",
          fontFamily: "Inter",
        },
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          colors: "#475569",
          fontFamily: "Inter",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  };

  const donut = {
    series: [stats?.stats?.genderShortlist?.male_percentage ? stats?.stats.genderShortlist.male_percentage:0, stats?.stats?.genderShortlist?.female_percentage ? stats?.stats.genderShortlist.female_percentage:0],
    options: {
      colors: ["#0ce7fa","#4669FA", "#ffb300", "#bb00ff", "#28d14c"],
      labels: ['Male', 'Female'],
      fill: {
        type: "gradient",
        colors: ["#0ce7fa", "#4669FA", "#ffb300", "#bb00ff", "#28d14c"],
      },
    }
  }

  const pie = {
    options: {
      colors: ["#0ce7fa","#4669FA", "#ffb300", "#bb00ff", "#28d14c"],
      labels: ['Male', 'Female'],
      fill: {
        type: "gradient",
        colors: ["#0ce7fa", "#4669FA", "#ffb300", "#bb00ff", "#28d14c"],
      },
    },
    series: [stats?.stats?.genderOffer?.male_percentage ? stats?.stats.genderOffer.male_percentage:0, stats?.stats?.genderOffer?.female_percentage ? stats?.stats.genderOffer.female_percentage:0],


  }

  const pieAllZero = pie.series.every((value: any) => value === 0);
  const donutAllZero = donut.series.every((value: any) => value === 0);

  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 mb-4">
        <div className="bg-white rounded-lg shadow-md h-full p-6 lg:col-span-3">
          <div className="pb-6">
            <div>
              <h3 className="text-base font-bold">Hiring Metrics</h3>
            </div>
          </div>
          <div className="relative">
            <div>
              <ul className="p-2 mt-2 space-y-2 w-full">
                <li>
                  <div className="flex w-full py-1 px-3 text-sm font-semibold rounded">
                    <span className="self-center rounded-full w-[42px] h-[42px] bg-gray-100 relative text-center p-2">
                      <i className="ri-check-fill text-xl text-indigo-500"></i>
                    </span>
                    <div className="ml-6 pl-2 pt-1 text-base text-start">
                      <div className="mb-1 text-gray-800">Offer Acceptance Rate</div>
                      <div className="text-gray-500 text-xs hidden sm:block">Percentage of job offers accepted by candidates.</div>
                    </div>
                    <div className="ml-auto pl-2 pt-1 text-base text-end">
                      <div className="font-bold text-green-500">{stats?.stats.acceptanceRate}%</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="flex w-full py-1 px-3 text-sm font-semibold rounded">
                    <span className="self-center rounded-full bg-gray-100 relative text-center p-2">
                    <i className="ri-thumb-down-line text-xl text-red-500"></i>
                    </span>
                    <div className="ml-6 pl-2 pt-1 text-base text-start">
                      <div className="mb-1 text-gray-800">Offer Decline Rate</div>
                      <div className="text-gray-500 text-xs hidden sm:block">Percentage of job offers declined by candidates</div>
                    </div>
                    <div className="ml-auto pl-2 pt-1 text-base text-end">
                      <div className="font-bold text-red-500">{stats?.stats.offerDeclineRate}%</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="flex w-full py-1 px-3 text-sm font-semibold rounded">
                    <span className="self-center rounded-full bg-gray-100 relative text-center p-2">
                    <i className="ri-user-2-fill text-xl text-indigo-500"></i>
                    </span>
                    <div className="ml-6 pl-2 pt-1 text-base text-start">
                      <div className="mb-1 text-gray-800">Hires</div>
                      <div className="text-gray-500 text-xs hidden sm:block">Number of candidates hired through the platform</div>
                    </div>
                    <div className="ml-auto pl-2 pt-1 text-base text-end">
                      <div className="font-bold text-green-500">{stats?.stats.number_of_hires}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="flex w-full py-1 px-3 text-sm font-semibold rounded">
                    <span className="self-center rounded-full bg-gray-100 relative text-center p-2">
                    <i className="ri-briefcase-4-line text-xl text-indigo-500"></i>
                    </span>
                    <div className="ml-6 pl-2 pt-1 text-base text-start">
                      <div className="mb-1 text-gray-800">Job Vacancy Duration</div>
                      <div className="text-gray-500 text-xs hidden sm:block">Average length of time job positions remain open</div>
                    </div>
                    <div className="ml-auto pl-2 pt-1 text-base text-end">
                      <div className="font-bold text-green-500">{stats?.stats.jobVacancyDuration}</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="flex w-full py-1 px-3 text-sm font-semibold rounded">
                    <span className="self-center rounded-full bg-gray-100 relative text-center p-2">
                    <i className="ri-briefcase-4-fill text-xl text-indigo-500"></i>
                    </span>
                    <div className="ml-6 pl-2 pt-1 text-base text-start">
                      <div className="mb-1 text-gray-800">Job Posting Fill Rate</div>
                      <div className="text-gray-500 text-xs hidden sm:block"> Percentage of job postings successfully filled</div>
                    </div>
                    <div className="ml-auto pl-2 pt-1 text-base text-end">
                      <div className="font-bold text-green-500">{stats?.stats.jobPostingFillRate}%</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="max-w-full lg:col-span-2">
          <div className="bg-white rounded-lg shadow-md h-full p-6">
            <div className="pb-6">
              <h3 className="text-base font-bold">Diversity of Shortlisted candidates</h3>
            </div>
            <div className="relative">
              {
                donutAllZero ? 
                <Empty/>:
                <Chart options={donut.options} series={donut.series} type={userInfo?.type === "recruiter" && userInfo?.recruiter?.is_corporate !== 1 ? "pie": "donut"} height={height} />
              }
              </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
        
      <div className="max-w-full">
          <div className="bg-white rounded-lg shadow-md h-full p-6">
            <div className="pb-6">
              <h3 className="text-base font-bold">Diversity of Hires</h3>
            </div>
            <div className="relative">
              {
                pieAllZero ? 
                  <Empty/>:
                <Chart options={pie.options} series={pie.series} type={userInfo?.type === "recruiter" && userInfo?.recruiter?.is_corporate !== 1 ? "pie": "donut"} height={height} />
              }
            </div>
          </div>
        </div>

        <div className="max-w-full">
          <div className="bg-white rounded-lg shadow-md h-full p-6">
            <div className="pb-6">
              <h3 className="text-base font-bold">Candidate Interactions</h3>
            </div>
            <div className="relative">
              <Chart options={options} series={interaction} type="area" height={height} />
            </div>
          </div>
        </div>
      </div>

      {userInfo?.type === "recruiter" && userInfo?.recruiter?.is_corporate !== 1 &&
        <div className="grid grid-cols-1 lg:grid-cols-1 gap-4 mb-4">
          <div className="max-w-full">
            <div className="bg-white rounded-lg shadow-md h-full p-6">
              <div className="pb-6">
                <h3 className="text-base font-bold">Messages Sent and Received</h3>
              </div>
              <div className="relative">
                <Chart options={options} series={series} type="bar" height={height} />
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};
