import { DashboardLayout } from "admin/components/layout/components/DashboardLayout";
import { Navbar } from "admin/components/layout/components/Navbar";
import { UsersTable } from "../components/UsersTable";
import { useState } from "react";
import { AddUser } from "../components/AddUser";
import { hasPermission } from "utils/hasPermission";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useNavigate } from "react-router-dom";
import { AppButton } from "components/button/AppButton";

export const AdminUsers = () => {
  const [addUserModal, setAddUserModaL] = useState(false);
  const { userInfo } = useGetUserInfo();
  const navigate = useNavigate();

  const hasPermissionFor = (...permissionNames: string[]): boolean => {
    return permissionNames.some(permissionName =>
      hasPermission(userInfo, permissionName)
    );
  };

  return (
    <DashboardLayout>
      <Navbar title="Users" description="View and manage other admin users" />
      <AddUser open={addUserModal} handleClose={() => setAddUserModaL(false)} />
      
      <div className="bg-gray-100 px-4 py-3 mt-8 rounded-md flex justify-between items-center">
        <div>

        </div>

        { hasPermissionFor('Manage Admin users') &&
          <div className="flex justify-end">
            
            <AppButton
                label="Manage Roles"
                variant="transparent"
                handleClick={() =>  navigate('roles')}
              />

            <button
              className="button flex items-center gap-x-2"
              onClick={()=>setAddUserModaL(true)}
            >
              <i className="ri-user-add-line text-md"></i>
              <span>Add New</span>
            </button>
          </div>
        }
      </div>

      <UsersTable />
    </DashboardLayout>
  );
};
