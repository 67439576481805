import { AppButton } from "components/button/AppButton";
import { DashboardLayout } from "components/layout/components/DashboardLayout";
import { Navbar } from "components/layout/components/Navbar";
import { MemberTable } from "../components/MemberTable";
import { useState } from "react";
import { AddMember } from "../components/AddMember";

const Team = () => {
  const [addNew, setAddNew] = useState(false);
   
  return (
    <DashboardLayout>
      <AddMember open={addNew} handleClose={() => setAddNew(false)} />
      <Navbar title="Team" description="Invite or manage your team members" />
      <div className="bg-gray-100 px-4 py-3 mt-8 rounded-md">
        <div className="flex gap-3 flex-col md:flex-row md:justify-between md:items-center">
          <div className="flex items-center">
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none ">
                <i className="ri-search-line text-xl text-gray-500"></i>
              </div>
              <input
                type="text"
                className=" border focus:outline-none border-gray-300 text-gray-900 text-sm rounded-lg  block w-full pl-10 p-2.5 pr-24"
                placeholder="Search Team Members"
              />
            </div>
          </div>
          <div>
            <AppButton
              label="Add Team Member"
              handleClick={() => setAddNew(true)}
            />
          </div>
        </div>
      </div>

      <MemberTable />
    </DashboardLayout>
  );
};

export default Team;
