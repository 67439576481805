import axios from "axios";
import { useMutation } from "react-query";
import { IPackage } from "../types";
import { END_POINT } from "config/environment";

export const updateProfile = async (props: IPackage) => {
  const url = `${END_POINT.BASE_URL}/v1/administrator/package/${props.id}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  const response = await axios.put(url, props, config);
  return response;
};

export const useUpdatePackage = () => {
  return useMutation(updateProfile);
};
