import { DatePicker, Drawer, Form, Select } from "antd";
import { AppButton } from "components/button/AppButton";
import { WORK_MODELS } from "constants/general";
import { useGlobalContext } from "stateManagers/GlobalContext";
import { IdentifierProps } from "types";
import { openNotification } from "utils/notification";
import { GENDERS, COUNTRIES, STATES } from "constants/general";
import { useState } from "react";

export const FilterJob = ({ open, handleClose }: IdentifierProps) => {
  const { filterJobState, setFilterJobState } = useGlobalContext();
  const [selectedState, setSelectedState] = useState(null);
  const [filteredCities, setFilteredCities] = useState<any[]>([]);
  
  const handleSubmit = (data: any) => {
    console.log(data);
    const startD = data.duration ? data.duration[0].format("YYYY-MM-DD") : null;
    const endD = data.duration ? data.duration[1].format("YYYY-MM-DD") : null;
    if (data.duration || data.model || data.location_state || data.location_city) {
      setFilterJobState({
        ...filterJobState,
        start_date: startD,
        end_date: endD,
        work_model: data.model,
        location_city: data.location_city,
        location_state: data.location_state
      });
      handleClose();
     } else {
      openNotification({
        state: "error",
        title: "Error",
        description: "Please filter with at least 1 value",
        duration: 4.5,
      });
    }
  };

  const handleStateChange = (value:any) => {
    setSelectedState(value);
    const state = STATES.find((state:any) => state.name === value);
    setFilteredCities(state ? state.cities : []);
  };

  return (
    <Drawer open={open} onClose={() => handleClose()} title="Filter Jobs">
      <Form layout="vertical" onFinish={handleSubmit}>

        <Form.Item name="location_state" label="Location (State)">
          <Select allowClear placeholder="Select" 
            options={STATES.map(state => ({ value: state.name, label: state.name }))}
            onChange={handleStateChange}
          />
        </Form.Item>

        <Form.Item name="location_city" label="Location (City)">
          <Select
            options={filteredCities.map(city => ({ value: city, label: city }))}
            allowClear
            placeholder="Select"
          />
        </Form.Item>

        <Form.Item name="model" label="Work model">
          <Select allowClear placeholder="Select" options={WORK_MODELS} />
        </Form.Item>

        <Form.Item name="duration" label="Expire Date">
          <DatePicker.RangePicker className="w-full" format="YYYY/MM/DD" />
        </Form.Item>

        <div className="flex items-center justify-end gap-4">
          <AppButton type="reset" label="Reset" variant="transparent" />
          <AppButton type="submit" label="Apply" />
        </div>

      </Form>
    </Drawer>
  );
};
