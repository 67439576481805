import { Skeleton, Modal } from "antd";
import { TCertification, TEducationHistory } from "features/settings/types";
import React, { useState } from "react";

export const EducationHistory: React.FC<{
  data: TEducationHistory[];
  certifications: TCertification[];
  isLoading?: boolean;
}> = ({ data, certifications, isLoading }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);
  const [certModalContent, setCertModalContent] = useState<JSX.Element | null>(null);
  const [isCertModalVisible, setIsCertModalVisible] = useState(false);

  const handleEyeIconClick = (item: TEducationHistory) => {
    setModalContent(
      <div>
        <div>
          <h3>
            <span className="font-medium pr-2">Grade:</span>
            <span> {item.grade}</span>
          </h3>
          <h3>
            <span className="font-medium pr-2">Degree:</span>
            <span> {item.degree_id}</span>
          </h3>
          {item.description && (
            <p className="pt-1 text-justify">
              <span className="font-medium">Description:</span>
              <span className="text-sm pl-2">{item.description}</span>
            </p>
          )}
        </div>
      </div>
    );
    setIsModalVisible(true);
  };




  const handleClick = (item: TCertification) => {
    setCertModalContent(
      <div>
        <img src={item.file_path}/>
      </div>
    );
    setIsCertModalVisible(true);
  };

  return (
<>
    <h3 className=" font-bold text-lg pt-8">Education History</h3>
    <div className="mt-6 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-8 gap-x-4 gap-y-3">
      <div className="md:col-span-2 lg:col-span-4">
        <Skeleton active loading={isLoading}>
          {data.map((item) => (
            <div className="border bg-white rounded-md px-3 py-4 mb-4 hover:border-secondary">
              <div className="flex justify-between items-start">
                <div>
                  <h5 className="text-xs font-semibold text-primary">
                    {item.school}
                  </h5>
                  <h4 className="text-base font-medium text-primary pb-3 pt-1">
                    {item.course}
                  </h4>
                </div>
                <div className="flex items-center gap-x-3">
                  <i
                    className="ri-eye-line cursor-pointer text-lg hover:text-primary"
                    onClick={() => handleEyeIconClick(item)}
                  ></i>
                </div>
              </div>
              <div
                className="flex md:flex-row flex-col gap-y-3 md:gap-x-5 text-sm"
                style={{ color: "#667085" }}
              >
                <div className="flex items-center gap-x-1">
                  <i className="ri-map-pin-line"></i>
                  <span>Distinction</span>
                </div>
                <div className="flex items-center gap-x-1">
                  <i className="ri-time-line"></i>
                  <div>
                    <span className="pr-2">{item.start_date} To</span>
                    <span>
                      {item.is_current === 0
                        ? item.end_date
                        : "Currently working here"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Skeleton>
      </div>
      <Modal
        title="Education Details"
        footer={null}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        style={{ top: 10 }}
      >
        {modalContent}
      </Modal>
    </div>

    <h3 className=" font-bold text-lg pt-8">Certifications</h3>
    <div className="mt-6 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-8 gap-x-4 gap-y-3">
      <div className="md:col-span-2 lg:col-span-4">
        <Skeleton active loading={isLoading}>
          {certifications.map((item:any) => (
            <div className="border bg-white rounded-md px-3 py-4 mb-4 hover:border-secondary">
              <div className="flex justify-between items-start">
                <div>
                  <h5 className="text-xs font-semibold text-primary">
                    {item.issuer}
                  </h5>
                  <h4 className="text-base font-medium text-primary pb-3 pt-1">
                    {item.title}
                  </h4>
                </div>
                <div className="flex items-center gap-x-3">
                  <i
                    className="ri-eye-line cursor-pointer text-lg hover:text-primary"
                    onClick={() => handleClick(item)}
                  ></i>
                </div>
              </div>
              <div
                className="flex md:flex-row flex-col gap-y-3 md:gap-x-5 text-sm"
                style={{ color: "#667085" }}
              >
                <div className="flex items-center gap-x-1">
                  <i className="ri-map-pin-line"></i>
                  <a href={item.link}>{item.link}</a>
                </div>
                
              </div>
            </div>
          ))}
        </Skeleton>
      </div>
      <Modal
        title="Certification Details"
        footer={null}
        visible={isCertModalVisible}
        onCancel={() => setIsCertModalVisible(false)}
        style={{ top: 10 }}
      >
        {certModalContent}
      </Modal>
    </div>
    </>
  );
};
