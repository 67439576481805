import { Dropdown, Menu, Progress } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { AppButton } from "components/button/AppButton";
import { adminRoutePath } from "config/routeMgt/adminRoutePath";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ReportTalent } from "./ReportTalent";
import { QUERY_KEY_FOR_TALENTS, useFetchTalent } from "../hooks/useFetchTalent";
import { usePagination } from "hooks/usePagination";
import { ITalent } from "../types";
import userDp from "assets/images/placeholderImage.jpeg";
import { useGlobalContext } from "stateManagers/GlobalContext";
import { openNotification } from "utils/notification";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useQueryClient } from "react-query";
import { useBanUser } from "../hooks/useBanUser";

export const TalentsTable = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [addToFolder, setAddToFolder] = useState(false);
  const queryClient = useQueryClient();
  const { filterTalent } = useGlobalContext();
  const [reportModaL, setReportModaL] = useState(false);
  const { pagination, onChange } = usePagination();
  const { mutate } = useBanUser();
  const { token } = useGetUserInfo();


  const getCurrentJob = (workHistories: any[]) => {
    return workHistories?.find(job => job.is_current === 1);
  };
  
  const { data, isFetching } = useFetchTalent({
    pagination,
    experience: filterTalent.experience,
    gender: filterTalent.gender,
    sector: filterTalent.sector,
  });


  const handleBanUser = (values: any) => {
    const props:any = {
    token,
    user_id: values.id as unknown as string,
    banned: values.banned
    };
    mutate(props, {
    onError: () => {
      openNotification({
        title: "Error",
        state: "error",
        description: "Error occurred try again",
        duration: 6.0,
      });
    },
    onSuccess: () => {
        openNotification({
        title: "Success",
        state: "success",
        description: "Success",
        duration: 4.5,
        });
        queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_FOR_TALENTS],
        });
    },
    });
};

  const hasSelected = selectedRowKeys.length > 0;

  const columns: ColumnsType<ITalent> = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, val) => (
        <Link
          to={adminRoutePath.talentDetails(val.id).path}
          className="font-medium text-primary"
        >
          {val.first_name} {val.last_name}
        </Link>
      ),
    },
    {
      title: "Present Job Role",
      
      render: (_, val) => (
        <span>{getCurrentJob(val?.work_histories)?.title ?? null}</span>
      )
    },
    {
      title: "Present Employer",
      render: (_, val) => (
        <span>{getCurrentJob(val?.work_histories)?.company ?? null}</span>
      )
    },
    {
      title: "Visibility Score",
      dataIndex: "visibilityScore",
      render: (_, val) => (
        <div className="flex justify-center items-center">
          <Progress type="dashboard" size={35} percent={val.visibility_score} />
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, val) => (
        <div
          style={{ background: "#F2F4F7" }}
          className="inline-flex items-center justify-center gap-2 bg-red-300 rounded-xl px-2"
        >
          {val?.user && val?.user.banned == 1 ?
            <>
              <i
                className="ri-checkbox-blank-circle-fill text-red-500"
                style={{ fontSize: 10 }}
              ></i>
              <span className="text-sm pb-1">Banned</span>
            </>:
            <>
            <i
              className="ri-checkbox-blank-circle-fill text-green-500"
              style={{ fontSize: 10 }}
            ></i>
            <span className="text-sm pb-1">Active</span>
          </>
          }
        </div>
      ),
    },
    {
      title: "Avatar",
      dataIndex: "avatar",
      render: (_, val) => (
        <div>
          <img
            src={val.dp ? val.dp : userDp}
            alt="avatar"
            className="rounded-full h-10 w-10 object-cover"
          />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",

      render: (_, val) => (
        <div>
          <Dropdown
            trigger={["click"]}
            disabled={selectedRowKeys.length > 0}
            overlay={
              <Menu>
                <Menu.Item key="1">
                  <Link to={adminRoutePath.talentDetails(val.id).path}>View profile</Link>
                </Menu.Item>
                { val?.user?.banned == 1 ?
                  <Menu.Item key="2" onClick={()=>handleBanUser({id:val?.user?.id, banned:0})}>
                    <p className="text-red-500">Unban account</p>
                  </Menu.Item>
                  :
                  <Menu.Item key="2" onClick={()=>handleBanUser({id:val?.user?.id, banned:1})}>
                    <p className="text-red-500">Ban account</p>
                  </Menu.Item>
                }
              </Menu>
            }
          >
            <i className="ri-more-2-fill text-lg cursor-pointer"></i>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <div>
      <ReportTalent
        open={reportModaL}
        handleClose={() => setReportModaL(false)}
      />
      {hasSelected && (
        <div className="mt-7 flex items-center gap-3">
          <button
            className="button"
            style={{ background: "#D92D20" }}
            onClick={() => setReportModaL(true)}
          >
            Report
          </button>
          <AppButton
            label="Add to folder"
            variant="transparent"
            handleClick={() => setAddToFolder(true)}
          />
        </div>
      )}

      <Table
        className="bg-white rounded-md shadow border mt-8"
        columns={columns}
        dataSource={data?.data.map((item) => ({ ...item, key: item.id }))}
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys: React.Key[], rows) => {
            setSelectedRowKeys(rows.map((row) => row.id));
          },
        }}
        scroll={{ x: 550 }}
        loading={isFetching}
        pagination={{ ...pagination, total: data?.total }}
        onChange={onChange}
      />
    </div>
  );
};
