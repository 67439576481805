import { Tabs } from "antd";
import { DashboardLayout } from "admin/components/layout/components/DashboardLayout";
import { Navbar } from "admin/components/layout/components/Navbar";import { Profile } from "./components/Profile";
import { Password } from "./components/Password";
import { useGetUserInfo } from "hooks/useGetUserInfo";

export const AdminSettings = () => {
  //const { userInfo } = useGetUserInfo();
  return (
    <DashboardLayout>
      <Navbar title="Settings" description="" />
      <Tabs defaultActiveKey="1" className="" size="large">
        <Tabs.TabPane tab="Profile" key="1">
          <Profile />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Password" key="6">
          <Password />
        </Tabs.TabPane>
      </Tabs>
    </DashboardLayout>
  );
};
