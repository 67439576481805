import logo from "assets/images/logoWhite.svg";
import ".././sidebar.css";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { SuperAdmin } from "./SuperAdmin";

export const SideBar = () => {
  const { userInfo } = useGetUserInfo();
  return (
    <div className="bg-primary h-screen w-full">
      <div>
        <div className="py-4 px-6">
          <img src={logo} alt="logo" />
        </div>
        <SuperAdmin />
      </div>
    </div>
  );
};
