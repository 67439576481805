import { useState } from "react";
import { AddCareerInterest } from "./AddCareerInterest";
import {
  QUERY_KEY_FOR_CAREER_INTEREST,
  useGetCareerInterest,
} from "features/settings/hooks/useGetCareerInterest";
import Popconfirm from "antd/es/popconfirm";
import { useDelete } from "hooks/useDelete";
import { Skeleton } from "antd";

const deleteEndPointUrl = "delete-career-interest/";

export const CareerInterest = () => {
  const [addInterest, setAddInterest] = useState(false);
  const { data, isLoading } = useGetCareerInterest();
  const { removeData } = useDelete({
    queryKey: QUERY_KEY_FOR_CAREER_INTEREST,
    deleteEndPointUrl,
  });

  return (
    <>
      <AddCareerInterest
        open={addInterest}
        handleClose={() => setAddInterest(false)}
      />
      <div className="mt-6 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-8 gap-x-4 gap-y-3">
        <div className="lg:col-span-2">
          <h4 className="font-semibold text-lg text-primary">
            Career Interest
          </h4>
          <h3>Add your career interest</h3>
        </div>

        <div className="md:col-span-2 lg:col-span-4 grid grid-cols-2">
          <Skeleton active loading={isLoading}>
            {data?.map((item) => (
              <div className="mb-4 flex items-center gap-2" key={item.id}>
                <button className="shadow-sm border rounded px-3 py-1 font-medium">
                  {item.name}
                </button>
                <Popconfirm
                  okButtonProps={{
                    style: { backgroundColor: '#f5222d', color: '#ffffff' }, // Custom styles
                  }} 
                  title={`Delete ${item.name}`}
                  description={`Are you sure to delete ${item.name}?`}
                  onConfirm={() => removeData(item.id as unknown as number)}
                >
                  <i className="ri-delete-bin-line text-red-600 cursor-pointer text-base hover:text-red-800"></i>
                </Popconfirm>
              </div>
            ))}
          </Skeleton>

          <button
            onClick={() => setAddInterest(true)}
            className="text-primary font-bold text-base flex items-center gap-1 hover:text-accent"
          >
            <i className="ri-add-line"></i> <span>Add New</span>
          </button>
        </div>
      </div>
    </>
  );
};
