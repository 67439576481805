import React from 'react';

interface CurrencyProps {
  value: number | null;
  decimalPrecision: number;
}

const CurrencyFormatter: React.FC<CurrencyProps> = ({
  value,
  decimalPrecision,
}) => {
  if (value === null) return null;

  const formattedValue = value.toLocaleString(undefined, {
    minimumFractionDigits: decimalPrecision,
    maximumFractionDigits: decimalPrecision,
  });

  const formattedCurrency = formattedValue;

  return <span>₦{formattedCurrency}</span>;
};

export default CurrencyFormatter;