import axios from "axios";
import { useMutation } from "react-query";
import { END_POINT } from "config/environment";
import { TPackage } from "../types";

export const asign = async (props: TPackage) => {
  const url = `${END_POINT.BASE_URL}/v1/administrator/package-permissions`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  const data: any = {
    permissions: props.permissions,
    name: props.name,
  };

  const response = await axios.post(url, data, config);
  return response;
};

export const useAsignRoles = () => {
  return useMutation(asign);
};
