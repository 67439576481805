import { ReactNode, createContext, useContext, useState } from "react";

type GlobalProviderProps = {
  children: ReactNode;
};

type GlobalContext = {
  filterJobState: {
    start_date: string | null;
    end_date: string | null;
    work_model: string | null;
    location_city: string | null;
    location_state: string | null;
  };
  filterTalent: {
    sector: string | null;
    experience: string | null;
    gender: string | null;
  };
  setFilterJobState: (state: any) => void;
  setFilterTalent: (state: any) => void;
};

const theGlobalContext = createContext({} as GlobalContext);

export function useGlobalContext() {
  return useContext(theGlobalContext);
}

export function GlobalContextProvider({ children }: GlobalProviderProps) {
  // Define the initial filter state
  const [filterJobState, setFilterJobState] = useState({
    start_date: null,
    end_date: null,
    work_model: null,
    location_state: null,
    location_city: null,
  });

  const [filterTalent, setFilterTalent] = useState({
    sector: null,
    experience: null,
    gender: null,
  });
  return (
    <theGlobalContext.Provider
      value={{
        filterJobState,
        setFilterJobState,
        filterTalent,
        setFilterTalent,
      }}
    >
      {children}
    </theGlobalContext.Provider>
  );
}
