export const routePath = {
  home: `/`,
  sign_in: `/sign-in`,
  sign_up: `/sign-up`,
  google: `/auth/google`,
  googleOauth: `/oauth/google`,
  linkedin : `/auth/linkedin`,
  linkedinOauth: `/oauth/linkedin`,
  zoomOauth: `/oauth/zoom`,
  forgot_password: `/forgot-password`,
  reset_password: `/password-reset`,
  jobs: `/jobs`,
  jobDetails: (id?: number | string) => ({
    format: `/jobs/:id`,
    path: `/jobs/${id}`,
  }),
  talents: `/talents`,
  talentDetails: (id?: number | string) => ({
    format: `/talents/:id`,
    path: `/talents/${id}`,
  }),
  folders: `/folders`,
  folderDetails: (id?: number | string) => ({
    format: `/folders/:id`,
    path: `/folders/${id}`,
  }),
  pricing: `/pricing`,
  verifyPayment: `/payment/verify`,
  teamInvite: `/team-invitation`,
  message: `/message:id`,
  messages: `/message`,
  settings: `/settings`,
  profile :`/settings/profile`,
  documents :`/settings/documents`,
  password :`/settings/password`, 
  billings :`/settings/billings`, 
  personalityTest :`/settings/personality-test`, 
  meetingSettings: `/meeting-settings`,
  invites: `/invites`,
  team: `/team`,

  terms: '/legal/terms-and-conditions',
  privacy: '/legal/privacy-policy',
};
