import { Form, Switch, Input, Select } from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { AppButton } from "components/button/AppButton";
import avatar from "assets/images/default.webp";
import bg from "../../../talents/assets/images/Image wrap outer.svg";
import {
  emailValidationRules,
  generalValidationRules,
  textInputValidationRules,
  phoneNumberValidationRule
} from "utils/formHelpers/validations";
import { EducationHistory } from "./EducationHistory";
import { WorkHistory } from "./WorkHistory";
import { VolunteerExperience } from "./VolunteerExperience";
import { CareerInterest } from "./CareerInterest";
import { Certifications } from "./Certifications";
import {
  QUERY_KEY_FOR_TALENT_PROFILE,
  useGetTalentProfile,
} from "features/settings/hooks/useGetTalentProfile";
import { ChangeEvent, useEffect, useState } from "react";
import { GENDERS, COUNTRIES, STATES } from "constants/general";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { IUpdateTProfile, FileWithPreview } from "features/settings/types";
import { useUpdateTalentProfile } from "features/settings/hooks/useUpdateTalentProfile";
import { openNotification } from "utils/notification";
import { useQueryClient } from "react-query";
import TextArea from "antd/es/input/TextArea";
import { useSignIn } from "react-auth-kit";
import { resizeImageToSquare, resizeCoverPhoto } from "utils/resizephoto";
import { Projects } from "./Projects";

const ethnicity = ["Hausa", "Igbo", "Yoruba"];

export const TalentProfile = () => {
  const { data, isLoading: isGetTPLoading } = useGetTalentProfile();
  const [form] = Form.useForm();
  const { token, userInfo } = useGetUserInfo();
  const queryClient = useQueryClient();
  const { mutate, isLoading: isUpdateTPLoading } = useUpdateTalentProfile();
  const [file, setFile] = useState<FileWithPreview | null>(null);
  const [resizedBlob, setResizedBlob] = useState<Blob | null>(null);
  const [coverPhotoBlob, setCoverPhotoBlob] = useState<Blob | null>(null);
  const [selectedState, setSelectedState] = useState(null);
  const [filteredCities, setFilteredCities] = useState<any[]>([]);
  
  const signIn = useSignIn();
  
  useEffect(() => {
    form.setFieldsValue({
      first_name: data?.data.first_name,
      last_name: data?.data.last_name,
      email: data?.data.email,
      gender: data?.data.gender,
      executive_summary: data?.data.executive_summary,
      professional_goal: data?.data.professional_goal,
      phone: data?.data.phone,
      country: data?.data.country,
      state: data?.data.state,
      city: data?.data.city,
      language: data?.data.language,
      prefered_industries: data?.data.prefered_industries,
      prefered_locations: data?.data.prefered_locations,
      linkedin: data?.data.linkedin,
      github: data?.data.github,
      behance: data?.data.behance,
      facebook: data?.data.facebook,
      x: data?.data.x,
      open_to_work: data?.data.open_to_work,
      instagram: data?.data.instagram,
      ethnic_group: data?.data.ethnic_group
    });
  }, [form, data]);


  const handleSubmit = (data: IUpdateTProfile) => {
    const props: IUpdateTProfile = {
      first_name: data.first_name,
      last_name: data.last_name,
      gender: data.gender,
      executive_summary: data.executive_summary,
      photo: resizedBlob ? resizedBlob : null,
      cover_photo: coverPhotoBlob ? coverPhotoBlob : null,
      professional_goal: data.professional_goal ? data.professional_goal : '',
      phone: data.phone ? data.phone :'',
      country: data.country ? data.country :'',
      state: data.state ? data.state : '',
      city: data.city ? data.city : '',
      language: data.language ? data.language : '',
      prefered_industries: data.prefered_industries,
      prefered_locations:  data.prefered_locations,
      linkedin: data.linkedin ? data.linkedin: '',
      github: data.github ? data.github : '',
      behance: data.behance ? data.behance : '',
      facebook: data.facebook ? data.facebook :'',
      x:  data.x ? data.x : '',
      open_to_work: data.open_to_work,
      instagram: data.instagram ? data.instagram : '',
      ethnic_group: data.ethnic_group ? data.ethnic_group: '',
      token,
    };
    mutate(props, {
      onError: (err: any) => {
        let errorTitle = "Error";
        let errorDescription = "An error occurred, please try again.";

        if (err.response && err.response.data &&  err.response.data.error) {
          errorTitle = "Error: " + err.response.data.error;
          errorDescription = err.response.data.description || "Please try again.";
        }

        openNotification({
          title: errorTitle,
          state: "error",
          description: errorDescription,
          duration: 6,
        });
      },
      onSuccess: (res: any) => {
      if(
        signIn({
          token: token,
          tokenType: "Bearer",
          authState: {"token": token,"user":res.data},
          expiresIn: 180,
        }))
        {
        openNotification({
          title: "Success",
          state: "success",
          description: "Profile Created Successful",
          duration: 4.5,
        });
        form.resetFields();
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_FOR_TALENT_PROFILE],
        });
      }
      },
    });
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile({
        ...selectedFile,
        preview: URL.createObjectURL(selectedFile)
      } as FileWithPreview);
      try {
        const resizedImageBlob = await resizeImageToSquare(selectedFile, 200);
        setResizedBlob(resizedImageBlob);
      } catch (error) {
        console.error('Error resizing image:', error);
      }
    }
  };

  const coverPhotoChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile({
        ...selectedFile,
        preview: URL.createObjectURL(selectedFile)
      } as FileWithPreview);
      try {
        const resizedImageBlob = await resizeCoverPhoto(selectedFile);
        setCoverPhotoBlob(resizedImageBlob);
      } catch (error) {
        console.error('Error resizing image:', error);
      }
    }
  };

  const handleStateChange = (value:any) => {
    setSelectedState(value);
    const state = STATES.find((state:any) => state.name === value);
    setFilteredCities(state ? state.cities : []);
  };
  
  return (
    <>
      <Form form={form} onFinish={handleSubmit} disabled={isGetTPLoading}>
        <div className="flex justify-between mb-4">
          <div>
            <h4 className="font-semibold text-lg text-primary">
              Personal info
            </h4>
            <h3 className="text-sm">Update your details here.</h3>
          </div>
          <div className="flex items-center gap-x-3">
            <AppButton type="button" label="Cancel" variant="transparent" />
            <AppButton
              type="submit"
              label="Save"
              isLoading={isUpdateTPLoading}
            />
          </div>
        </div>
        <hr />
        <div className="grid grid-cols-1 md:grid-cols-5 my-3 md:my-5 gap-3">
          <Form.Item noStyle name="image">
            <Input type="hidden" />
          </Form.Item>
          <div>
            <h3 className="font-medium">Your profile picture</h3>
            <h3 className="text-xs">This will be displayed on your profile</h3>
          </div>
          <div className="relative">
            <div className="flex items-baseline">
              <img src={resizedBlob ? URL.createObjectURL(resizedBlob) : data?.data.dp != null ? data?.data.dp : avatar} alt="avatar" className="h-10 w-10 rounded-full" />
              <input type="file" accept="image/*" style={{ display: 'none' }} id="cover_photo" onChange={handleFileChange}/>
              <i className="ri-edit-2-fill cursor-pointer text-xl text-primary hover:text-primary" onClick={() => document.getElementById('cover_photo')?.click()}></i>
            </div>
          </div>
          <div className="bg-transparent">
            <Form.Item
              name="open_to_work"
              label="Open to work"
              valuePropName="checked"
            >
              <Switch className="bg-transparent" checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
            </Form.Item>
            <h3 className="text-xs">You will be recomended to recruiters</h3>
          </div>
        </div>
        <br />
        <hr />

        <div className="grid grid-cols-1 md:grid-cols-5 my-3 md:my-5 gap-3">
          <Form.Item noStyle name="image">
            <Input type="hidden" />
          </Form.Item>
          <div>
            <h3 className="font-medium">Your cover photo</h3>
            <h3 className="text-xs">This will be displayed on your profile</h3>
          </div>
          <div className="relative">
            <div className="flex items-baseline">
              <img src={coverPhotoBlob ? URL.createObjectURL(coverPhotoBlob) : data?.data.user.cover_photo != null ? data?.data.user.cover_photo : bg} alt="avatar" className="h-8 w-54 rounded-" />
              <input type="file" accept="image/*" style={{ display: 'none' }} id="fileInput" onChange={coverPhotoChange}/>
              <i className="ri-edit-2-fill cursor-pointer text-xl text-primary hover:text-primary" onClick={() => document.getElementById('fileInput')?.click()}></i>
            </div>
          </div>
        </div>
        <br />
        <hr />

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Full Name</h3>
          <div className="col-span-3">
            <div className="flex gap-x-3">
              <Form.Item name="first_name" rules={textInputValidationRules}>
                <Input placeholder="First name" />
              </Form.Item>
              <Form.Item name="last_name" rules={textInputValidationRules}>
                <Input placeholder="Last name" />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Email Address</h3>

          <Form.Item
            name="email"
            className="col-span-2"
            rules={emailValidationRules}
          >
            <Input placeholder="example@gmail.com" disabled />
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Phone Number (Optional)</h3>

          <Form.Item
            name="phone"
            className="col-span-2"
            rules={phoneNumberValidationRule}
          >
            <Input placeholder="+2348012345678"  />
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Gender</h3>

          <Form.Item
            name="gender"
            className="col-span-2"
            rules={generalValidationRules}
          >
            <Select options={GENDERS} allowClear placeholder="Select" />
          </Form.Item>
        </div>
        <br />
        <hr />

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Country</h3>

          <Form.Item
            name="country"
            className="col-span-2"
            rules={generalValidationRules}
          >
            <Select options={COUNTRIES} allowClear placeholder="Select" />
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>State</h3>

          <Form.Item
            name="state"
            className="col-span-2"
            rules={generalValidationRules}
          >
            <Select allowClear placeholder="Select" 
              options={STATES.map(state => ({ value: state.name, label: state.name }))}
              onChange={handleStateChange}
            />
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>City</h3>

          <Form.Item
            name="city"
            className="col-span-2"
            rules={generalValidationRules}
          >
            <Select
              options={filteredCities.map(city => ({ value: city, label: city }))}
              allowClear
              placeholder="Select"
            />
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Ethnicity</h3>

          <Form.Item
            name="ethnic_group"
            className="col-span-2"
            rules={generalValidationRules}
          >
            <Select
              options={ethnicity.map(item => ({ value: item, label: item }))}
              allowClear
              placeholder="Select"
            />
          </Form.Item>

        </div>


        {
          /*<div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
            <h3>NIN</h3>

            <Form.Item
              name="nin"
              className="col-span-2"
              rules={textInputValidationRules}
            >
              <Input placeholder="12345678901" />
            </Form.Item>
          </div>*/
        }
        <br />
        <hr />
        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Executive Summary</h3>
          <Form.Item
            className="col-span-2"
            name="executive_summary"
          >
            <TextArea autoSize={{minRows:3}}/>
          </Form.Item>
        </div>  

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Professional Goal</h3>
          <Form.Item
            className="col-span-2"
            name="professional_goal"
          >
            <TextArea autoSize={{minRows:3}}/>
          </Form.Item>
        </div>  

        <br />
        <hr /> 

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Linkedin profile (Optional)</h3>

          <Form.Item
            name="linkedin"
            className="col-span-2"
            //rules={generalValidationRules}
          >
            <Input placeholder="https://linkedin.com"  />
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Github profile (Optional)</h3>

          <Form.Item
            name="github"
            className="col-span-2"
            //rules={generalValidationRules}
          >
            <Input placeholder="https://github.com"  />
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Behance profile (Optional)</h3>

          <Form.Item
            name="behance"
            className="col-span-2"
            //rules={generalValidationRules}
          >
            <Input placeholder="https://behance.com"  />
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Facebook profile (Optional)</h3>

          <Form.Item
            name="facebook"
            className="col-span-2"
            //rules={generalValidationRules}
          >
            <Input placeholder="https://facebook.com"  />
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>X profile (Optional)</h3>

          <Form.Item
            name="x"
            className="col-span-2"
            //rules={generalValidationRules}
          >
            <Input placeholder="https://twitter.com"  />
          </Form.Item>
        </div>


        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Instagram profile (Optional)</h3>

        <Form.Item
            name="instagram"
            className="col-span-2"
            //rules={generalValidationRules}
          >
            <Input placeholder="https://instagram.com"  />
          </Form.Item>
        </div>
        
        <br />
        <hr />
      </Form>

      <EducationHistory isLoading={isGetTPLoading} data={data ? data?.data.education_histories : []} />
      <br />
      <hr />
      <WorkHistory isLoading={isGetTPLoading} data={data ? data?.data.work_histories : []}/>
      <br />
      <hr />

      <VolunteerExperience isLoading={isGetTPLoading} data={data ? data?.data.volunteer_experiences : []}/>
      <br />
      <hr />
      <CareerInterest />

      <br />
      <hr />
      <Certifications isLoading={isGetTPLoading} data={data ? data?.data.certifications : []}/>

      <br />
      <hr />
      <Projects isLoading={isGetTPLoading} data={data ? data?.data.projects : []}/>
    </>
  );
};
