import logo from "assets/images/logoWhite.svg";
import ".././sidebar.css";
import { TalentSidebar } from "./TalentSidebar";
import { RecruiterSidebar } from "./RecruiterSidebar";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { CorporateRecruiter } from "./CorporateRecruiter";

export const SideBar = () => {
  const { userInfo } = useGetUserInfo();
  return (
    <div className="bg-primary h-screen w-full">
      <div>
        <div className="py-4 px-6">
          <img src={logo} alt="logo" />
        </div>
        {userInfo?.recruiter?.is_corporate === 0 && <RecruiterSidebar />}
        {userInfo?.type === "talent" && <TalentSidebar />}
        {userInfo?.recruiter?.is_corporate === 1 && <CorporateRecruiter />}
      </div>
    </div>
  );
};
