import { AllRoutes } from "config/routeMgt/AllRoutes";
import { AuthProvider } from "react-auth-kit";
import { QueryClient, QueryClientProvider } from "react-query";
import {ConfigProvider } from 'antd';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  },
});


const App = () => {
  return (
    <AuthProvider authType="localstorage" authName="arno-app">
      <QueryClientProvider client={queryClient}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#01065a',
            },
          }}
        >
          <AllRoutes />
        </ConfigProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
};

export default App;
