import axios from "axios";
import { useMutation } from "react-query";
import { END_POINT } from "config/environment";
import { JobOfferProps } from "../types";

export const sendOffer = async (props: JobOfferProps) => {
  const url = `${END_POINT.BASE_URL}/v1/send-job-offer`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  const data: any = {
    listing_invite_id: props.listing_invite_id,
  };

  const response = await axios.post(url, data, config);
  return response;
};

export const useSendJobOffer = () => {
  return useMutation(sendOffer);
};
