import axios from "axios";
import { useQuery } from "react-query";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { END_POINT } from "config/environment";

export const QUERY_KEY_FOR_BILLING_HISTORY = "billing_history";

const getData = async (props: {
  token: string;
}): Promise<any> => {
    const url = `${END_POINT.BASE_URL}/v1/administrator/billing-history`;
    const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  const res = await axios.get(url, config);
  const item = res.data;
  return item;
};

export const useGetBillingHistory = () => {
  const { token } = useGetUserInfo();
  const queryData = useQuery(
    [QUERY_KEY_FOR_BILLING_HISTORY],
    () => getData({
      token,
    }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
    }
  );
  return queryData;
};


