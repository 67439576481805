import { Dropdown, Menu } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useState } from "react";
//import { AsynPermissions } from "./AsynPermissions";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { hasPermission } from "utils/hasPermission";
import { useGetRoles } from "../hooks/useGetRoles";
import { RolesData } from "types";

export const RolesTable = () => {
  const { data, isLoading: rolesDataLoading } = useGetRoles({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [role, setRole] = useState<any>();
  const [roleModaL, setRoleModaL] = useState<boolean>(false);
  const { userInfo } = useGetUserInfo();


  const hasPermissionFor = (...permissionNames: string[]): boolean => {
    return permissionNames.some(permissionName =>
      hasPermission(userInfo, permissionName)
    );
  };

  const columns: ColumnsType<RolesData> = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (_, val) => (
        <p
          className="font-medium text-primary"
        >
          {val.name}
        </p>
      ),
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      render: (_, val) => (
        <div className="font-medium text-primary">
          {val.permissions.length}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",

      render: (_, val) => (
        <div>
          {
            userInfo?.id !== val.id && hasPermissionFor('Manage Admin users') &&
            <Dropdown
              trigger={["click"]}
              disabled={selectedRowKeys.length > 0}
              overlay={
                <Menu>
                  <Menu.Item key="1" onClick={() => {setRole(val); setRoleModaL(true); }}>
                    Asign Permissions
                  </Menu.Item>
                  {/*<Menu.Item key="2" onClick={() => {setUserData(val); setEditModaL(true); }}>
                    Edit
                  </Menu.Item>
                  <Menu.Item key="3">
                    <Popconfirm
                  okButtonProps={{
                    style: { backgroundColor: '#f5222d', color: '#ffffff' }, // Custom styles
                  }} 
                      title="Delete user"
                      description="Are you sure to delete this user?"
                      onConfirm={() => removeData(val.id)}
                    >
                      Delete
                    </Popconfirm>
                  </Menu.Item>*/}
                </Menu>
              }
            >
              <i className="ri-more-2-fill text-lg cursor-pointer"></i>
            </Dropdown>
          }
        </div>
      ),
    },
  ];

  return (
    <div>
      {/* roleModaL &&
      <AsynPermissions
        open={roleModaL}
        handleClose={() => setRoleModaL(false)}
        data={role}
      />
      */}
      <Table
        className="bg-white rounded-md shadow border mt-8"
        columns={columns}
        dataSource={data?.data.map((item:any) => ({ ...item, key: item.id }))}
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys: React.Key[], rows) => {
            setSelectedRowKeys(rows.map((row) => row.id));
          },
        }}
        scroll={{ x: 550 }}
        loading={rolesDataLoading}
      />
    </div>
  );
};
