import { Empty, Modal } from "antd";
import { AppButton } from "components/button/AppButton";
import { useHandleAcceptOffer } from "features/invites/hooks/useHandleAcceptOffer";
import { InvitesProps } from "features/invites/types";
import { useState } from "react";

export const JobOffers  = ({ data }: InvitesProps) =>  {
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedInviteIndex, setSelectedInviteIndex] = useState(-1);
  const { acceptOffer, isLoading } = useHandleAcceptOffer();

  const handleOpenDetails = (index: number) => {
    setSelectedInviteIndex(index);
    setOpenDetails(true);
  };

  return (
    <div>
      {data.length > 0 ? (
      data.map((invite, index) => (
        <>
          <div className="border rounded-md shadow px-3 py-4 my-4 gap-y-5 flex md:flex-row flex-col justify-between">
            <div>
              <h5 className="text-sm font-semibold text-primary">{invite.listing.title}</h5>
              <h1 className="text-base font-medium  pb-3 pt-1">
              {invite.listing.location_city}
              </h1>

              <div className="flex items-center text-base gap-x-5 text-primary font-semibold">
                <div className="flex items-center text-base gap-x-1 text-primary font-semibold">
                  <i className="ri-map-pin-line"></i>
                  <span>{invite.listing.company_name}</span>
                </div>

                <div className="flex items-center text-base gap-x-2 text-primary font-semibold">
                  <i className="ri-star-fill text-yellow-400"></i>
                  <span>5.0</span>
                </div>
              </div>
            </div>

            <div className="flex justify-between flex-col">
              <div>
                <AppButton
                  label="Details"
                  handleClick={() => handleOpenDetails(index)}
                />
              </div>
              {
                invite.accepted_offer === 1 ?

                <h4 className="font-semibold base cursor-pointer hover:text-accent text-secondary">
                  Job offer acceptance sent
                </h4>
                :
                <h4 onClick={()=>acceptOffer({listing_invite_id:invite.id})} className="font-semibold base cursor-pointer hover:text-accent">
                  Confirm Job Offer
                </h4>
              }
            </div>
          </div>

          {/* details */}
          <Modal
            open={openDetails}
            title="Invite Details"
            style={{ top: 15 }}
            width="40rem"
            footer={false}
            onCancel={() => setOpenDetails(false)}
          >
            {selectedInviteIndex !== -1 && (
            <div>
              <div className="flex flex-col gap-4">
                <div>
                  <h4>Title</h4>
                  <h3 className="text-base font-medium">
                    {data[selectedInviteIndex].listing.title}
                  </h3>
                </div>
                <div>
                  <h4>Sender</h4>
                  <h3 className="text-base font-medium text-primary">
                    {data[selectedInviteIndex].listing.company_name}
                  </h3>
                </div>
                <div>
                  <h4>Job Type</h4>
                  <h3 className="text-base font-medium">
                    {data[selectedInviteIndex].listing.type}
                  </h3>
                </div>
                <div>
                  <h3 className="text-base pb-1">Description:</h3>
                  <p>{data[selectedInviteIndex].listing.description}</p>
                </div>
              </div>
            </div>
            )}
          </Modal>
        </>))
      ):
      <Empty />
      }
    </div>
  );
};
