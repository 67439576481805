import axios from "axios";
import { useMutation } from "react-query";
import { END_POINT } from "config/environment";
import { IJobsProps } from "../types";

export const likeJob = async (props: IJobsProps) => {
  const url = `${END_POINT.BASE_URL}/v1/like-job/${props.id}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  const data: any = {};

  const response = await axios.post(url, data, config);
  return response;
};

export const useLikeJob = () => {
  return useMutation(likeJob);
};
