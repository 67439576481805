import axios from "axios";
import { useMutation } from "react-query";
import { IRecruiterProfile } from "../types";
import { END_POINT } from "config/environment";

export const updateProfile = async (props: IRecruiterProfile) => {
  const url = `${END_POINT.BASE_URL}/v1/update-recruiter-profile`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
      "Content-Type": "multipart/form-data"
    },
  };

  const formData = new FormData();
  formData.append("first_name", props.first_name);
  formData.append("last_name", props.last_name);
  formData.append("company_name", props.company_name);
  formData.append("rc_number", props.rc_number);
  formData.append("photo", props.photo);
  formData.append("phone", props.phone);
  formData.append("country", props.country);
  formData.append("state", props.state);
  formData.append("city", props.city);
  formData.append("language", props.language);
  formData.append("prefered_industries", props.prefered_industries);
  formData.append("prefered_locations", props.prefered_locations);
  formData.append("linkedin", props.linkedin);
  formData.append("github", props.github);
  formData.append("behance", props.behance);
  formData.append("facebook", props.facebook);
  formData.append("x", props.x);
  formData.append("photo", props.photo);
  formData.append("instagram", props.instagram);
  formData.append("cover_photo", props.cover_photo);
  const response = await axios.post(url, formData, config);
  return response;
};

export const useUpdateRecruiterProfile = () => {
  return useMutation(updateProfile);
};
