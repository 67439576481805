import axios from "axios";
import { useMutation } from "react-query";
import { END_POINT } from "config/environment";

interface PayInitProp{
    plan : string;
    gateway?: any;
    token?: string;
    upgrade?: boolean;
    plan_duration?: string;
    packages?: any
    payment_gateway_id?: number;
}

export const paymentInit = async (props: PayInitProp) => {
    const url = `${END_POINT.BASE_URL}/v1/${props.gateway?.name.toLowerCase()}-init`;
    const config = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${props.token}`,
        },
    };

    const data: PayInitProp = {
        plan: props.plan,
        payment_gateway_id: props.gateway?.id,
        plan_duration: props.plan_duration,
        packages: props.packages,
        upgrade: props.upgrade
    };

    const response = await axios.post(url, data, config);
    return response;
};

export const usePaymentInit = () => {
    return useMutation(paymentInit);
};
