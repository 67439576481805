import axios from "axios";
import { useMutation } from "react-query";
import { END_POINT } from "config/environment";
import { IFolderProps } from "../types";

export const createFolder = async (props: IFolderProps) => {
  const url = `${END_POINT.BASE_URL}/v1/folders`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  const corporate_id = props?.userInfo?.current_team ? props?.userInfo?.current_team.corporate_id : props?.userInfo?.recruiter.is_corporate == 1 ? props?.userInfo?.recruiter.id :'';
  const data: any = {
    recruiter_id: props.recruiter_id,
    name: props.name,
    id: props?.id,
    corporate_id
  };

  const response = await axios.post(url, data, config);
  return response;
};

export const useCreateFolder = () => {
  return useMutation(createFolder);
};
