import { Skeleton, Modal } from "antd";
import { useGetSectors } from "features/jobs/hooks/useGetSectors";
import { IProject, IWorkHistory } from "features/settings/types";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export const Projects: React.FC<{
  data: IProject[];
  isLoading?: boolean;
}> = ({ data, isLoading }) => {
  const { data: sectorData } = useGetSectors();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);

  const handleEyeIconClick = (item: IWorkHistory) => {
    const sector = sectorData?.find((sec) => sec.id === item.sector_id);
    setModalContent(
      <div>
        <h1>
          <span className="font-medium pr-2">Sector:</span>
          <span> {sector?.name} </span>
        </h1>
        <p className="pt-1 text-justify">
          <span className="font-medium">Description:</span>
          <span className="text-sm pl-2">{item.description}</span>
        </p>
      </div>
    );
    setIsModalVisible(true);
  };

  return (
    <>
      <div className="mt-6 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-8 gap-x-4 gap-y-3">
        <div className="md:col-span-2 lg:col-span-4">
          <Skeleton active loading={isLoading}>
            {data.map((item) => (
              <div className="border bg-white rounded-md px-3 py-4 mb-4 cursor-pointer hover:border-secondary">
                <div className="flex justify-between items-start">
                  <div className="capitalize">
                    <h5 className="text-base font-semibold text-primary">
                      {item.title}
                    </h5>
                    <h5 className="text-base font-medium text-primary pb-3 pt-1">
                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                        {item.link}
                    </a>
                    </h5>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </Skeleton>
        </div>       
      </div>
    </>
  );
};
