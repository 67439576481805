import { Empty, Form, Input, Modal, Radio, Space } from "antd";
import { AppButton } from "components/button/AppButton";
import { useAcceptInvite } from "features/invites/hooks/useAcceptInvite";
import { QUERY_KEY_FOR_TALENT_INVITE } from "features/invites/hooks/useFetchTalentInvites";
import { useHandleInvite } from "features/invites/hooks/useHandleInvite";
import { IAcceptInvite, InvitesProps } from "features/invites/types";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { generalValidationRules } from "utils/formHelpers/validations";
import { openNotification } from "utils/notification";

export const Pending = ({ data }: InvitesProps) => {
  const [form] = Form.useForm();
  const { InviteSubmit, isLoading } = useHandleInvite();
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedInviteIndex, setSelectedInviteIndex] = useState(-1);

  const handleOpenDetails = (index: number) => {
    setSelectedInviteIndex(index);
    setOpenDetails(true);
  };

  return (
    <div>
      {data.length > 0 ? (
        data.map((invite, index) => (
          <>
            <div
              key={invite.id}
              className="border rounded-md shadow px-3 py-4 my-4 gap-y-5 flex md:flex-row flex-col justify-between"
            >
              <div>
                <h5 className="text-sm font-semibold text-primary">
                  {invite.listing.location_city}
                </h5>
                <h1 className="text-base font-medium  pb-3 pt-1">
                  {invite.listing.title}
                </h1>

                <div className="flex items-center text-base gap-x-5 text-primary font-semibold">
                  <div className="flex items-center text-base gap-x-1 text-primary font-semibold">
                    <i className="ri-map-pin-line"></i>
                    <span>{invite.listing.company_name}</span>
                  </div>

                  <div className="flex items-center text-base gap-x-2 text-primary font-semibold">
                    <i className="ri-star-fill text-yellow-400"></i>
                    <span>5.0</span>
                  </div>
                </div>
              </div>

              <div>
                <AppButton
                  label="Details"
                  handleClick={() => handleOpenDetails(index)}
                />
              </div>
            </div>

            {/* details */}
            <Modal
              open={openDetails}
              title="Invite Details"
              style={{ top: 15 }}
              width="40rem"
              footer={false}
              onCancel={() => setOpenDetails(false)}
            >
              {selectedInviteIndex !== -1 && (
                <div>
                  <div className="flex flex-col gap-4">
                    <div>
                      <h4>Title</h4>
                      <h3 className="text-base font-medium">
                        {data[selectedInviteIndex].listing.title}
                      </h3>
                    </div>
                    <div>
                      <h4>Sender</h4>
                      <h3 className="text-base font-medium text-primary">
                        {data[selectedInviteIndex].listing.company_name}
                      </h3>
                    </div>
                    <div>
                      <h4>Job Type</h4>
                      <h3 className="text-base font-medium">
                        {data[selectedInviteIndex].listing.type}
                      </h3>
                    </div>
                    <div>
                      <h3 className="text-base pb-1">Description:</h3>
                      <p>{data[selectedInviteIndex].listing.description}</p>
                    </div>
                  </div>

                  <Form
                    className="mt-3"
                    initialValues={{
                      status: 1,
                      listing_invite_id: data[selectedInviteIndex].id,
                    }}
                    onFinish={InviteSubmit}
                  >
                    <Form.Item
                      name="invite_date_id"
                      rules={generalValidationRules}
                    >
                      <Radio.Group>
                        <Space direction="vertical">
                          {data[selectedInviteIndex].dates.map((item) => (
                            <Radio key={item.id} value={item.id}>
                              {item.date}
                            </Radio>
                          ))}
                        </Space>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item noStyle name="listing_invite_id">
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item noStyle name="status">
                      <Input type="hidden" />
                    </Form.Item>
                    <div className="flex justify-end">
                      <AppButton
                        label="Accept"
                        type="submit"
                        isLoading={isLoading}
                      />
                    </div>
                  </Form>
                  <Form
                    initialValues={{
                      status: 2,
                      listing_invite_id: data[selectedInviteIndex].id,
                    }}
                    onFinish={InviteSubmit}
                  >
                    <Form.Item noStyle name="status">
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item noStyle name="listing_invite_id">
                      <Input type="hidden" />
                    </Form.Item>

                    <div className="flex -mt-8">
                      <AppButton
                        variant="transparent"
                        type="submit"
                        isLoading={isLoading}
                        label="Decline"
                      />
                    </div>
                  </Form>
                </div>
              )}
            </Modal>
          </>
        ))
      ) : (
        <Empty />
      )}
    </div>
  );
};
