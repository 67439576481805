import axios from "axios";
import { useMutation } from "react-query";
import { END_POINT } from "config/environment";
import { ICreateEducationHistory } from "../types";

export const createEducationHistory = async (
  props: ICreateEducationHistory
) => {
  const url = `${END_POINT.BASE_URL}/v1/save-education-history`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  // necessary to make immediate changes when in  a central place when schema changes
  const data: any = {
    id: props.id,
    talent_id: props.talent_id,
    school: props.school,
    course: props.course,
    degree_id: props.degree_id,
    grade: props.grade,
    start_date: props.start_date,
    end_date: props.end_date,
    is_current: props.is_current,
    description: props.description,
  };

  const response = await axios.post(url, data, config);
  return response;
};

export const useCreateEducationHistory = () => {
  return useMutation(createEducationHistory);
};
