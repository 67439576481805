import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Tooltip,
} from "antd";
import { AppButton } from "components/button/AppButton";
import { LEVEL_LIST, WORK_MODELS } from "constants/general";
import { IdentifierProps } from "types";
import {
  generalValidationRules,
  textInputValidationRulesOpt,
} from "utils/formHelpers/validations";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useCreateJob } from "../hooks/useCreateJob";
import { IJobsProps } from "../types";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { openNotification } from "utils/notification";
import { QUERY_KEY_FOR_JOBS } from "../hooks/useGetJobs";
import { useQueryClient } from "react-query";
import { useGetSingleJob } from "../hooks/useGetSingleJob";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useGetSectors } from "../hooks/useGetSectors";
import { GENDERS, COUNTRIES, STATES } from "constants/general";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const NewJobForm = ({ open, handleClose, id }: IdentifierProps) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { token, userInfo } = useGetUserInfo();
  const { mutate, isLoading } = useCreateJob();
  const { data: sectorData, isLoading: loadingSector } = useGetSectors();
  const { data, isSuccess } = useGetSingleJob({
    token,
    id: id as number,
  });

  const [selectedState, setSelectedState] = useState(null);
  const [filteredCities, setFilteredCities] = useState<any[]>([]);
  const [editorValue, setEditorValue] = useState<string>("");

  useEffect(() => {
    if (isSuccess && id) {
      const expiry_date = dayjs(data.expiry_date);
      form.setFieldsValue({
        title: data.title,
        company_name: data.company_name,
        type: data.type,
        level: data.level,
        expiryDate: expiry_date,
        experience: data.years_of_experience,
        description: data.description,
        location_city: data.location_city,
        location_state: data.location_state,
        skills: data.skills,
        sector_id: data.sector_id,
        slot: data.slot,
      });
      setEditorValue(data.description);
    } else {
      form.resetFields();
    }
  }, [form, id, data, isSuccess]);

  const disablePastDates = (current: dayjs.Dayjs) => {
    return current && current.isBefore(dayjs().endOf('day'));
  };

  const handleSubmit = (data: any) => {
    const skills = data.skills ? data.skills.map((item: any) => item.name) : [];
    const props: IJobsProps = {
      recruiter_id: userInfo?.recruiter?.id,
      token,
      userInfo,
      title: data.title,
      sector_id: data.sector_id,
      company_name: data.company_name,
      type: data.type,
      level: data.level,
      expiry_date: data.expiryDate,
      years_of_experience: data.experience,
      description: editorValue,
      location_id: 1,
      location_city: data.location_city,
      location_state: data.location_state,
      slot: data.slot ?? 1,
      id: id as unknown as number,
      skills,
    };
    mutate(props, {
      onError: (err: any) => {
        let errorTitle = "Error";
        let errorDescription = "An error occurred, please try again.";

        if (err.response && err.response.data && err.response.data.error) {
          errorTitle = "Error: " + err.response.data.error;
          errorDescription =
            err.response.data.description || "Please try again.";
        }

        openNotification({
          title: errorTitle,
          state: "error",
          description: errorDescription,
          duration: 6,
        });
      },
      onSuccess: (res: any) => {
        openNotification({
          title: "Success",
          state: "success",
          description: "Job Created Successfully",
          duration: 4.5,
        });
        form.resetFields();
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_FOR_JOBS],
        });
        handleClose();
      },
    });
  };

  const handleStateChange = (value: any) => {
    setSelectedState(value);
    const state = STATES.find((state: any) => state.name === value);
    setFilteredCities(state ? state.cities : []);
  };

  return (
    <Modal
      width="40rem"
      style={{ top: 15 }}
      footer={false}
      open={open}
      onCancel={() => handleClose()}
      title={id ? "Edit Job" : "Add Job"}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        requiredMark="optional"
        layout="vertical"
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 mt-5"
      >
        <Form.Item
          rules={generalValidationRules}
          name="title"
          label="Job Title"
          className="md:col-span-2"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={textInputValidationRulesOpt}
          name="company_name"
          label="Company Name"
          className="md:col-span-2"
        >
          <Input />
        </Form.Item>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 col-span-4 gap-y-3 md:gap-y-0 gap-x-3 -mt-3">
          <Form.Item
            rules={generalValidationRules}
            name="sector_id"
            label="Sector"
          >
            <Select
              allowClear
              placeholder="Select"
              loading={loadingSector}
              options={sectorData?.map((sector) => ({
                value: sector.id,
                label: sector.name,
              }))}
            />
          </Form.Item>

          <Form.Item
            rules={generalValidationRules}
            name="location_state"
            label="Location (State)"
          >
            <Select
              allowClear
              placeholder="Select"
              options={STATES.map((state) => ({
                value: state.name,
                label: state.name,
              }))}
              onChange={handleStateChange}
            />
          </Form.Item>

          <Form.Item
            rules={generalValidationRules}
            name="location_city"
            label="Location (City)"
          >
            <Select
              options={filteredCities.map((city) => ({
                value: city,
                label: city,
              }))}
              allowClear
              placeholder="Select"
            />
          </Form.Item>

          <Form.Item rules={generalValidationRules} name="level" label="Level">
            <Select options={LEVEL_LIST} placeholder="Select" allowClear />
          </Form.Item>

          <Form.Item
            rules={generalValidationRules}
            name="type"
            label="Work Model"
          >
            <Select options={WORK_MODELS} placeholder="Select" allowClear />
          </Form.Item>
          <Form.Item
            rules={generalValidationRules}
            name="experience"
            label="Years of Experience"
          >
            <InputNumber min={1} className="w-full" placeholder="2" />
          </Form.Item>
        </div>

        <Form.Item
          name="expiryDate"
          label="Expiry Date"
          rules={generalValidationRules}
          className="md:col-span-2"
        >
          <DatePicker disabledDate={disablePastDates} className="w-full" format="YYYY-MM-DD"/>
        </Form.Item>

        <Form.Item
          name="slot"
          label="Number of slot"
          rules={generalValidationRules}
          className="md:col-span-2"
        >
          <InputNumber min={1} className="w-full" placeholder="1" />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          className="col-span-4 -mt-2"
          rules={textInputValidationRulesOpt}
        >
          <ReactQuill
            value={editorValue}
            onChange={setEditorValue}
            placeholder="Write something..."
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [
                  { list: "ordered" },
                  { list: "bullet" },
                  { indent: "-1" },
                  { indent: "+1" },
                ],
                ["link", "image"],
                ["clean"],
              ],
            }}
          />
        </Form.Item>
        <div className="col-span-4">
          <Form.List name="skills">
            {(fields, { add, remove }) => (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4">
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key} className="flex items-baseline gap-1">
                    <Form.Item
                      {...restField}
                      name={[name, "name"]}
                      rules={[{ required: true, message: "Field is required" }]}
                    >
                      <Input placeholder="Enter Skill" className="w-full" />
                    </Form.Item>

                    <Tooltip title="Remove">
                      <MinusCircleOutlined
                        className="text-base pl-1"
                        onClick={() => remove(name)}
                      />
                    </Tooltip>
                  </div>
                ))}
                <Form.Item>
                  <Button type="default" onClick={() => add()} block>
                    + Add Skills (Optional)
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </div>
        <div className="col-span-4 flex justify-end gap-2">
          <AppButton
            label="Cancel"
            variant="transparent"
            handleClick={() => handleClose()}
          />
          <AppButton label="Save Job" type="submit" isLoading={isLoading} />
        </div>
      </Form>
    </Modal>
  );
};
