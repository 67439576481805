import { Drawer, Form, Select } from "antd";
import { AppButton } from "components/button/AppButton";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { IdentifierProps } from "types";
import { useGetFolders } from "../hooks/useGetFolders";
import { useAddAndRemoveTalent } from "../hooks/useAddAndRemoveTalent";
import { openNotification } from "utils/notification";
import { IAddTalent } from "../types";
import { useQueryClient } from "react-query";
import { QUERY_KEY_FOR_FOLDERS } from "../hooks/useGetFolders";
import { useState } from "react";
import { useDebounce } from "hooks/useDebounce";

export const AddToFolderForm = ({
  open,
  handleClose,
  arrayId,
}: IdentifierProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm);
  const { data, isLoading: loadingFolders } = useGetFolders({
    search: debouncedSearchTerm,
  });
  const { token } = useGetUserInfo();
  const { mutate, isLoading } = useAddAndRemoveTalent();
  const queryClient = useQueryClient();

  const handleSearch = (val: string) => {
    setSearchTerm(val);
  };

  const handleSubmit = (values: any) => {
    if (!arrayId) {
      return;
    }
    const props: IAddTalent = {
      token,
      talent_id: arrayId,
      folder_id: values.folder_id,
      request_url: "add-talents-to-folder",
    };
    mutate(props, {
      onError: (err: any) => {
        let errorTitle = "Error";
        let errorDescription = "An error occurred, please try again.";

        if (err.response && err.response.data &&  err.response.data.error) {
          errorTitle = "Error: " + err.response.data.error;
          errorDescription = err.response.data.description || "Please try again.";
        }

        openNotification({
          title: errorTitle,
          state: "error",
          description: errorDescription,
          duration: 6,
        });
      },
      onSuccess: (res: any) => {
        openNotification({
          title: "Success",
          state: "success",
          description: "Talent added to folder successfully",
          duration: 4.5,
        });
        queryClient.invalidateQueries([QUERY_KEY_FOR_FOLDERS]);
        handleClose();
      },
    });
  };

  return (
    <Drawer title="Add to folder" open={open} onClose={() => handleClose()}>
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="folder_id" label="Select folder">
          <Select
            onClear={() => setSearchTerm("")}
            onSearch={handleSearch}
            allowClear
            showSearch
            loading={loadingFolders}
            placeholder="Select"
            options={data?.data.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            filterOption={false}
          />
        </Form.Item>

        <AppButton type="submit" label="Add" isLoading={isLoading} />
      </Form>
    </Drawer>
  );
};
