import authImage from "../assets/images/authSvg.svg";
import logo from "assets/images/logoSvg.svg";
import "../assets/style.css";
import { RadioForm, radioFormOptions } from "../components/RadioForm";
import { SignUpForm } from "../components/SignUpForm";
import { useState } from "react";
import { useIsAuthenticated } from "react-auth-kit";
import { Link, Navigate } from "react-router-dom";
import { routePath } from "config/routeMgt/routePath";

export const SignUp = () => {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [step, setStep] = useState<boolean>(false);
  const isAuthenticated = useIsAuthenticated();
  return (
    <>
      {isAuthenticated() && <Navigate to={routePath.home} replace={true} />}

      <div className="grid grid-cols-1 lg:grid-cols-2 h-screen overflow-auto">
        <div className="Container py-3 bg-white">
          <div className="mt-2">
            <img src={logo} alt="logo" />
          </div>
          <div className="flex justify-center mt-10">
            <div className="authFormWrap">
              {step ? (
                <SignUpForm
                  selectedOptionProp={selectedOption}
                  setStepProp={setStep}
                />
              ) : (
                <RadioForm
                  options={radioFormOptions}
                  selectedOptionProp={selectedOption}
                  setSelectedOptionProp={setSelectedOption}
                  setStepProp={setStep}
                />
              )}
            </div>
          </div>
        </div>
        <div className="bg-primary h-screen Container lg:flex items-center justify-center hidden w-full sticky top-0">
          <img src={authImage} alt="auth background" className="max-h-96" />
        </div>
      </div>
    </>
  );
};
