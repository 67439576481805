import axios from "axios";
import { useMutation } from "react-query";
import { END_POINT } from "config/environment";
import { careerInterestProps } from "../types";

export const createDate = async (props: careerInterestProps) => {
  const url = `${END_POINT.BASE_URL}/v1/save-career-interest`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  // necessary to make immediate changes when in  a central place when schema changes
  const data: any = {
    talent_id: props.talent_id,
    name: props.name,
  };

  const response = await axios.post(url, data, config);
  return response;
};

export const useCreateCareerInterest = () => {
  return useMutation(createDate);
};
