import axios from "axios";
import { useMutation } from "react-query";
import { END_POINT } from "config/environment";
import { IAddTalent } from "../types";

const getData = async (props: IAddTalent) => {
  const url = `${END_POINT.BASE_URL}/v1/${props.request_url}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  const data: any = {
    talent_id: props.talent_id,
    folder_id: props.folder_id,
  };

  const response = await axios.post(url, data, config);
  return response;
};

export const useAddAndRemoveTalent = () => {
  return useMutation(getData);
};
