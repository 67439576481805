import axios from "axios";
import { useQuery } from "react-query";
import { END_POINT } from "config/environment";
import { IGeneralProps } from "types";
import { IRecruiterProfile } from "features/settings/types";

export const QUERY_KEY_FOR_RECRUITER_DETAILS = "recruiter-details";

const getSingleRecruiter = async (
  props: IGeneralProps
): Promise<IRecruiterProfile> => {
  const id = props.id;
  const url = `${END_POINT.BASE_URL}/v1/administrator/recruiter/${id}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  const res = await axios.get(url, config);
  const item = res.data.data;
//   console.log(item);
  
  const data: IRecruiterProfile = { ...item };

  return data;
};

export const useFetchRecruiterDetails = ({ id, token }: IGeneralProps) => {
  const queryData = useQuery([QUERY_KEY_FOR_RECRUITER_DETAILS, id], () =>
    getSingleRecruiter({
      id,
      token,
    })
  );

  return queryData;
};
