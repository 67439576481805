import { IAcceptInvite } from '../types';
import { useGetUserInfo } from 'hooks/useGetUserInfo';
import { useAcceptJobOffer } from './useAcceptJobOffer';
import { openNotification } from 'utils/notification';
import { useQueryClient } from 'react-query';
import { Form } from 'antd';
import { QUERY_KEY_FOR_TALENT_INVITE } from './useFetchTalentInvites';

export const useHandleAcceptOffer= () => {
    const queryClient = useQueryClient();
    const [form] = Form.useForm();
    const { token } = useGetUserInfo();
    const { mutate, isLoading,  } = useAcceptJobOffer();
    const acceptOffer = (values: any) => {
        const props: IAcceptInvite = {
          token,
          ...values,
        };
        mutate(props, {
          onError: (err: any) => {
            openNotification({
              title: "Error",
              state: "error",
              description: "Error occurred try again",
              duration: 4.5,
            });
          },
          onSuccess: (res: any) => {
            openNotification({
              title: "Success",
              state: "success",
              description: "Successfully",
              duration: 4.5,
            });
            form.resetFields();
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY_FOR_TALENT_INVITE],
            });
            // setOpenDetails(false);
          },
        });
      };
  return {isLoading, acceptOffer}
  
}
