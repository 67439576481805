import { Popconfirm, Progress } from "antd";
import planIcon from "../assets/images/activePlanIcon.svg";
import "../assets/style.css";
import { Link } from "react-router-dom";
import { routePath } from "config/routeMgt/routePath";
import { BillingHistory } from "./BillingHistory";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { QUERY_KEY_FOR_SUBSRIPTION, useGetSubscription } from "../hooks/useGetSubscription";
import CurrencyFormatter from "utils/CurrencyFormatter";
import { PricingComponent } from "features/pricing/components/PricingComponent";
import { useCancelSubscription } from "../hooks/useCancelSubscription";
import { openNotification } from "utils/notification";
import { useQueryClient } from "react-query";

export const Billings = () => {
  const { token, userInfo } = useGetUserInfo();
  const { data, isLoading } = useGetSubscription();
  const { mutate:update, isLoading:loading } = useCancelSubscription();
  const queryClient = useQueryClient();

  const handleSubmit = () => {
    const props = {
      token
    };
    update(props, {
      onError: (err: any) => {
        let errorTitle = "Error";
        let errorDescription = "An error occurred, please try again.";

        if (err.response && err.response.data &&  err.response.data.error) {
          errorTitle = "Error: " + err.response.data.error;
          errorDescription = err.response.data.description || "Please try again.";
        }

        openNotification({
          title: errorTitle,
          state: "error",
          description: errorDescription,
          duration: 6,
        });
      },
      onSuccess: (res: any) => {
        openNotification({
          title: "Success",
          state: "success",
          description: "Updated Successfully",
          duration: 4.5,
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_FOR_SUBSRIPTION],
        });
      },
    });
  };
  return (
    <>
    {
      data?.data && data?.data?.subscription?.status === "active"?
      <>
      <div className="mb-4">
        <h1 className="font-semibold text-lg text-accent">Plans and bills</h1>
        <h3 className="text-sm">Manage your plan and billing details.</h3>
      </div>
      <hr />

      <div className="bg-white border rounded-md mt-5 pb-2">
        <div className="flex items-center gap-x-2 px-3 py-2">
          <img src={planIcon} alt="plan icon" className="h-8" />
          <h2 className="font-medium text-accent">{data?.data.package.name}</h2>
        </div>
        <hr />
        <div className="px-3 py-3">
          <button
            className="rounded-md px-2 font-medium"
            style={{ background: "#ECFDF3", color: "#027A48" }}
          >
            Active plan
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-3 px-3 pb-2">
          <div>
            <div className="">
              <h2 className="font-bold text-xl pb-2 text-accent">
                <CurrencyFormatter value={data?.data.subscription.amount} decimalPrecision={0} /> <sub className="text-xs text-gray-500">{data?.data.subscription.plan_duration === "monthly" ? 'per month' :
                 data?.data.subscription.plan_duration  === "quarterly" ? 'per quarter' :
                 data?.data.subscription.plan_duration  === "biannually" ? 'per six months' :
                 data?.data.subscription.plan_duration  === "annually" ? 'per annum' : ''}</sub>
              </h2>
              <p>{data?.data.package.description}</p>
            </div>
          </div>
          <div>
            <div className="flex justify-between text-primary text-sm">
              <span>Days</span>
              <span>{data?.data.subscription.used_days} of {data?.data.subscription.plan_duration === "monthly" ? '30 days' :
                 data?.data.subscription.plan_duration  === "quarterly" ? '90 days' :
                 data?.data.subscription.plan_duration  === "biannually" ? '180 days' :
                 data?.data.subscription.plan_duration  === "annually" ? '365 days' : ''}</span>
            </div>
            <Progress percent={data?.data.subscription.percentage_passed} showInfo={false} />
            <span>{data?.data.subscription.remaining_days} days remaining for your plan to expire</span>
          </div>
        </div>
        <hr />

        <div className="flex gap-x-3 lg:justify-end mt-4 px-2">
          <Popconfirm
                  okButtonProps={{
                    style: { backgroundColor: '#f5222d', color: '#ffffff' }, // Custom styles
                  }} 
            title="Cancel subscription"
            description="Are you sure to cancel your subscription?"
            onConfirm={() => handleSubmit()}
          >
            <button
              className="transparentButton"
              style={{ background: "#F4F5FF" }}
            >
              Cancel Subscription
            </button>
          </Popconfirm>

          
          <Link to={routePath.pricing} className="button">
            Upgrade Plan
          </Link>
        </div>
      </div>
      </>
      :
      <PricingComponent/>
    }
    <BillingHistory />
    </>
  );
};
