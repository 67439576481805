import { DashboardLayout } from "admin/components/layout/components/DashboardLayout";
import { Navbar } from "admin/components/layout/components/Navbar";
import { useState } from "react";
import { AddRole } from "../components/AddRole";
import { hasPermission } from "utils/hasPermission";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { RolesTable } from "../components/RolesTable";

export const AdminRoles = () => {
  const [addRoleModal, setAddRoleModaL] = useState(false);
  const { userInfo } = useGetUserInfo();

  const hasPermissionFor = (...permissionNames: string[]): boolean => {
    return permissionNames.some(permissionName =>
      hasPermission(userInfo, permissionName)
    );
  };

  return (
    <DashboardLayout>
      <Navbar title="Roles" description="View and manage Roles" />
      <AddRole open={addRoleModal} handleClose={() => setAddRoleModaL(false)} />
      
      <div className="bg-gray-100 px-4 py-3 mt-8 rounded-md flex justify-between items-center">
        <div>

        </div>

        { hasPermissionFor('Manage Admin users') &&
          <div className="flex justify-end">
            <button
              className="button flex items-center gap-x-2"
              onClick={()=>setAddRoleModaL(true)}
            >
              <i className="ri-add-line text-md"></i>
              <span>Add New Role</span>
            </button>
          </div>
        }
      </div>

      <RolesTable />
    </DashboardLayout>
  );
};
