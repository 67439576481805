import checkIcon from "../assets/images/CheckIcon.svg";
import { AppButton } from "components/button/AppButton";
import { Skeleton } from "antd";
import { useGetPackage } from "../hooks/useGetPakage";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { SelectPaymentGateway } from "../components/SelectPaymentGateway";
import { useState } from "react";
import { useGetSubscription } from "features/settings/hooks/useGetSubscription";
import CurrencyFormatter from "utils/CurrencyFormatter";
import { SelectPackages } from "./SelectPackages";

export const PricingComponent = () => {
  const { data, isLoading } = useGetPackage();
  const { userInfo } = useGetUserInfo();
  const [initPay, setInitPay] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any>('');
  const [upgrade, setUpgrade] = useState(false);
  const { data: sub, isLoading: loading } = useGetSubscription();

  const renderButton = (item: { id: any; name?: string; description?: string; type?: string; account_type?: string; amount?: number; currency?: string; status?: string; benefits?: any[]; }) => {
    if (
      sub?.data?.subscription &&
      sub?.data?.subscription.package_id === item.id &&
      sub?.data?.subscription.status === "active"
    ) {
      return (
        <AppButton
          label="Current plan"
          additionalClassNames={[
            "text-green-700 font-semibold w-full bg-green-100 py-2 rounded",
          ]}
        />
      );
    } else if (
      sub?.data?.subscription &&
      sub?.data?.subscription.status === "active" &&
      sub?.data?.subscription.package_id !== item.id
    ) {
      return (
        <AppButton
          label="Upgrade"
          additionalClassNames={[
            "text-white font-semibold w-full bg-primary py-2 rounded",
          ]}
          handleClick={() => {
            setSelectedPlan(item);
            setUpgrade(true);
            setInitPay(true);
          }}
        />
      );
    } else if(userInfo?.recruiter?.is_corporate === 1 && item.account_type !== "enterprise" ){
      return ;
    } 
    else {
      return (
        <AppButton
          label="Subscribe"
          additionalClassNames={[
            "text-white font-semibold w-full bg-primary py-2 rounded",
          ]}
          handleClick={() => {
            setSelectedPlan(item);
            setInitPay(true);
          }}
        />
      );
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">

      {  userInfo?.recruiter?.is_corporate === 1 ? selectedPlan &&(
        <SelectPackages
          open={initPay}
          handleClose={() => {setInitPay(false); setSelectedPlan('')}}
          data={{ upgrade: upgrade, data:selectedPlan }}
        />
      )
    :
    selectedPlan && (
      <SelectPaymentGateway
        open={initPay}
        handleClose={() => setInitPay(false)}
        data={{ upgrade: upgrade, data:selectedPlan }}
      />
    )
    }

      <Skeleton active loading={isLoading}>
        {data?.map((item, i) => (
          <div key={i} className="flex flex-col h-full rounded-md border shadow px-5 pb-7 pt-10 text-center bg-white">
            <div className="flex-grow">
              <h2 className="font-bold text-xl text-primary">
              <CurrencyFormatter value={Number(item.monthly)} decimalPrecision={0} /> - <CurrencyFormatter value={Number(item.annually)} decimalPrecision={0} />
              </h2>
              <div className="my-3">
                <h4 className="font-semibold text-base text-primary">{item.name}</h4>
                <h3 className="text-xs">{item.description}</h3>
              </div>
              <ul className="flex flex-col gap-2 text-left">
                {item.permissions.map((permission, index) => (
                  <li key={index} className="flex gap-x-3 justify-start">
                    <img src={checkIcon} alt="check icon" />
                    <h3 className="text-sm">{permission}</h3>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-8">
              {renderButton(item)}
            </div>
          </div>
        ))}
      </Skeleton>

    </div>
  );
};