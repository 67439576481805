import React, { useState } from "react";
import { Skeleton, Modal } from "antd";
import { TalentProfile } from "features/settings/types";

export const ExSummary : React.FC<{
  data: TalentProfile["data"] | null;
  isLoading?: boolean;
}> = ({ data, isLoading }) => {
  return (
    <div>
      <Skeleton active loading={isLoading}>
      <div>
      <h3 className=" font-bold text-lg pt-8">Executive Summary</h3>
        <p className="text-left py-3">
         {data?.executive_summary}
        </p>
        </div>
      </Skeleton>


      <Skeleton active loading={isLoading}>
        <div>
        <h3 className=" font-bold text-lg pt-8">Professional Goal</h3>
        <p className="text-left py-3">
         {data?.professional_goal}
        </p>
        </div>
      </Skeleton>
    </div>
  );
};