import { Input, Checkbox, Form, Select } from "antd";
import { AppButton } from "components/button/AppButton";
import { routePath } from "config/routeMgt/routePath";
import { Link } from "react-router-dom";
import {
  emailValidationRules,
  generalValidationRules,
  textInputValidationRules,
} from "utils/formHelpers/validations";
import { loginUser } from "../hooks/useLoginUser";
import { useMutation } from "react-query";
import { useSignIn } from "react-auth-kit";
import { ISignInUserProps } from "../types";
import { openNotification } from "utils/notification";
import googleLogo from "assets/images/googleIcon.png";
import linkedinLogo from "assets/images/linkedinIcon.png";
import { useEffect, useState } from "react";
import { END_POINT } from "config/environment";
import { BeatLoader } from "react-spinners";

export const SignInForm = () => {
  const signIn = useSignIn();
  const { mutate, isLoading } = useMutation(loginUser);
  const [loginUrl, setLoginUrl] = useState('');
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(false);
  
  const handleSubmit = (data: any) => {
    const props: ISignInUserProps = {
      email: data.email,
      password: data.password,
      type: data.type,
    };

    mutate(props, {
      onError: (err: any) => {
        openNotification({
          title: "Error",
          state: "error",
          description: err.response.data.msg,
          duration: 8.0,
        });
      },
      onSuccess: (res: any) => {
        const result = res.data;
        if (
          signIn({
            token: result.token,
            tokenType: "Bearer",
            authState: result,
            expiresIn: 180,
          })
        ) {
          openNotification({
            title: "Success",
            state: "success",
            description: "Logged in successfully",
            duration: 4.5,
          });
        }
      },
    });
  };
  
  const redirect = (platform:string) => {
    if (!type) {
      openNotification({
        title: 'Error',
        state: 'error',
        description: 'Please select an account type',
        duration: 4.5,
      });
      return;
    }
    setLoading(true);
    fetch(`${END_POINT.AUTH_URL}/${platform === 'google' ? 'auth':'auth/linkedin'}?type=${type}&is_corporate=2`,{
      headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      }
    })
    .then((response) => {
        if (response.ok) {
            return response.json();
        }
        setLoading(false);
        throw new Error('Something went wrong!');
    })
    .then((data) => window.location.assign(data.url))
    .catch((error) => console.error(error));
  };

  return (
    <>
      <Form onFinish={handleSubmit} layout="vertical" requiredMark={false}>
        <Form.Item
          name="type"
          label="Account type"
          rules={generalValidationRules}
        >
          <Select
            options={[
              { label: "Recruiter / Enterprise", value: "recruiter" },
              { label: "Talent", value: "talent" },
            ]}
            placeholder="Select"
            onChange={(e)=>setType(e)}
            allowClear
          />
        </Form.Item>

        <Form.Item name="email" label="Email" rules={emailValidationRules}>
          <Input placeholder="Enter your email" className="py-2 shadow-sm" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={generalValidationRules}
          className="-mt-3"
        >
          <Input.Password
            placeholder="***********"
            className="shadow-sm py-2"
          />
        </Form.Item>
        <div className="flex justify-between mb-6 -mt-2">
          <Checkbox>Remember me</Checkbox>
          <Link
            to={routePath.forgot_password}
            className="text-primary font-medium"
          >
            Forgot password
          </Link>
        </div>
        <AppButton
          label="Sign in"
          type="submit"
          additionalClassNames={["button w-full py-1"]}
          isLoading={isLoading}
        />
      </Form>

      <button onClick={()=>redirect('google')} className="flex justify-center gap-x-2 text-accent hover:text-primary py-2 border border-slate-300 rounded w-full mt-4 shadow-sm text-sm">
        <img src={googleLogo} alt="google auth" className="h-5" />
        <span>{loading ? <BeatLoader color="#222" /> : "Sign in with Google"}</span>
      </button>

      <button onClick={()=>redirect('linkedin')} className="flex justify-center gap-x-2 text-accent hover:text-primary py-2 border border-slate-300 rounded w-full mt-4 shadow-sm text-sm">
        <img src={linkedinLogo} alt="linkedin auth" className="h-5" />
        <span>{loading ? <BeatLoader color="#222" /> : "Sign in with Linkedin"}</span>
      </button>
    </>
  );
};
