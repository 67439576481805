import axios from "axios";
import { useMutation } from "react-query";
import { END_POINT } from "config/environment";
import { IJobInvite } from "../types";

export const sendJobInvite = async (props: IJobInvite) => {
  const corporate_id = props?.userInfo?.current_team ? props?.userInfo?.current_team.corporate_id :'';

  const url = `${END_POINT.BASE_URL}/v1/send-invite`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  // necessary to make immediate changes when in  a central place when schema changes
  const data: any = {
    talent_id: props.talent_id,
    listing_id: props.listing_id,
    interview_date: props.interview_date,
    meeting_platform: props.meeting_platform,
    note: props.note,
    corporate_id
  };

  const response = await axios.post(url, data, config);
  return response;
};

export const useSentJobInvite = () => {
  return useMutation(sendJobInvite);
};
