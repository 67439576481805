import { DashboardLayout } from "admin/components/layout/components/DashboardLayout";
import { Navbar } from "admin/components/layout/components/Navbar";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { AdminCount } from "./components/AdminCount";
//import { messaging } from "../../firebase";
//import { getToken } from "firebase/messaging";
import { useEffect } from "react";
import { sendTokenToServer } from "./hooks/syncToken";
import { useMutation } from "react-query";
import { loginUser } from "features/Authentication/hooks/useLoginUser";
import { AdminChart } from "./components/AdminChart";

export const AdminHome = () => {
  const { userInfo, token } = useGetUserInfo();
  const { mutate, isLoading } = useMutation(sendTokenToServer);
/*
  useEffect(()=>{
    requestPermission();
  },[]);

  const requestPermission = async() => {

    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const fcmToken = await getToken(messaging, {
        vapidKey: "BFj4_XwyXioNRKKJjkU4gTIB5FHw_SHKboS_bT8U-y76vw1b-iLrBZ2VLEY-LPtzuABWGnV3LkZ7WdQo5vXZiZ8",
      });
      const storedToken = localStorage.getItem('fcmToken');
      if (!storedToken || storedToken !== fcmToken) {
        const props: any = {
          token: fcmToken,
          authToken: token
        };
        mutate(props, {
          onError: (err: any) => {
          },
          onSuccess: (res: any) => {
            localStorage.setItem('fcmToken', fcmToken);
          },
        });
      }

    } else if (permission === "denied") {

      //alert("You denied for the notification");

    }

  };
  */

  return (
    <DashboardLayout>
      <Navbar
        description="Admin Dashboard"
        title={`Welcome back, ${userInfo?.name}`}
      />
      <AdminCount />
      <div className="py-6" />
      <AdminChart />
    </DashboardLayout>
  );
};
