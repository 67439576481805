import axios from "axios";
import { useMutation } from "react-query";
import { TUpdatePackagePlan } from "../types";
import { END_POINT } from "config/environment";

export const updatePackagePlan = async (props: TUpdatePackagePlan) => {
  const url = `${END_POINT.BASE_URL}/v1/${props.operation}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  const data: any = {
    user_id: props.user_id,
    id: props.id,
  };

  const response = await axios.post(url, data, config);
  return response;
};

export const useUpdatePackagePlan = () => {
  return useMutation(updatePackagePlan);
};
