import { Dropdown, Menu, Popconfirm } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { Link } from "react-router-dom";
import { QUERY_KEY_FOR_USERS, useFetchUsers } from "../hooks/useFetchUsers";
import { usePagination } from "hooks/usePagination";
import { IUsers } from "../types";
import userDp from "assets/images/placeholderImage.jpeg";
import { useDelete } from "hooks/useDelete";
import { EditUser } from "./EditUser";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { hasPermission } from "utils/hasPermission";

export const UsersTable = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const { pagination, onChange } = usePagination();
  const [editModal, setEditModaL]= useState<boolean>(false);
  const [userData, setUserData] = useState<any>();
  const { userInfo } = useGetUserInfo();
  const deleteEndPointUrl="administrator/delete-user/";


  const hasPermissionFor = (...permissionNames: string[]): boolean => {
    return permissionNames.some(permissionName =>
      hasPermission(userInfo, permissionName)
    );
  };

  const { removeData } = useDelete({
    queryKey: QUERY_KEY_FOR_USERS,
    deleteEndPointUrl,
  });

  const { data, isFetching } = useFetchUsers({
    pagination
  });
  const columns: ColumnsType<IUsers> = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role",
      //dataIndex: "roles",
      render: (_, val) => (
        <span>
          {val.roles[0]['name']}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "banned",
      render: (_, val) => (
        <div>
          {val?.banned ? 'banned' : 'active'}
        </div>
      ),
    },
    {
      title: "Avatar",
      dataIndex: "avatar",
      render: (_, val) => (
        <div>
          <img
            src={val.dp ? val.dp : userDp}
            alt="avatar"
            className="rounded-full h-10 w-10 object-cover"
          />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",

      render: (_, val) => (
        <div>
          {
            userInfo?.id !== val.id && hasPermissionFor('Manage Admin users') &&
            <Dropdown
              trigger={["click"]}
              disabled={selectedRowKeys.length > 0}
              overlay={
                <Menu>
                  <Menu.Item key="2" onClick={() => {setUserData(val); setEditModaL(true); }}>
                    Edit
                  </Menu.Item>
                  <Menu.Item key="3">
                    <Popconfirm
                  okButtonProps={{
                    style: { backgroundColor: '#f5222d', color: '#ffffff' }, // Custom styles
                  }} 
                      title="Delete user"
                      description="Are you sure to delete this user?"
                      onConfirm={() => removeData(val.id)}
                    >
                      Delete
                    </Popconfirm>
                  </Menu.Item>
                </Menu>
              }
            >
              <i className="ri-more-2-fill text-lg cursor-pointer"></i>
            </Dropdown>
          }
        </div>
      ),
    },
  ];

  return (
    <div>
      <EditUser
        open={editModal} 
        handleClose={()=>setEditModaL(false)}
        data = {userData}
      />

      <Table
        className="bg-white rounded-md shadow border mt-8"
        columns={columns}
        dataSource={data?.data.map((item) => ({ ...item, key: item.id }))}
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys: React.Key[], rows) => {
            setSelectedRowKeys(rows.map((row) => row.id));
          },
        }}
        scroll={{ x: 550 }}
        loading={isFetching}
        pagination={{ ...pagination, total: data?.total }}
        onChange={onChange}
      />
    </div>
  );
};
