import { useNavigate } from "react-router-dom";
export const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full bg-white text-center">
      <div className="bg-white h-screen flex justify-center items-center">
        <div className="px-3">
          <div className="flex justify-center">
            <i className="ri-error-warning-line text-4xl text-yellow-400"></i>
          </div>
          <h1 className="md:text-3xl text-xl py-3 font-medium">
            Page Not Found.
          </h1>
          <p className="pb-5">
            The page you're looking for does not seem to exist.
          </p>
          <button onClick={() => navigate(-1)} className="button bg-primary">
            Return to last page
          </button>
        </div>
      </div>
    </div>
  );
};
