import { Tabs } from "antd";
import { DashboardLayout } from "components/layout/components/DashboardLayout";
import { Navbar } from "components/layout/components/Navbar";
import { Pending } from "../components/talentInvites/Pending";
import { Progressed } from "../components/talentInvites/Progressed";
import { JobOffers } from "../components/talentInvites/JobOffers";
import { Declined } from "../components/talentInvites/Declined";
import { useFetchTalentInvites } from "../hooks/useFetchTalentInvites";
import { ScaleLoader } from "react-spinners";

export const Invite = () => {
  const { data, isLoading } = useFetchTalentInvites();

  // Filter invites based on their status
  const pendingInvites = data
    ? data.filter((invite) => invite.status === 0)
    : [];
  const progressedInvites = data
    ? data.filter((invite) => invite.status === 1)
    : [];
  const declinedInvites = data
    ? data.filter((invite) => invite.status === 2)
    : [];

  const jobOffer = data
    ? data.filter((invite) => invite.sent_offer === 1)
    : [];

  return (
    <DashboardLayout>
      <Navbar title="Settings" description="" />
      <Tabs defaultActiveKey="1" size="large">
        <Tabs.TabPane tab="Pending Invites" key={1} className="tabBg">
          <Pending data={pendingInvites} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Accepted" key={2}>
          <Progressed data={progressedInvites} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Declined" key={3}>
          <Declined data={declinedInvites} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Job Offers" key={4}>
          <JobOffers data={jobOffer}/>
        </Tabs.TabPane>
      </Tabs>
      <div className="flex justify-center mt-8">
        {isLoading ? <ScaleLoader color="var(--app-color-primary)" /> : null}
      </div>
    </DashboardLayout>
  );
};
