import { MinusCircleOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Tooltip, Steps, Progress } from "antd";
import { AppButton } from "components/button/AppButton";
import { useCreateCareerInterest } from "features/settings/hooks/useCreateCareerInterest";
import { QUERY_KEY_FOR_CAREER_INTEREST } from "features/settings/hooks/useGetCareerInterest";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useQueryClient } from "react-query";
import { IdentifierProps } from "types";
import { textInputValidationRules } from "utils/formHelpers/validations";
import { openNotification } from "utils/notification";

export const RecruiterProgress = ({ open, handleClose, data }: IdentifierProps) => {
  const { mutate, isLoading } = useCreateCareerInterest();
  const { token, userInfo } = useGetUserInfo();
  const queryClient = useQueryClient();
  
  return (
    <Modal
      title="Get Started"
      open={open}
      onCancel={() => handleClose(false)}
      footer={null}
      width="45rem"
      centered
    >

    <p>Complete your registration to verify your account.</p>
    <div className="pt-4">
        <div className="py-4">
            <Progress percent={data?.total} />
        </div>
    
        <Steps
            direction="vertical"
            className="text-primary"
            items={[
            {
                title: 'Complete your profile',
                description:"",
                status: data?.profile_section == 100 ? 'finish': 'wait'
            },
            {
                title: 'Upload your  Documents',
                description:"",
                status: data?.documents == 100 ? 'finish': 'wait'
            }
            ]}
        />
        </div>
    </Modal>
    
  );
};
function useGetQueryClient() {
  throw new Error("Function not implemented.");
}
