import { DatePicker, Drawer, Form, Select } from "antd";
import { AppButton } from "components/button/AppButton";
import { WORK_MODELS } from "constants/general";
import { useGlobalContext } from "stateManagers/GlobalContext";
import { IdentifierProps } from "types";
import { openNotification } from "utils/notification";

export const FilterJob = ({ open, handleClose }: IdentifierProps) => {
  const { filterJobState, setFilterJobState } = useGlobalContext();
  const handleSubmit = (data: any) => {
    const startD = data.duration ? data.duration[0].format("YYYY-MM-DD") : null;
    const endD = data.duration ? data.duration[1].format("YYYY-MM-DD") : null;
     if (data.duration || data.model) {
      setFilterJobState({
        ...filterJobState,
        start_date: startD,
        end_date: endD,
        work_model: data.model,
      });
      handleClose();
     } else {
      openNotification({
        state: "error",
        title: "Error",
        description: "Please filter with at least 1 value",
        duration: 4.5,
      });
     }
  };
  return (
    <Drawer open={open} onClose={() => handleClose()} title="Filter Jobs">
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="model" label="Work model">
          <Select allowClear placeholder="Select" options={WORK_MODELS} />
        </Form.Item>
        <Form.Item name="duration" label="Expire Date">
          <DatePicker.RangePicker className="w-full" format="YYYY/MM/DD" />
        </Form.Item>
        <div className="flex items-center justify-end gap-4">
          <AppButton type="reset" label="Reset" variant="transparent" />
          <AppButton type="submit" label="Apply" />
        </div>
      </Form>
    </Drawer>
  );
};
