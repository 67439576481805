import { HomeCard } from "./HomeCard";
import { useGetRecruiterCount } from "../hooks/useGetRecruiterCount";
import { useState, useEffect } from "react";
import { RecruiterProgress } from "./RecruiterProgress";
import { RecruiterChart } from "./RecruiterChart";

export const RecruiterCount = () => {
  const { data } = useGetRecruiterCount();
  const [isOpen, setIsOpen ] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (data?.profile.total !== 100) {
        setIsOpen(true);
      }
    }, 2000);
  
    return () => clearTimeout(timer);
  }, [data]);

  return (
    <>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">

      <RecruiterProgress
        open={isOpen}
        data={data?.profile}
        handleClose={() => setIsOpen(false)}
      />

      <HomeCard
        title="Accepted"
        count={data?.accepted_invites ? data?.accepted_invites : 0}
        percentage={40}
      />
      <HomeCard
        title="Pending"
        count={data?.pending_invites ? data?.pending_invites : 0}
        percentage={10}
      />
      <HomeCard
        title="Rejected"
        count={data?.rejected_invites ? data?.rejected_invites : 0}
        percentage={20}
      />
    </div>

    <RecruiterChart stats={data}/>
    </>
  );
};
