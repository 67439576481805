import { DatePicker, Form, Input, Modal, Select, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import { AppButton } from "components/button/AppButton";
import dayjs from "dayjs";
import { useGetSectors } from "features/jobs/hooks/useGetSectors";
import { useCreateVolunteerExperience } from "features/settings/hooks/useCreateVolunteerExperience";
import { QUERY_KEY_FOR_SINGLE_VOLUNTEER, useGetSingleVolunteer } from "features/settings/hooks/useGetSingleVolunteer";
import { QUERY_KEY_FOR_TALENT_PROFILE } from "features/settings/hooks/useGetTalentProfile";
import { ICreateWorkHistory } from "features/settings/types";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { IdentifierProps } from "types";
import {
  generalValidationRules,
  textInputValidationRules,
} from "utils/formHelpers/validations";
import { openNotification } from "utils/notification";

export const AddVolunteerExperience = ({ open, handleClose, id }: IdentifierProps) => {
  const [currentWork, setCurrentWork] = useState(false);
  const [form] = Form.useForm();
  const { token, userInfo } = useGetUserInfo();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useCreateVolunteerExperience();
  const { data: sectorData, isLoading: loadingSector } = useGetSectors();
  const { data, isSuccess } = useGetSingleVolunteer({
    id: id as number,
    token,
  });

  useEffect(() => {
    form.setFieldsValue({
      organization: data?.company,
      is_current: data?.is_current,
      description: data?.description,
      sector_id: data?.sector_id,
      work_type: data?.type,
      title: data?.title,
      date: data?.end_date ? [dayjs(data.start_date), dayjs(data.end_date)] : data?.start_date ? dayjs(data.start_date) : undefined,
      ss: data?.start_date ? dayjs(data.start_date) : undefined, 
    });
  }, [form, data]);
  
  const disableFutureDates = (current: dayjs.Dayjs) => {
    return current && current.isAfter(dayjs().endOf('day'));
  };

  const handleSubmit = (data: any) => {
    const startDate = data.date ? data.date[0].format("YYYY-MM-DD") : null;
    const endDate = data.date ? data.date[1].format("YYYY-MM-DD") : null;
    const onStartDate = data.ss ? data.ss.format("YYYY-MM-DD") : null;

    const props: ICreateWorkHistory = {
      id:  id as number,
      talent_id: userInfo?.talent?.id,
      title: data.title,
      sector_id: data.sector_id,
      company: data.organization,
      work_type: data.work_type,
      start_date: currentWork ? onStartDate : startDate,
      end_date: endDate,
      is_current: data.is_current,
      description: data.description,
      token,
    };
    mutate(props, {
      onError: (err: any) => {
        let errorTitle = "Error";
        let errorDescription = "An error occurred, please try again.";

        if (err.response && err.response.data &&  err.response.data.error) {
          errorTitle = "Error: " + err.response.data.error;
          errorDescription = err.response.data.description || "Please try again.";
        }

        openNotification({
          title: errorTitle,
          state: "error",
          description: errorDescription,
          duration: 6,
        });
      },
      onSuccess: (res: any) => {
        openNotification({
          title: "Success",
          state: "success",
          description: "Volunteer Experience Created Successfully",
          duration: 4.5,
        });
        form.resetFields();
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_FOR_TALENT_PROFILE],
        });
        queryClient.invalidateQueries({queryKey: [QUERY_KEY_FOR_SINGLE_VOLUNTEER, id]});
        handleClose();
      },
    });
  };

  return (
    <Modal
      title={id ? "Edit Volunteer Experience" :"Add Volunteer Experience"}
      open={open}
      onCancel={() => handleClose(false)}
      footer={null}
      width="45rem"
      style={{ top: 10 }}
    >
      <div>
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={handleSubmit}
          form={form}
          initialValues={{ is_current: false }}
        >
          <div className="flex items-center gap-2 mb-4">
            <Form.Item noStyle name="is_current">
              <Switch size="small" onChange={(val) => setCurrentWork(val)} />
            </Form.Item>
            <span>I currently work here</span>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5">
            <Form.Item
              name="organization"
              label="Organization"
              rules={generalValidationRules}
            >
              <Input placeholder="Enter Organization" />
            </Form.Item>
            <Form.Item
              name="title"
              label="Job Title"
              rules={generalValidationRules}
            >
              <Input placeholder="Enter Job Title" />
            </Form.Item>
            <Form.Item name="sector_id" label="Sector">
              <Select
                className="SelectTag w-full"
                options={sectorData?.map((sector) => ({
                  value: sector.id,
                  label: sector.name,
                }))}
                placeholder="Select"
              ></Select>
            </Form.Item>

            {!currentWork ? (
              <Form.Item name="date" label="Start & End Date">
                <DatePicker.RangePicker
                  className="w-full"
                  format="YYYY-MM-DD"
                  placeholder={["Start date", "End date"]}
                  disabledDate={disableFutureDates}
                />
              </Form.Item>
            ) : (
              <Form.Item name="ss" label="Start Date">
                <DatePicker className="w-full" format="YYYY-MM-DD" disabledDate={disableFutureDates} />
              </Form.Item>
            )}

            <Form.Item
              className="col-span-2"
              name="description"
              label="Description"
            >
              <TextArea />
            </Form.Item>
          </div>
          <div className="flex">
            <AppButton type="submit" isLoading={isLoading} />
          </div>
        </Form>
      </div>
    </Modal>
  );
};
