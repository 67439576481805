import axios from "axios";
import { useQuery } from "react-query";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { END_POINT } from "config/environment";
import { TGateways } from "admin/finance/types";

export const QUERY_KEY_FOR_SUBSRIPTION = "subscription";

const getData = async (props: {
  token: string;
  userInfo: any;
}): Promise<any> => {
    const url = `${END_POINT.BASE_URL}/v1/subscription`;
    const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  const res = await axios.get(url, config);
  console.log(res);
  const item = res;
  return item;
};

export const useGetSubscription = () => {
  const { token, userInfo } = useGetUserInfo();
  const queryData = useQuery(
    [QUERY_KEY_FOR_SUBSRIPTION],
    () => getData({
        token,
        userInfo
    }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
    }
  );
  return queryData;
};
