import axios from "axios";
import { useQuery } from "react-query";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { ITalentInvites } from "../types";
import { END_POINT } from "config/environment";

export const QUERY_KEY_FOR_TALENT_INVITE = "talent-job-invites";

const getData = async (props: { token: string }): Promise<ITalentInvites[]> => {
  const url = `${END_POINT.BASE_URL}/v1/talent-job-invites`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  const res = await axios.get(url, config);

  const data: ITalentInvites[] = res.data.data.map((item: ITalentInvites) => ({
    ...item,
  }));

  return data;
};

export const useFetchTalentInvites = () => {
  const { token } = useGetUserInfo();
  const queryData = useQuery(
    [QUERY_KEY_FOR_TALENT_INVITE],
    () => getData({ token }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
    }
  );

  return queryData;
};