import authImage from "../assets/images/authSvg.svg";
import logo from "assets/images/logoSvg.svg";
import { adminRoutePath } from "config/routeMgt/adminRoutePath";
import { Link } from "react-router-dom";
import "../assets/style.css";
import { ForgotPasswordForm } from "../components/ForgotPasswordForm";

export const AdminForgotPassword = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 h-screen overflow-auto">
      <div className="Container py-3 bg-white">
        <div className="mt-2">
          <img src={logo} alt="logo" />
        </div>
        <div className="flex justify-center mt-10">
          <div className="authFormWrap">
            <div className="mb-6">
              <h2 className="text-2xl font-bold pb-1 text-primary">
                Forgot password
              </h2>
              <p className="text-accent text-sm">
                Fill in your email and we will send you a code to reset your
                password.
              </p>
            </div>
            <ForgotPasswordForm />

            <div className="flex justify-center items-center gap-x-2 text-sm mt-6">
              <span className="text-accent">Want to go back?</span>
              <Link
                to={adminRoutePath.sign_in}
                className="text-primary font-medium hover:text-accent"
              >
                Sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-primary h-screen Container lg:flex items-center justify-center hidden w-full sticky top-0">
        <img src={authImage} alt="auth background" className="max-h-96" />
      </div>
    </div>
  );
};
