import React, { useEffect, useRef, useState } from 'react';
import { Navbar } from 'components/layout/components/Navbar';
import { DashboardLayout } from 'components/layout/components/DashboardLayout';
import avatar from "assets/images/default.webp";
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { END_POINT } from 'config/environment';
import { useGetUserInfo } from 'hooks/useGetUserInfo';
import axios from 'axios';
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form, Input } from 'antd';

export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const Messages: React.FC = () => {
  const [chatlists, setChatlists] = React.useState<any>([]);
  const [messages, setMessages] = React.useState<any>([]);
  const [text, setText] = React.useState('');
  const { token, userInfo } = useGetUserInfo();
  const [renderCount, setRenderCount] = useState(0);
  const [to_user_id, setToUser] = useState<any>('');
  const location = useLocation();
  const chat_id = new URLSearchParams(location.search).get('id');
  const navigate = useNavigate();
  const messagesEndRef = useRef<null | HTMLDivElement>(null)
  const [socketConnected, setSocketConnected] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  const { TextArea } = Input;

  
  window.Pusher = Pusher;
  
  const echo = new Echo({
    broadcaster: 'reverb',
    wsHost: '0.0.0.0',
    key: "xcojhz5uzpwxskt4utcq",
    wsPort: 8080,
    wssPort: 443,
    forceTLS: false,
    enabledTransports: ['ws'],
    authorizer: (channel: { name: any; }, options: any) => {
      return {
          authorize: (socketId: any, callback: (arg0: boolean, arg1: any) => void) => {
              axios.post(END_POINT.AUTH_URL+'/broadcasting/auth', {
                  socket_id: socketId,
                  channel_name: channel.name
              },{headers:{"Authorization": `Bearer ${token}`}})
              .then((response: { data: any; }) => {
                  callback(false, response.data);
              })
              .catch((error: any) => {
                  callback(true, error);
              });
          }
      };
  },
});
const channel = `chat.${chat_id}`;

const connectToChannel = ()=>{
    echo.private(channel)
      .listen('MessageSent', async(e:any) => {
      console.log(e.message);
      setMessages((prevMessages: any) => [...prevMessages, e.message]);
    });
}



useEffect(() => {
  setRenderCount(prevCount => prevCount + 1);
},[]);


useEffect(() => {
  if (renderCount === 2) {
      const handleChatListWrapper = async () => {
          await getChatList();
        };
        handleChatListWrapper();
  }
}, [renderCount]);


useEffect(() => {
  if (renderCount === 2) {
      const handleConnectChannelWrapper = async () => {
          await connectToChannel();
          setSocketConnected(true);
        };
        handleConnectChannelWrapper();
  }
  return () => {
    echo.leave(channel);
  };
}, [renderCount, chat_id]);

useEffect(() => {
  if (renderCount === 2) {
      const handleGetMessageWrapper = async () => {
          await getMessages();
        };
        handleGetMessageWrapper();
  }
}, [renderCount, chat_id]);


  const getMessages = () => {
    fetch(`${END_POINT.BASE_URL}/v1/message/${chat_id}`, {
      headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
        if (response.ok) {
            return response.json();
        }
        throw new Error('Something went wrong!');
    })
    .then((data) => {
      setMessages(data)
    })
    .catch((error) => console.error(error));
  };

  const postData = () => {
    const requestData = {
      to_user_id: to_user_id.id,
      text: text
    };
  
    fetch(`${END_POINT.BASE_URL}/v1/sendmessage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestData)
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong!');
    })
    .then((data) => {
      setText('');
    })
    .catch((error) => console.error(error));
  };

  const getChatList = () => {
    fetch(`${END_POINT.BASE_URL}/v1/chatlists`, {
      headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong!');
    })
    .then((data) => {
      setChatlists(data);
      if(chat_id){
        const currentChat = data.filter((item:any) => item.id == chat_id);
        setToUser(currentChat[0].participants);
    }
    })
    .catch((error) => console.error(error));
  };

  const handleClick = (contact: any) => {
    setToUser(contact.participants);
    navigate(`/message?id=${contact.id}`);
  };


  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messages]);


  const groupMessagesByDate = (messagesData:any) => {
    const groupedMessages : any = {};
    messagesData.forEach((message:any) => {
      const date = dayjs(message.created_at).format('YYYY-MM-DD'); // Format date as YYYY-MM-DD
      if (!groupedMessages[date]) {
        groupedMessages[date] = [];
      }
      groupedMessages[date].push(message);
    });
    return groupedMessages;
  };

  const groupedMessages = groupMessagesByDate(messages);

  const formatDate = (date:any) => {
    const today = dayjs().startOf('day');
    const messageDate = dayjs(date);
    const diffDays = today.diff(messageDate, 'day');

    if (diffDays === 0) {
      return 'Today';
    } else if (diffDays === 1) {
      return 'Yesterday';
    } else if (diffDays < 7) {
      return DAYS[messageDate.day()];
    } else {
      return messageDate.format('ddd, MMM YYYY');
    }
  };

  const handleChange = (e:any) => {
    setText(e.target.value);
    const rowsNeeded = Math.ceil(e.target.scrollHeight / 20);
    e.target.rows = rowsNeeded;
  };

  return (
    <DashboardLayout>
      <div className='flex-grow h-screen -mb-10 overflow-hidden'>
        <Navbar title="Message" description="" />
        <div className="flex border-t border-gray-200 w-full">
          <div className="flex flex-col w-1/4 ">
            <div className='px-3 py-8'>
              <div className="flex items-center border border-gray-200  pl-2 rounded-lg">
                <i className="ri-search-line text-md text-gray-400"></i>
                <input type="text" placeholder="Search messages " className="w-full p-2 h-12 rounded-md focus:outline-none focus:border-blue-500" />
              </div>
            </div>
            <div className="overflow-y-auto overflow-x-hidden max-h-screen pb-60">
              {chatlists.map((contact: any, index: any) => (
              <div  key={index}>
                <div className={`flex items-center cursor-pointer hover:bg-gray-200 p-2 mr-2 rounded-md ${chat_id==contact.id && 'bg-gray-200'}`} onClick={()=>handleClick(contact)}>
                  <div className="w-8 h-8 rounded-full mr-3">
                    <img src={contact.participants.photo ? contact.participants.photo: avatar} alt="User Avatar" className="w-8 h-8 rounded-full" />
                  </div>
                  <div className="flex-1">
                    <h2 className="text-xs">{contact.participants.name}</h2>
                  </div>
                </div>
              </div>
                ))}
            </div>
          </div>
            
          <div className="flex-1 border-l border-gray-200">
            <header className="flex items-center justify-start gap-3 p-4 text-gray-700 border-b border-gray-200 sticky top-0 bg-white z-10">
              <div className="flex items-center">
                <img src={to_user_id.photo ? to_user_id.photo: avatar} alt="User Avatar" className="w-10 h-10 rounded-full" />
              </div>
                <h1 className="text-sm font-semibold">{to_user_id.name}</h1>
            </header>
            
            <div className="max-h-screen overflow-y-auto p-4 pb-60" ref={messagesEndRef}>
              {Object.keys(groupedMessages).map((date) => (
              <div key={date}>
                <div className='flex items-center text-center justify-center'>
                  <div className="text-center text-xs mt-4 mb-1 p-1 px-2 rounded-sm text-gray-500 bg-gray-100 w-auto">
                    {formatDate(date)}
                  </div>
                </div>
                {groupedMessages[date].map((message:any, index:any) => (
                  <div key={index}>
                    {message.user.id !== userInfo.id ? (
                      // Incoming message
                    <div className="flex mb-2 justify-start">
                      <div className="w-9 h-9 rounded-full flex items-center justify-center, mr-1">
                        <img src={to_user_id.photo ? to_user_id.photo : avatar} alt="Avatar" className="w-8 h-8 rounded-full" />
                      </div>
                      <div className='flex max-w-[80%] rounded-lg p-2 bg-gray-200'>
                        <span className='text-sm justify-start items-start'>
                          {message.text}
                          <span className='flex justify-end items-end'>
                            <span className='font-extralight text-[10px] pl-1'>{dayjs(message.created_at).format('h:mm A')}&nbsp;</span>
                          </span>
                        </span>
                      </div>
                    </div>
                    ) : (
                    <div className='flex justify-end mb-2'>
                      <div className='flex max-w-[85%] rounded-xl p-2 bg-[#b19dfc]'>
                        <span className='text-sm justify-start items-start'>
                          {message.text}
                          &nbsp;<span className='flex justify-end items-end'>
                            <span className='font-extralight text-[10px] inline-block'>{dayjs(message.created_at).format('h:mm A')}&nbsp;</span>
                            <i className="ri-check-fill text-blue-700 text-md inline-block"></i>
                          </span>
                        </span>
                      </div>
                    </div>
                    )}
                  </div>
                ))}
              </div>
              ))}
            </div>
            
            <footer className="bg-white pb-4 px-2 w-full sticky bottom-0 z-10">
              <Form onFinish={()=>postData}>
                <Input onChange={(e)=>setText(e.target.value)} value={text} placeholder="Type a message..." className="w-full p-2 h-12 rounded-md focus:outline-none focus:border-primary" suffix={<button type='submit' className="bg-primary h-10 w-12 rounded-lg" onClick={()=>postData()}>
                  <i className="ri-send-plane-fill text-xl text-white"></i>
                </button>} />
              </Form>
            </footer>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Messages;