import { Input, Form } from "antd";
import { AppButton } from "components/button/AppButton";
import { emailValidationRules } from "utils/formHelpers/validations";
import { useState } from "react";
import { END_POINT } from "config/environment";
import { openNotification } from "utils/notification";
import { useNavigate } from 'react-router-dom';

export const ForgotPasswordForm = () => {

  const [loading, setLoading] = useState(false);
  const [email, setEmail]=useState('');
  const navigate = useNavigate();

  const submit = () => {
    setLoading(true);
    fetch(`${END_POINT.AUTH_URL}/administrator/forgot-password`, {
      method: 'POST',
      headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      },
      body: JSON.stringify({email:email})
    })
    .then((response) => {
        if (response.ok) {
            return response.json();
        }
        setLoading(false);
        openNotification({
          title: 'Error',
          state: 'error',
          description: 'Something went wrong',
          duration: 4.5,
        });
        throw new Error('Something went wrong!');
    })
    .then((data) => {
      openNotification({
        title: 'Success',
        state: 'success',
        description: 'Password reset link sent you email',
        duration: 4.5,
      });
      navigate('/');
    })
    .catch((error) => console.error(error));
  };

  return (
    <>
      <Form layout="vertical" requiredMark={false} onFinish={submit}>
        <Form.Item name="email" label="Email" rules={emailValidationRules}>
          <Input onChange={(e)=>setEmail(e.target.value)} placeholder="Enter your email" className="py-2 shadow-sm" />
        </Form.Item>

        <AppButton
          label="Submit"
          type="submit"
          additionalClassNames={["button w-full py-1"]}
          isLoading={loading}
        />
      </Form>
    </>
  );
};
