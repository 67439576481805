import axios from "axios";
import { useQuery } from "react-query";
import { END_POINT } from "config/environment";
import { IGeneralProps } from "types";
import { ISingleFolderProps } from "../types";

export const QUERY_KEY_FOR_SINGLE_FOLDER = "single-folder";

const getSingleFolder = async (
  props: IGeneralProps
): Promise<ISingleFolderProps> => {
  const id = props.id;
  const url = `${END_POINT.BASE_URL}/v1/folders/${id}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  const res = await axios.get(url, config);
  const item = res.data;
  const data: ISingleFolderProps = { ...item };

  return data;
};

export const useGetSingleFolder = ({ id, token }: IGeneralProps) => {
  const queryData = useQuery([QUERY_KEY_FOR_SINGLE_FOLDER, id], () =>
    getSingleFolder({
      id,
      token,
    })
  );

  return queryData;
};
