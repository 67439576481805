import { Dropdown, Menu, Popconfirm } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { QUERY_KEY_FOR_TEAM_MEMBERS, useGetMembers } from "../hooks/useGetMembers";
import { ITeamMembers, TUpdatePackagePlan } from "../types";
import { useUpdatePackagePlan } from "../hooks/useUpdatePackagePlan";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { openNotification } from "utils/notification";
import { useQueryClient } from "react-query";
import { AttachUserPlan } from "./AttachUserPlan";
import { useState } from "react";

export const MemberTable = () => {
  const { token } = useGetUserInfo();
  const queryClient = useQueryClient();
  const { data, isLoading } = useGetMembers();
  const { mutate, isLoading:loading } = useUpdatePackagePlan();
  const [attach, setAttach] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  
  const detachPlan = (values: TUpdatePackagePlan) => {
    mutate(
      {
        id: values.id,
        user_id: values.user_id,
        token: token,
        operation: "remove-package-plan"
      },
      {
        onError: (err: any) => {
          openNotification({
            title: "Error",
            state: "error",
            description: "Error occurred try again",
            duration: 4.5,
          });
        },
        onSuccess: (res: any) => {
          openNotification({
            title: "Success",
            state: "success",
            description: "",
            duration: 4.5,
          });
          queryClient.invalidateQueries([QUERY_KEY_FOR_TEAM_MEMBERS]);
        },
      }
    );
  };

  const columns: ColumnsType<ITeamMembers> = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, val) => <span>{val?.user?.name}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (_, val) => <span>{val?.user?.email}</span>,
    },
    {
      title: "Plan",
      dataIndex: "plan",
      render: (_, val) => <span>{val?.user?.corporate_subscription ? val?.user?.corporate_subscription?.package.name : 'none'}</span>,
    },
  
    {
      title: "status",
      dataIndex: "invite_status",
      render: (_, val) => (
        <div className={`inline-flex items-center justify-center gap-2 rounded-xl px-2 text-sm pb-1${val?.invite_status === "pending" ? "bg-yellow-100 text-yellow-600" : val?.invite_status === "active" ? "bg-green-100 text-green-700":'' }`}>
          <i
            className={`ri-checkbox-blank-circle-fill  ${val?.invite_status === "active" ? "text-green-700" : val?.invite_status === "pending" ? "text-yellow-600":''}`}
            style={{ fontSize: 10 }}
          ></i>

          <span className="text-sm pb-1">{val?.invite_status}</span>
        </div>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",

      render: (_, val) => (
        <div>
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                { val.user.corporate_subscription === null && <Menu.Item key="2" onClick={()=>{setSelectedUser(val.user_id); setAttach(true)}}>Asign Package Plan</Menu.Item>}
                { val.user.corporate_subscription && <Menu.Item key="3">
                    <Popconfirm
                  okButtonProps={{
                    style: { backgroundColor: '#f5222d', color: '#ffffff' }, // Custom styles
                  }} 
                      title="Remove plan from user"
                      description="Are you sure to Remove plan from user?"
                      onConfirm={()=>detachPlan({'user_id': val?.user_id, 'id':val.user.corporate_subscription.id})}
                    >
                      Remove Package Plan
                    </Popconfirm>
                  </Menu.Item>}
                <Menu.Item key="4">Disable</Menu.Item>
              </Menu>
            }
          >
            <i className="ri-more-2-fill text-lg cursor-pointer"></i>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (  
    <>
      <Table
        className="bg-white rounded-md shadow border mt-8"
        columns={columns}
        dataSource={data}
        scroll={{ x: 550 }}
        loading={isLoading}
      />
      <AttachUserPlan open={attach} handleClose={() => setAttach(false)} user_id={selectedUser} />
    </>
  );
};
