import axios from "axios";
import { useQuery } from "react-query";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { END_POINT } from "config/environment";
import { IFolderProps } from "../types";

export const QUERY_KEY_FOR_FOLDERS = "folders";

// TO DO : need to exist in the general data entities and refactored
interface IGetDataProps {
  pagination?: {
    pageSize?: number;
    current?: number;
  };
  token?: string;
  userInfo?: any;
  search?: string;
}

const getData = async (
  props: IGetDataProps
): Promise<{ data: IFolderProps[]; total: number }> => {
  const { pagination, search } = props;

  const url = `${END_POINT.BASE_URL}/v1/folders`;

  const corporate_id = props?.userInfo?.current_team ? props?.userInfo?.current_team.corporate_id : props?.userInfo?.recruiter.is_corporate == 1 ? props?.userInfo?.recruiter.id :'';

  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
    params: {
      q: search,
      page: pagination?.current,
      limit: pagination?.pageSize,
      corporate_id
    },
  };

  const res = await axios.get(url, config);
  const fetchedData = res.data.data;

  const data: IFolderProps[] = fetchedData.map(
    (item: IFolderProps): IFolderProps => ({ ...item })
  );
  
  const ans = {
    data,
    total: res.data.total,
  };
  return ans;
};

export const useGetFolders = ({ pagination, search }: IGetDataProps) => {
  const { userInfo, token } = useGetUserInfo();
  const queryData = useQuery(
    [QUERY_KEY_FOR_FOLDERS, pagination, search],
    () =>
      getData({
        pagination,
        token,
        userInfo,
        search,
      }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
    }
  );

  return queryData;
};
