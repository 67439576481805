import  { useEffect, useState } from "react";
import { END_POINT } from "config/environment";
import {useLocation} from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { useNavigate } from 'react-router-dom';
import { openNotification } from "utils/notification";
import axios from "axios";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useSignIn } from "react-auth-kit";

  
export const VerifyPayment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useGetUserInfo();
  const signIn = useSignIn();
  const [renderCount, setRenderCount] = useState(0);


  useEffect(() => {
    setRenderCount(prevCount => prevCount + 1);
  },[]);

  useEffect(() => {
    //if (renderCount === 2) {
        const handleAuthCallbackWrapper = async () => {
            await handleAuthCallback();
          };
          handleAuthCallbackWrapper();
    //}
  }, []);


  const handleAuthCallback = async () => {
    const queryParams = new URLSearchParams(location.search);
    const gateway = queryParams.get('gateway');
    if(gateway === 'paystack' || 'flutterwave'){
        try {
        const response = await axios.get(`${END_POINT.BASE_URL}/v1/${gateway}-verify${location.search}`, {
          headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
          }
        });
    
        const data = await response.data;
        if(data.user){
            openNotification({
                title: "Success",
                state: "success",
                description: "Payment successfull",
                duration: 4.5,
            });

        if(
          signIn({
            token: data.token,
            tokenType: "Bearer",
            authState: data,
            expiresIn: 500,
          })
        ){
          openNotification({
            title: "Success",
            state: "success",
            description: "Switched successfully",
            duration: 4.5,
          });
        }
            navigate('/');
        }

    
       
        } catch (error: any) {
    
        if (error?.response && error?.response?.status === 403) {
            openNotification({
                title: 'Error',
                state: 'error',
                description: error?.response.data.message,
                duration: 4.5,
            });
        } else {
            openNotification({
            title: 'Error',
            state: 'error',
            description: 'Something went wrong!',
            duration: 4.5,
            });
        }
        navigate('/');
        } finally {
        }
    }else{
      try {
        const response = await axios.get(`${END_POINT.BASE_URL}/v1/oauth/google-callback${location.search}`, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
    
        const data = await response.data;
          openNotification({
            title: "Success",
            state: "success",
            description: "Authorized successfully",
            duration: 4.5,
          });
        navigate('/');
      } catch (error: any) {
        openNotification({
          title: 'Error',
          state: 'error',
          description: 'Something went wrong!',
          duration: 4.5,
        });
        navigate('/');        
      } finally {
      }
    }
  };
  

  return (
    <div className="flex justify-center items-center h-screen">
        <ScaleLoader color="var(--app-color-primary)" />
      </div>
  );
};
