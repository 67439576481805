import axios from "axios";
import { useQuery } from "react-query";
import { END_POINT } from "config/environment";
import { IGeneralProps } from "types";
import { ITalentInvites } from "features/invites/types";

export const QUERY_KEY_FOR_TALENT_JOB_FOR_RECRUITER =
  "talent-job-invites-for-recruiter";

const getSingleFolder = async (
  props: IGeneralProps
): Promise<ITalentInvites[]> => {
  const id = props.id;
  const url = `${END_POINT.BASE_URL}/v1/talent-job-invites-for-recruiter/${id}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  const res = await axios.get(url, config);
  const data: ITalentInvites[] = res.data.data.map((item: ITalentInvites) => ({
    ...item,
  }));
  return data;
};

export const useGetRecruiterInvites = ({ id, token }: IGeneralProps) => {
  const queryData = useQuery([QUERY_KEY_FOR_TALENT_JOB_FOR_RECRUITER, id], () =>
    getSingleFolder({
      id,
      token,
    })
  );

  return queryData;
};
