import axios from "axios";
import { ICreateUserProps } from "../types";
import { END_POINT } from "config/environment";

export const createUser = async (props: ICreateUserProps) => {
  const url = `${END_POINT.AUTH_URL}/register`;
  const data = props;
  const response = await axios.post(url, data);
  return response;
};
