import axios from "axios";
import { useMutation } from "react-query";
import { END_POINT } from "config/environment";
import { IReviewProps } from "../types";

export const createReview = async (props: IReviewProps) => {

  const url = `${END_POINT.BASE_URL}/v1/invites/send-feedback`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  // necessary to make immediate changes when in  a central place when schema changes
  const data: any = {
    listing_invite_id: props.listing_invite_id,
    message: props.message,
    rate: props.rate
  };

  const response = await axios.post(url, data, config);
  return response;
};

export const useSendReview = () => {
  return useMutation(createReview);
};
