import axios from "axios";
import { useQuery } from "react-query";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { END_POINT } from "config/environment";
import { TTeams } from "types";

export const QUERY_KEY_FOR_TEAMS = "teams";

const getData = async (props: {
  token: string;
  userInfo: any;
}): Promise<TTeams[]> => {
  const corporate_id = `${props.userInfo?.current_team ? '?corporate_id='+props.userInfo?.current_team.corporate_id : ''}`
  const url = `${END_POINT.BASE_URL}/v1/get-teams${corporate_id}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  const res = await axios.get(url, config);
  const item: TTeams[] = res.data;
  return item;
};

export const useGetTeams = () => {
  const { token, userInfo } = useGetUserInfo();
  const queryData = useQuery(
    [QUERY_KEY_FOR_TEAMS],
    () => getData({
        token,
        userInfo
    }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
    }
  );
  return queryData;
};
