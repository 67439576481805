
export function resizeImageToSquare(imageFile: File, squareSize: number): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;
        const squareDimension = Math.min(img.width, img.height);
        canvas.width = squareSize;
        canvas.height = squareSize;
        ctx.drawImage(img, (img.width - squareDimension) / 2, (img.height - squareDimension) / 2, squareDimension, squareDimension, 0, 0, squareSize, squareSize);
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Failed to resize image.'));
          }
        }, 'image/jpeg');
      };
      img.onerror = () => {
        reject(new Error('Failed to load image.'));
      };
      img.src = event.target!.result as string;
    };
    reader.onerror = () => {
      reject(new Error('Failed to read image file.'));
    };
    reader.readAsDataURL(imageFile);
  });
}




export function resizeCoverPhoto(imageFile: File): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;
        const canvasWidth = 1060;
        const canvasHeight = 240;
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        const imgAspectRatio = img.width / img.height;
        const canvasAspectRatio = canvasWidth / canvasHeight;

        let drawWidth, drawHeight, offsetX, offsetY;

        if (imgAspectRatio > canvasAspectRatio) {
          drawHeight = canvasHeight;
          drawWidth = img.width * (canvasHeight / img.height);
          offsetX = (canvasWidth - drawWidth) / 2;
          offsetY = 0;
        } else {
          drawWidth = canvasWidth;
          drawHeight = img.height * (canvasWidth / img.width);
          offsetX = 0;
          offsetY = (canvasHeight - drawHeight) / 2;
        }

        ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);

        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Failed to resize image.'));
          }
        }, 'image/jpeg');
      };
      img.onerror = () => {
        reject(new Error('Failed to load image.'));
      };
      img.src = event.target!.result as string;
    };
    reader.onerror = () => {
      reject(new Error('Failed to read image file.'));
    };
    reader.readAsDataURL(imageFile);
  });
}
