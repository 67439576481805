import { DatePicker, Form, Input, Modal, Select, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import { AppButton } from "components/button/AppButton";
import dayjs from "dayjs";
import { useCreateEducationHistory } from "features/settings/hooks/useCreateEducationHistory";
import { QUERY_KEY_FOR_SINGLE_EDUCATION_HISTORY, useGetSingleEducation } from "features/settings/hooks/useGetSingleEducation";
import { QUERY_KEY_FOR_TALENT_PROFILE } from "features/settings/hooks/useGetTalentProfile";
import { ICreateEducationHistory } from "features/settings/types";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { IdentifierProps } from "types";
import {
  generalValidationRules,
  textInputValidationRules,
} from "utils/formHelpers/validations";
import { openNotification } from "utils/notification";

export const AddEducationHistory = ({
  open,
  handleClose,
  id,
}: IdentifierProps) => {
  const [currentSchool, setCurrentSchool] = useState(false);
  const [form] = Form.useForm();
  const { token, userInfo } = useGetUserInfo();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useCreateEducationHistory();
  const { data, isSuccess } = useGetSingleEducation({
    id: id as number,
    token,
  });

  const disableFutureDates = (current: dayjs.Dayjs) => {
    return current && current.isAfter(dayjs().endOf('day'));
  };
  
  useEffect(() => {
    if (isSuccess && id) {
      form.setFieldsValue({});
    } else {
      form.resetFields();
    }
  }, [isSuccess, id, data, form]);

  useEffect(() => {
    form.setFieldsValue({
      institution: data?.school,
      is_current: data?.is_current,
      description: data?.description,
      course: data?.course,
      degree_id: data?.degree_id,
      grade: data?.grade,
      date: data?.end_date ? [dayjs(data.start_date), dayjs(data.end_date)] : data?.start_date ? dayjs(data.start_date) : undefined,
      ss: data?.start_date ? dayjs(data.start_date) : undefined, 
    });
  }, [form, data]);

  const handleSubmit = (data: any) => {
    const startDate = data.date ? data.date[0].format("YYYY-MM-DD") : null;
    const endDate = data.date ? data.date[1].format("YYYY-MM-DD") : null;
    const onStartDate = data.ss ? data.ss.format("YYYY-MM-DD") : null;

    const props: ICreateEducationHistory = {
      id:  id as number,
      talent_id: userInfo?.talent?.id,
      school: data.institution,
      start_date: currentSchool ? onStartDate : startDate,
      end_date: endDate,
      is_current: data.is_current,
      description: data.description,
      token,
      course: data.course,
      degree_id: data.degree_id,
      grade: data.grade,
    };
    mutate(props, {
      onError: (err: any) => {
        let errorTitle = "Error";
        let errorDescription = "An error occurred, please try again.";

        if (err.response && err.response.data &&  err.response.data.error) {
          errorTitle = "Error: " + err.response.data.error;
          errorDescription = err.response.data.description || "Please try again.";
        }

        openNotification({
          title: errorTitle,
          state: "error",
          description: errorDescription,
          duration: 6,
        });
      },
      onSuccess: (res: any) => {
        openNotification({
          title: "Success",
          state: "success",
          description: "Education History Created Successfully",
          duration: 4.5,
        });
        form.resetFields();
        queryClient.invalidateQueries({queryKey: [QUERY_KEY_FOR_TALENT_PROFILE]});
        queryClient.invalidateQueries({queryKey: [QUERY_KEY_FOR_SINGLE_EDUCATION_HISTORY, id]});
        handleClose();
      },
    });
  };

  return (
    <Modal
      title={id ? "Edit Education History" :"Add Education History"}
      open={open}
      onCancel={() => handleClose(false)}
      footer={null}
      width="40rem"
      style={{ top: 10 }}
    >
      <div>
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={handleSubmit}
          form={form}
          initialValues={{ is_current: false }}
        >
          <div className="flex items-center gap-2 mb-4 mt-5">
            <Form.Item noStyle name="is_current">
              <Switch size="small" onChange={(val) => setCurrentSchool(val)} />
            </Form.Item>
            <span>I currently school here</span>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5">
            <Form.Item
              name="institution"
              label="Institution"
              rules={generalValidationRules}
            >
              <Input placeholder="Enter Institution" />
            </Form.Item>
            <Form.Item
              name="course"
              label="Course"
              rules={generalValidationRules}
            >
              <Input placeholder="Enter course" />
            </Form.Item>

            <Form.Item
              name="degree_id"
              label="Degree"
              rules={generalValidationRules}
            >
              <Select allowClear placeholder="Select" options={[
                { value: 1, label: 'Bachelor' },
                { value: 2, label: 'Master' },
                { value: 3, label: 'PhD' },
              ]} />
            </Form.Item>

            <Form.Item
              name="grade"
              label="Grade"
              rules={generalValidationRules}
            >
              <Input placeholder="Enter Grade" />
            </Form.Item>

            {!currentSchool ? (
              <Form.Item name="date" label="Start & End Date">
                <DatePicker.RangePicker
                  className="w-full"
                  format="YYYY-MM-DD"
                  placeholder={["Start date", "End date"]}
                  disabledDate={disableFutureDates} 
                />
              </Form.Item>
            ) : (
              <Form.Item name="ss" label="Start Date">
                <DatePicker className="w-full" format="YYYY-MM-DD" disabledDate={disableFutureDates}  />
              </Form.Item>
            )}

            <Form.Item
              className="col-span-2"
              name="description"
              label="Description"
            >
              <TextArea />
            </Form.Item>
          </div>
          <div>
            <AppButton type="submit" isLoading={isLoading} />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

