import axios from "axios";
import { TEducationHistory } from "../types";
import { useQuery } from "react-query";
import { END_POINT } from "config/environment";
import { IGeneralProps } from "types";

export const QUERY_KEY_FOR_SINGLE_EDUCATION_HISTORY = "get-education-history";

export const getSingleEducation = async (
  props: IGeneralProps
): Promise<TEducationHistory> => {
  const id = props.id;
  const url = `${END_POINT.BASE_URL}/v1/get-education-history/${id}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  const res = await axios.get(url, config);


  const item = res.data;

  const data: TEducationHistory = {
    id: item.id,
    talent_id: item.talent_id,
    course: item.course,
    school: item.school,
    degree_id: item.degree_id,
    grade: item.grade,
    start_date: item.start_date,
    end_date: item.end_date,
    is_current: item.is_current,
    description: item.description,
  };
  return data;
};

export const useGetSingleEducation = ({ id, token }: IGeneralProps) => {
  const queryData = useQuery([QUERY_KEY_FOR_SINGLE_EDUCATION_HISTORY, id], () =>
    getSingleEducation({
      id,
      token,
    })
  );

  return queryData;
};
