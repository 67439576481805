import { Form, Input, Modal, Select, Radio, RadioChangeEvent } from "antd";
import { AppButton } from "components/button/AppButton";
import { IdentifierProps } from "types";
import {
    emailValidationRules,
  generalValidationRules,
  textInputValidationRules,
} from "utils/formHelpers/validations";
import { useGetRoles } from "../hooks/useGetRoles";
import { openNotification } from "utils/notification";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useEditUser } from "../hooks/useEditUser";
import { IUsers } from "../types";
import { QUERY_KEY_FOR_USERS } from "../hooks/useFetchUsers";
import { useQueryClient } from "react-query";
import { useEffect, useState } from "react";

export const EditUser = ({ open, handleClose, data: propData }: IdentifierProps) => {
    const { data, isLoading: rolesDataLoading } = useGetRoles({});
    const [form] = Form.useForm();
    const { token } = useGetUserInfo();
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useEditUser();

    useEffect(() => {
        if (propData) {
        form.setFieldsValue({
            name: propData.name,
            email: propData.email,
            banned: propData?.banned,
            role: propData.roles[0].name
        });
        } else {
        form.resetFields();
        }
    }, [propData, form]);
    const handleFormSubmit = (values: any) => {
        const props: IUsers = {
        token,
        user_id: propData.id as unknown as string,
        name: values.name,
        email: values.email,
        password: values.password,
        roles: values.role,
        banned: values.banned
        };
        mutate(props, {
        onError: () => {
            openNotification({
            title: "Error",
            state: "error",
            description: "Error occurred try again",
            duration: 6.0,
            });
        },
        onSuccess: () => {
            openNotification({
            title: "Success",
            state: "success",
            description: "Added Successfully",
            duration: 4.5,
            });
            form.resetFields();
            queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_FOR_USERS],
            });
            handleClose();
        },
        });
    };


    return (
        <Modal
        style={{ top: 20 }}
        footer={false}
        open={open}
        onCancel={() => handleClose()}
        title="Edit User"
        >
        <Form layout="vertical" requiredMark="optional" className="mt-5"
            onFinish={handleFormSubmit}
            form={form}
        >
        
            <Form.Item
                name="name"
                label="Full Name"
                rules={textInputValidationRules}
            >
                <Input placeholder="Please enter full name" />
            </Form.Item>

            <Form.Item
                name="email"
                label="Email"
                rules={emailValidationRules}
            >
                <Input placeholder="Please enter user's email address" />
            </Form.Item>
            <Form.Item
                name="password"
                label="Password"
                className="-mt-3"
            >
                <Input.Password
                    placeholder="***********"
                    className="shadow-sm py-2"
                />
            </Form.Item>

            <Form.Item
                name="banned"
                label="Status"
                className="-mt-3"
            >
                <Radio.Group>
                    <Radio value={0}>Active</Radio>
                    <Radio value={1}>Banned</Radio>
                </Radio.Group>

            </Form.Item>
            
            <Form.Item
            name="role"
            label="Select role"
            rules={generalValidationRules}
            >
            <Select
                loading={rolesDataLoading}
                placeholder="Select"
                allowClear
                options={data?.data.map((item:any) => ({
                value: item.name,
                label: item.name,
                }))}
            />
            </Form.Item>
            <AppButton label="Submit" type="submit" isLoading={isLoading}  />
        </Form>
        </Modal>
    );
};