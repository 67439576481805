import React, { useState } from "react";
import "../assets/style.css";
import checkboxBase from "../assets/images/CheckboxBase.svg";
import featureIcon from "../assets/images/FeaturedIcon.svg";
import { AppButton } from "components/button/AppButton";
import { routePath } from "config/routeMgt/routePath";
import { Link } from "react-router-dom";

interface RadioFormProps {
  options: { label: string; value: string; showCase: string }[];
  selectedOptionProp: string;
  setStepProp: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedOptionProp: React.Dispatch<React.SetStateAction<string>>;
}

export const radioFormOptions = [
  { label: "Individual Recruiters", value: "recruiter", showCase: "Recruiter" },
  {
    label: "individuals, Freelancer",
    value: "talent",
    showCase: "Talent",
  },
  {
    label: "Companies and groups",
    value: "is_corporate",
    showCase: "Enterprise",
  },
];

export const RadioForm: React.FC<RadioFormProps> = ({
  options,
  selectedOptionProp,
  setSelectedOptionProp,
  setStepProp,
}) => {
  const [error, setError] = useState<string>("");
  const [termd_and_condition, setTermd_and_condition] = useState<any>();
  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOptionProp(event.target.value);
    setError("");
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (!selectedOptionProp) {
      setError("Please select an option");
    } else if(!termd_and_condition){
      setError("Please accept the terms and privacy policy");
    }
     else {
      // Handle form submission
      setStepProp(true);
    }
  };

  return (
    <div>
      <div className="mb-6">
        <h2 className="text-2xl font-bold pb-1 text-primary">Sign up</h2>
        <p className="text-accent text-sm">How do you intend to use Arno?</p>
      </div>
      <form onSubmit={handleSubmit}>
        {options.map((option) => (
          <label
            key={option.value}
            htmlFor={option.value}
            className="relative flex flex-col bg-white p-4 rounded-lg shadow-sm cursor-pointer border my-3"
          >
            <div className="flex justify-between">
              <div className="flex items-center gap-x-3">
                <img src={featureIcon} alt="featured icon" />
                <div className="text-sm">
                  <span className="">{option.showCase}</span>
                  <p>{option.label}</p>
                </div>
              </div>
              <img src={checkboxBase} alt="checkbox" className="h-4 mt-1" />
            </div>
            <input
              type="radio"
              name="plan"
              id={option.value}
              value={option.value}
              className="absolute h-0 w-0 appearance-none"
              onChange={handleOptionChange}
            />
            <span
              style={{ background: "#F4F5FF", border: "1px solid #B3B7FF" }}
              aria-hidden="true"
              className="hidden absolute inset-0 border-2 pl-2 bg-opacity-1 rounded-lg"
            >
              <div className="flex items-center gap-x-3 mt-3">
                <img src={featureIcon} alt="featured icon" className="h-14" />
                <div className="text-sm">
                  <span className="">{option.showCase}</span>
                  <p>{option.label}</p>
                </div>
              </div>
              <span className="absolute top-4 right-4 h-4 w-4 mt-2 inline-flex items-center justify-center rounded-full bg-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5 text-white"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </span>
          </label>
        ))}
        <div className="flex gap-4 py-4">
          <input type="checkbox"  required name="terms" onChange={(e)=>setTermd_and_condition(e.target.value)} className="w-5 h-5 focus:ring-primary" />
          <p className="text-sm">I agree Arno's <a href="https://www.arnopro.com/arno-terms-of-use" className="underline"  target='_blank' rel="noreferrer" aria-label='Terms and Conditions'>Terms & Conditions</a> and <a href="https://www.arnopro.com/arno-data-privacy-policy" className="underline" target="_blank" rel="noreferrer" aria-label="Privacy Policy">Privacy Policy</a></p>
        </div>
        <AppButton
          label="Continue"
          type="submit"
          additionalClassNames={["button w-full py-1"]}
        />
        {error && (
          <p className="text-sm pt-2 text-red-500 font-medium">{error}</p>
        )}
      </form>
      <div className="flex justify-center items-center gap-x-2 text-sm mt-6">
        <span className="text-accent">Already have an account?</span>
        <Link
          to={routePath.sign_in}
          className="text-primary font-medium hover:text-accent"
        >
          Sign in
        </Link>
      </div>

      <div className="mb-5 mt-7 flex justify-around items-center">
        <div className="flex items-center gap-x-1">
          <div style={{ height: 5 }} className="bg-primary w-8 rounded" />
          <div className="h-2 w-2 rounded-full bg-gray-300"></div>
        </div>
      </div>
    </div>
  );
};
