import React from "react";

export const PersonalityTest = () => {
  return (
    <div>
      <div className="mb-4">
        <h1 className="font-semibold text-lg text-accent">Personality</h1>
        <h3 className="text-sm">Take a personality test</h3>
      </div>

      <hr />

      <div className="flex justify-center mt-7 text-center">
        <a href="!#" className="button" style={{width: "20em"}}>
          Take Personality Test
        </a>
      </div>
    </div>
  );
};
