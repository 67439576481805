import { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import CurrencyFormatter from "utils/CurrencyFormatter";

export const AdminChart = ({ height = 350 }) => {
  const series = [
    {
      "name":"2024",
      data: [88, 71, 80, 84, 86, 71, 86, 77, 75, 89, 71, 86],
    },
    {
      "name":"2023",
      data: [96, 72, 82, 65, 87, 70, 80, 79, 67, 84, 87, 70],
    },
    {
      "name":"2022",
      data: [90, 70, 85, 60, 80, 70, 90, 75, 60, 80, 75, 96],
    }    
  ];

  
  const options : any = {
    chart: {
      toolbar: {
        show: false,
      },
      stacked: true
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center'
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    colors: ["#ffb300", "#4669FA", "#0ce7fa"],
    tooltip: {
      theme: "dark",
    },
    grid: {
      show: false,
      borderColor: "#e2e8f0",
      strokeDashArray: 10,
      position: "back",
    },
    fill: {
      type: "gradient",
      colors: ["#ffb300", "#4669FA", "#0ce7fa"],
    },
    yaxis: {
      show: false,
      labels: {
        style: {
          colors: "#475569",
          fontFamily: "Inter",
        },
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      labels: {
        style: {
          colors: "#475569",
          fontFamily: "Inter",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  };

  return (
    <div>
      <Chart options={options} series={series} type="area" height={height} />

      <Chart options={revoptions} series={rev} type="bar" height={height} />
    </div>
  );
};



  const rev = [
    {
      name: "Revenue",
      data: [760000, 850000, 990000, 980000, 870000, 970000, 910000, 846000, 940000, 750000, 812000, 995000],
    }
  ];
  const revoptions : any = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "rounded",
        columnWidth: "45%",
      },
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "right",
      fontSize: "12px",
      fontFamily: "Inter",
      offsetY: -30,
      markers: {
        width: 8,
        height: 8,
        offsetY: -1,
        offsetX: -5,
        radius: 12,
      },
      labels: {
        colors: "#475569",
      },
      itemMargin: {
        horizontal: 18,
        vertical: 0,
      },
    },
    title: {
      text: "Revenue Report",
      align: "left",
      offsetX: 0,
      offsetY: 13,
      floating: false,
      style: {
        fontSize: "20px",
        fontWeight: "500",
        fontFamily: "Inter",
        color: "#0f172a",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    yaxis: {
      show: false,
      opposite: false,
      labels: {
        style: {
          colors: "#475569",
          fontFamily: "Inter",
        },
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      labels: {
        style: {
          colors: "#475569",
          fontFamily: "Inter",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          const formattedValue = val.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
          return "₦" + formattedValue;
        },
      },
    },
    colors: ["#4669FA", "#0CE7FA", "#FA916B"],
    grid: {
      show: true,
      borderColor: "#E2E8F0",
      strokeDashArray: 10,
      position: "back",
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          legend: {
            position: "bottom",
            offsetY: 8,
            horizontalAlign: "center",
          },
          plotOptions: {
            bar: {
              columnWidth: "80%",
            },
          },
        },
      },
    ],
  };