import { Form, Modal, Rate } from "antd";
import TextArea from "antd/es/input/TextArea";
import { AppButton } from "components/button/AppButton";
import { QUERY_KEY_FOR_FEEDBACK } from "components/layout/hooks/useGetFeedback";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { IdentifierProps } from "types";
import { openNotification } from "utils/notification";
import { IReviewProps } from "../types";
import { useSendReview } from "../hooks/useSendReview";
import { useQueryClient } from "react-query";

export const ReviewForm = ({ open, handleClose, data}: IdentifierProps) => {
  const desc = ["terrible", "bad", "normal", "good", "wonderful"];
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { token, userInfo } = useGetUserInfo();
  const { mutate, isLoading } = useSendReview();

  const handleSubmit = (item: any) => {
    const props: IReviewProps = {
      token,
      message: item.message,
      rate: item.rate,
      listing_invite_id: data.id
    };
    mutate(props, {
      onError: (err: any) => {
        let errorTitle = "Error";
        let errorDescription = "An error occurred, please try again.";

        if (err.response && err.response.data &&  err.response.data.error) {
          errorTitle = "Error: " + err.response.data.error;
          errorDescription = err.response.data.description || "Please try again.";
        }
        openNotification({
          title: errorTitle,
          state: "error",
          description: errorDescription,
          duration: 6,
        });
      },
      onSuccess: (res: any) => {
        openNotification({
          title: "Success",
          state: "success",
          description: "Review sent Successfully",
          duration: 4.5,
        });
        form.resetFields();
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_FOR_FEEDBACK],
        });
        handleClose();
      },
    });
  };
  
  return (
    <Modal
      style={{ top: 15 }}
      footer={false}
      open={open}
      //onCancel={() => handleClose()}
      title="Review"
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <Form.Item
          name="rate"
          label={`How did your ${data?.listing?.title} interview with ${userInfo.recruiter ? data?.talent?.first_name+' '+data?.talent?.last_name :data?.listing?.company_name} go?`}
          rules={[{ required: true, message: "Rating is required" }]}
        >
          <Rate tooltips={desc} allowClear={true} />
        </Form.Item>

        <Form.Item name="message" label="Care to share more about it?">
          <TextArea rows={5} />
        </Form.Item>

        <div className="flex justify-end gap-3">
          {/*<AppButton
            variant="transparent"
            label="Close"
            handleClick={() => handleClose()}
          />*/}
          <AppButton label="Send" type="submit" />
        </div>
      </Form>
    </Modal>
  );
};
