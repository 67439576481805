import checkIcon from "../assets/images/CheckIcon.svg";
import { AppButton } from "components/button/AppButton";
import { QUERY_KEY_FOR_PACKAGE, useGetPackage } from "../hooks/useGetPackage";
import { Dropdown, Menu, Popconfirm, Skeleton } from "antd";
import { AddPackage } from "./AddPackage";
import { useState } from "react";
import { UpdatePackage } from "./UpdatePackage";
import { TPackage } from "../types";
import { AddPackageFeature } from "./AddPackageFeature";
import { useDelete } from "hooks/useDelete";
import CurrencyFormatter from "utils/CurrencyFormatter";
import { AsynPermissions } from "./AsynPermissions";

export const Package = () => {
  const [addPackage, setAddPackage]= useState(false);
  const [editPackage, setEditPackage]= useState(false);
  const [currentPackage, setCurrentPackage] = useState<TPackage>();
  const [createPackageFeature, setCreatePackageFeature] = useState(false);
  const { data, isLoading } = useGetPackage();

  const deleteEndPointUrl="administrator/delete-benefit/";
  const packageDeleteEndPointUrl="administrator/delete-package/";

  const { removeData } = useDelete({
    queryKey: QUERY_KEY_FOR_PACKAGE,
    deleteEndPointUrl,
  });


  const { removeData: DeletePackage } = useDelete({
    queryKey: QUERY_KEY_FOR_PACKAGE,
    deleteEndPointUrl:packageDeleteEndPointUrl,
  });
  
  return (
    <div>
      {
        addPackage &&
        <AddPackage
          open={addPackage}
          handleClose={()=>setAddPackage(false)}
        />
      }
      
      {
        editPackage && 
        <UpdatePackage
          open={editPackage}
          handleClose={()=>setEditPackage(false)}
          data={currentPackage}
        />
      }

      { createPackageFeature &&
        <AsynPermissions
          open={createPackageFeature}
          handleClose={() => setCreatePackageFeature(false)}
          data={currentPackage}
        />
      }

      <div className="flex justify-between mb-4">
        <div>
            <h4 className="font-semibold text-lg text-primary">
            Subscription Package
            </h4>
            <h3 className="text-sm">Manage Subscription Package pricing</h3>
        </div>

        <div className="flex items-center gap-x-3">
          <AppButton
            type="submit"
            label="Add Package"
            isLoading={false}
            handleClick={()=>setAddPackage(true)}
          />
        </div>

      </div>
      <hr />
      <div className="col-span-12 pt-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          <Skeleton active loading={isLoading}>
            {data?.map((item, index) => (
              <div key={index} className="flex flex-col h-full rounded-md border shadow px-5 pb-7 pt-2 bg-white">
                <div className="text-right">
                  <Dropdown
                    trigger={["click"]}
                    overlay={
                      <Menu>
                        <Menu.Item key="1" onClick={() => { setCurrentPackage(item); setEditPackage(true); }}>
                          <i className="ri-edit-2-fill text-md"></i> Edit
                        </Menu.Item>
                        <Menu.Item key="2">
                          <Popconfirm
                  okButtonProps={{
                    style: { backgroundColor: '#f5222d', color: '#ffffff' }, // Custom styles
                  }} 
                            title="Delete Package"
                            description="Are you sure to delete this?"
                            onConfirm={() => DeletePackage(item.id)}
                          >
                            <i className="ri-delete-bin-5-fill text-md text-red-500"></i> Delete
                          </Popconfirm>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <i className="ri-more-2-fill text-lg cursor-pointer"></i>
                  </Dropdown>
                </div>

                <div className="flex-grow pb-7 pt-8 text-center bg-white">
                  <h2 className="font-semibold text-xl text-primary">{item.name} - {item.account_type}</h2>
                  <div className="my-3">
                    <h4 className="font-bold text-base text-primary">
                      <CurrencyFormatter value={Number(item.monthly)} decimalPrecision={0} /> - <CurrencyFormatter value={Number(item.annually)} decimalPrecision={0} />
                    </h4>
                    <h3 className="text-xs">{item.description}</h3>
                  </div>
                  <ul className="flex flex-col gap-3 pt-2 text-left">
                    {item.permissions.map((permission, i) => (
                      <li key={i} className="flex items-center gap-x-3 justify-between">
                        <p className="flex justify-flex-start">
                          <img src={checkIcon} alt="check icon" />
                          <h3 className="text-sm pl-2">{permission}</h3>
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
                
                <div className="mt-8">
                  {item.account_type !== "enterprise" && (
                    <AppButton
                      label="Add Features"
                      additionalClassNames={[
                        "text-green-700 font-semibold w-full bg-green-100 py-2 rounded",
                      ]}
                      handleClick={() => { setCurrentPackage(item); setCreatePackageFeature(true); }}
                    />
                  )}
                </div>
              </div>
            ))}
          </Skeleton>
        </div>
      </div>
    </div>
  );
};
