import { Form, Input } from "antd";
import { AppButton } from "components/button/AppButton";
import { useState } from "react";
import { END_POINT } from "config/environment";
import { openNotification } from "utils/notification";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { decode } from 'base-64';

import {
  passwordValidationRules,
  textInputValidationRules,
} from "utils/formHelpers/validations";

export const ResetPasswordForm = () => {

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const params = token ? decode(token) : null;
  const data = params ? JSON.parse(params) : null;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(data ? data.email : '');
  const [dataToken, setDataToken] = useState(data ? data.token : '');
  const [password, setPassword ]= useState('');
  const [password_confirmation, setPassword_confirmation ]= useState('');

  

  const submit = () => {
    if(!token){
      return ;
    }
    setLoading(true);
    fetch(`${END_POINT.AUTH_URL}/reset-password`, {
      method: 'POST',
      headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      },
      body: JSON.stringify({email:email, password:password,password_confirmation:password_confirmation, token:dataToken})
    })
    .then((response) => {
        if (response.ok) {
            return response.json();
        }
        setLoading(false);
        openNotification({
          title: 'Error',
          state: 'error',
          description: 'Something went wrong',
          duration: 4.5,
        });
        throw new Error('Something went wrong!');
    })
    .then((data) => {
      openNotification({
        title: 'Success',
        state: 'success',
        description: 'Password reset successfully',
        duration: 4.5,
      });
      navigate('/');
    })
    .catch((error) => console.error(error));
  };

  return (
    <Form layout="vertical" requiredMark={false} onFinish={submit}>
      <Form.Item
        name="password"
        label="Password"
        rules={passwordValidationRules}
        className="-mt-3"
      >
        <Input.Password onChange={(e:any)=>setPassword(e.target.value)} placeholder="***********" className="shadow-sm py-2" />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        label="Confirm Password"
        dependencies={["password"]}
        rules={[
          {
            required: true,
            message: "Field is required",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                "The two passwords that you entered does not match."
              );
            },
          }),
        ]}
        className="-mt-3"
      >
        <Input.Password onChange={(e)=>setPassword_confirmation(e.target.value)} placeholder="***********" className="shadow-sm py-2" />
      </Form.Item>
      <AppButton
        label="Submit"
        type="submit"
        isLoading={loading}
        additionalClassNames={["button w-full py-1"]}
      />
    </Form>
  );
};
