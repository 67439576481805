import axios from "axios";
import { useQuery } from "react-query";
import { END_POINT } from "config/environment";
import { useGetUserInfo } from "hooks/useGetUserInfo";

// TO DO : need to exist in the general data entities and refactored
interface IGetDataProps {
  token?: string;
}

export const QUERY_KEY_FOR_ROLES  = "Roles";

const getRoles = async (
  props: IGetDataProps
): Promise<{ data: any }> => {

  const url = `${END_POINT.BASE_URL}/v1/administrator/roles`;

  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    }
  };

  const res = await axios.get(url, config);
  const fetchedData = res.data;

  const data: any[] = fetchedData.map(
    (item: any): any => ({ ...item })
  );
  const ans = {
    data
  };
  
  return ans;
};

export const useGetRoles = (props: IGetDataProps) => {
  const { token } = useGetUserInfo();
  const queryData = useQuery(
    [QUERY_KEY_FOR_ROLES, props],
    () =>
      getRoles({
        ...props,
        token,
      }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
    }
  );
  return queryData;
};