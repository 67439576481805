import { Form, Modal, Checkbox, Row, Col } from "antd";
import { AppButton } from "components/button/AppButton";
import { useGetPermissions } from "../hooks/useGetPermissions";
import { openNotification } from "utils/notification";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useAsignRoles } from "../hooks/useAsignRoles";
import { useQueryClient } from "react-query";
import { useState } from "react";
import { QUERY_KEY_FOR_PACKAGE } from "../hooks/useGetPackage";

export const AsynPermissions = ({ open, handleClose, data: pdata }: any) => {
  const { data } = useGetPermissions({});
  const [form] = Form.useForm();
  const { token } = useGetUserInfo();
  const [checkedPermissions, setCheckedPermissions] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useAsignRoles();

  const handleFormSubmit = () => {
    const props: any = {
      token,
      name: pdata.name,
      permissions: checkedPermissions,
    };

    mutate(props, {
      onError: () => {
        openNotification({
          title: "Error",
          state: "error",
          description: "Error occurred, try again",
          duration: 6.0,
        });
      },
      onSuccess: () => {
        openNotification({
          title: "Success",
          state: "success",
          description: "Permissions assigned successfully",
          duration: 4.5,
        });
        form.resetFields();
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_FOR_PACKAGE],
        });
        handleClose();
      },
    });
  };

  const preselectedPermissions = pdata?.permissions.map((permission: { name: string }) => permission.name) ?? [];
  const onChange = (checkedValues: string[]) => { // Corrected type for checkedValues
    setCheckedPermissions(checkedValues);
  };

  return (
    <Modal
      style={{ top: 20 }}
      footer={false}
      open={open}
      onCancel={() => handleClose()}
      title="Assign Permissions"
      width="50rem"
    >
      <div className="py-8">
        <Checkbox.Group onChange={onChange} defaultValue={preselectedPermissions}>
          <div className="grid grid-cols-3 gap-4">
            {data?.data.map((permission: any, index: number) => (
              <Checkbox key={index} value={permission}>
                {permission}
              </Checkbox>
            ))}
          </div>
        </Checkbox.Group>
      </div>
      <AppButton label="Submit" type="submit" isLoading={isLoading} handleClick={handleFormSubmit} />
    </Modal>
  );
};
