import axios from "axios";
import { useMutation } from "react-query";
import { END_POINT } from "config/environment";
import { IUsers } from "../types";

export const editUser = async (props: IUsers) => {
  const url = `${END_POINT.BASE_URL}/v1/administrator/users/${props.user_id}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  const data: any = {
    name: props.name,
    email: props.email,
    role: props.roles,
    banned: props.banned,
    password: props.password
  };

  const response = await axios.put(url, data, config);
  return response;
};

export const useEditUser = () => {
  return useMutation(editUser);
};