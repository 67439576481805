import { Form, Input } from "antd";
import { AppButton } from "components/button/AppButton";
import {
  passwordValidationRules,
  textInputValidationRules,
} from "utils/formHelpers/validations";
import { useChangePassword } from "../hooks/useChangePassword";
import { openNotification } from "utils/notification";
import { useGetUserInfo } from "hooks/useGetUserInfo";

export const Password = () => {
  const { mutate, isLoading } = useChangePassword();
  const { token } = useGetUserInfo();
  const [form] = Form.useForm();

  const handleSubmit = (data: any) => {
    mutate(
      {
        ...data,
        token,
      },
      {
        onError: (err: any) => {
          console.log(err.response.data);

          openNotification({
            title: "Error",
            state: "error",
            description: `${err.response.data.data.old_password[0]}`,
            duration: 4.5,
          });
        },
        onSuccess: (res: any) => {
          openNotification({
            title: "Success",
            state: "success",
            description: "Password reset successful",
            duration: 4.5,
          });
          form.resetFields();
        },
      }
    );
  };

  return (
    <Form onFinish={handleSubmit}>
      <div className="flex justify-between mb-4">
        <div>
          <h1 className="font-semibold text-lg text-accent">Password</h1>
          <h3 className="text-sm">Update your password.</h3>
        </div>
        <div className="flex items-center gap-x-3">
          <AppButton type="button" label="Cancel" variant="transparent" />
          <AppButton type="submit" label="Save" isLoading={isLoading} />
        </div>
      </div>
      <hr />

      <div className="flex flex-col gap-3 mt-3 md:mt-5">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-2">
          <h3>Old password</h3>
          <Form.Item
            name="old_password"
            className="col-span-2"
            rules={textInputValidationRules}
          >
            <Input.Password placeholder="****************" />
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-2">
          <h3>New password</h3>
          <Form.Item
            name="password"
            className="col-span-2"
            rules={passwordValidationRules}
          >
            <Input.Password placeholder="****************" />
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-2">
          <h3>Confirm password</h3>
          <Form.Item
            name="password_confirmation"
            className="col-span-2"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Field is required",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The two passwords that you entered does not match."
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="****************" />
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};
