import  { useEffect, useState } from "react";
import { END_POINT } from "config/environment";
import {useLocation} from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { useNavigate } from 'react-router-dom';
import { openNotification } from "utils/notification";
import axios from "axios";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { AppButton } from "components/button/AppButton";
import {decode as base64_decode, encode as base64_encode} from 'base-64';

  
export const TeamInvite = () => {
  const location = useLocation();
  const navigate = useNavigate();
  //const { token } = useGetUserInfo();
  const queryParams = new URLSearchParams(location.search);
  const token: string | null = queryParams.get('token');
  const res: any = token !== null ? base64_decode(token) : {};
  const data = JSON.parse(res);

  const acceptInvite = async () => {

      try {
        const response = await axios.get(`${END_POINT.BASE_URL}/accept-team-invitation-email?user_id=${data?.user.id}&team_id=${data?.team_id}`, {
          headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          }
        });

        const res = await response.data;
            openNotification({
                title: "Success",
                state: "success",
                description: "Successfull",
                duration: 4.5,
            });
            navigate('/');

        } catch (error: any) {
    
        if (error?.response && error?.response?.status === 403) {
            openNotification({
                title: 'Error',
                state: 'error',
                description: error?.response.data.message,
                duration: 4.5,
            });
        } else {
            openNotification({
            title: 'Error',
            state: 'error',
            description: 'Something went wrong!',
            duration: 4.5,
            });
        }
        } finally {
      }
      
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
        {/*<ScaleLoader color="var(--app-color-primary)" />*/}

        {
          !token ? 
          <h1 className="font-semibold text-2xl">Invalid Request</h1>
          :
          <>
            <h3 className="font-semibold text-2xl">You have receive invitation from {data?.recruiter?.company_name}</h3>
            <p className="py-2 pb-8 text-lg">Click the  "Accept Invitation" button below to honor this invitation.</p>
            <AppButton label="Accept Invitation" handleClick={()=>acceptInvite()} />
          </>
        }
      </div>
  );
};
