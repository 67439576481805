import axios from "axios";
import { useQuery } from "react-query";
import { END_POINT } from "config/environment";
import { useGetUserInfo } from "hooks/useGetUserInfo";

// TO DO : need to exist in the general data entities and refactored
interface IGetDataProps {
  token?: string;
}

export const QUERY_KEY_FOR_PERMISSIONS  = "Permissions";

const getPermissions = async (
  props: IGetDataProps
): Promise<{ data: any }> => {

  const url = `${END_POINT.BASE_URL}/v1/administrator/roles-permissions?guard=users`;

  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    }
  };

  const res = await axios.get(url, config);
  const fetchedData = res;

  
  return fetchedData;
};

export const useGetPermissions = (props: IGetDataProps) => {
  const { token } = useGetUserInfo();
  const queryData = useQuery(
    [QUERY_KEY_FOR_PERMISSIONS, props],
    () =>
      getPermissions({
        ...props,
        token,
      }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
    }
  );
  return queryData;
};