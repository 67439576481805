import axios from "axios";
import { useMutation } from "react-query";
import { IPackage } from "../types";
import { END_POINT } from "config/environment";

export const createPackage = async (props: IPackage) => {
  const url = `${END_POINT.BASE_URL}/v1/administrator/packages`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  const data: any = {
    name: props.name,
    description: props.description,
    monthly: props.monthly,
    quarterly: props.quarterly,
    biannually: props.biannually,
    annually: props.annually,
    account_type: props.account_type,
    use_enterprise: props.use_enterprise,
    enterprise_discount: props.enterprise_discount,
    status: 1,
  };

  const response = await axios.post(url, data, config);
  return response;
};

export const useCreatePackage = () => {
  return useMutation(createPackage);
};
