import Table, { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { hasPermission } from "utils/hasPermission";
import { QUERY_KEY_FOR_GATWAYS, useGetGateways } from "../hooks/useGetGateways";
import { Dropdown, Menu } from "antd";
import { openNotification } from "utils/notification";
import { useQueryClient } from "react-query";
import { useUpdateGateway } from "../hooks/useUpdateGateway";
import { IGateways } from "../types";

export const PaymentGateways = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const { token, userInfo } = useGetUserInfo();
  const queryClient = useQueryClient();
  const { mutate } = useUpdateGateway();

  const hasPermissionFor = (...permissionNames: string[]): boolean => {
    return permissionNames.some(permissionName =>
      hasPermission(userInfo, permissionName)
    );
  };

  const { data, isFetching } = useGetGateways();

  const handleStatus = (values: any) => {
    const props:IGateways = {
    token,
    id: values.id as unknown as number,
    status: values.status
    };
    mutate(props, {
    onError: () => {
      openNotification({
        title: "Error",
        state: "error",
        description: "Error occurred try again",
        duration: 6.0,
      });
    },
    onSuccess: () => {
        openNotification({
        title: "Success",
        state: "success",
        description: "Success",
        duration: 4.5,
        });
        queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_FOR_GATWAYS],
        });
    },
    });
};

  const columns: ColumnsType<any> = [
    {
      title: "Logo",
      dataIndex: "image",
      render: (_, val) => (
        <div>
          <img
            src={val.image}
            alt="avatar"
            className="w-20 object-cover"
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, val) => (
        <div
          style={{ background: "#F2F4F7" }}
          className="inline-flex items-center justify-center gap-2 bg-red-300 rounded-xl px-2"
        >
          {val?.status == 1 ?
            <>
              <i
                className="ri-checkbox-blank-circle-fill text-green-500"
                style={{ fontSize: 10 }}
              ></i>
              <span className="text-sm pb-1">Active</span>
            </>:
            <>
            <i
              className="ri-checkbox-blank-circle-fill text-red-500"
              style={{ fontSize: 10 }}
            ></i>
            <span className="text-sm pb-1">Inactive</span>
          </>
          }
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",

      render: (_, val) => (
        <div>
          {
            userInfo?.id !== val.id && hasPermissionFor('Manage Admin users') &&
            <Dropdown
            trigger={["click"]}
            disabled={selectedRowKeys.length > 0}
            overlay={
              <Menu>
                { val?.status == 1 ?
                  <Menu.Item key="2" onClick={()=>handleStatus({id:val?.id, status:0})}>
                    <p className="text-red-500">Deactivate</p>
                  </Menu.Item>
                  :
                  <Menu.Item key="2" onClick={()=>handleStatus({id:val?.id, status:1})}>
                    <p className="text-green-500"> Activate</p>
                  </Menu.Item>
                }
              </Menu>
            }
          >
            <i className="ri-more-2-fill text-lg cursor-pointer"></i>
          </Dropdown>
          }
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        className="bg-white rounded-md shadow border mt-8"
        columns={columns}
        dataSource={data?.map((item) => ({ ...item, key: item.id }))}
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys: React.Key[], rows) => {
            setSelectedRowKeys(rows.map((row) => row.id));
          },
        }}
        scroll={{ x: 550 }}
        loading={isFetching}
      />
    </div>
  );
};
