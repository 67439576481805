import { DashboardLayout } from "admin/components/layout/components/DashboardLayout";
import { Navbar } from "admin/components/layout/components/Navbar";
import { TalentsTable } from "../components/TalentsTable";
import { useState } from "react";
import { FilterModal } from "../components/FilterModal";

export const AdminTalents = () => {
  const [openFilter, setOpenFilter] = useState(false);

  return (
    <DashboardLayout>
      <Navbar title="Talents" description="View and manage Talents" />
      <FilterModal open={openFilter} handleClose={() => setOpenFilter(false)} />
      <div className="bg-gray-100 px-4 py-3 mt-8 rounded-md flex justify-between items-center">
        <button
          className="transparentButton flex items-center gap-x-2"
          style={{ background: "white" }}
        >
          <i className="ri-download-cloud-2-line text-base"></i>
          <span>Download CSV</span>
        </button>
        <button
          className="transparentButton flex items-center gap-x-2"
          style={{ background: "white" }}
          onClick={() => setOpenFilter(true)}
        >
          <i className="ri-filter-3-line text-base"></i>
          <span>Filters</span>
        </button>
      </div>

      <TalentsTable />
    </DashboardLayout>
  );
};
