import axios from "axios";
import { useQuery } from "react-query";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { END_POINT } from "config/environment";
import { dashboardCountsProps } from "../types";

export const QUERY_KEY_FOR_RECRUITER_DASHBOARD_COUNT = "recruiter_dashboard";

const getData = async (props: {
  token: string;
  userInfo: any;
}): Promise<dashboardCountsProps> => {
  const corporate_id = props?.userInfo?.current_team ? '?corporate_id='+props?.userInfo?.current_team.corporate_id :'';

  const url = `${END_POINT.BASE_URL}/v1/recruiter_dashboard${corporate_id}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  
  const res = await axios.get(url, config);
  const item: dashboardCountsProps = res.data;
  const data: dashboardCountsProps = {
    ...item,
  };

  return data;
};

export const useGetRecruiterCount = () => {
  const { token, userInfo } = useGetUserInfo();
  const queryData = useQuery(
    [QUERY_KEY_FOR_RECRUITER_DASHBOARD_COUNT],
    () => getData({ token, userInfo }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
    }
  );

  return queryData;
};
