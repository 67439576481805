import { HomeCard } from "./HomeCard";
import { useGetCount } from "../hooks/useGetCount";
import { useState, useEffect } from "react";

export const AdminCount = () => {
  const { data } = useGetCount();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">

      <HomeCard
        title="Total Jobs"
        count={data?.jobs ? data?.jobs : 0}
        percentage={40}
      />
      <HomeCard
        title="Total Recruiters"
        count={data?.recruiters ? data?.recruiters : 0}
        percentage={10}
      />
      <HomeCard
        title="Total Candidates"
        count={data?.talents ? data?.talents : 0}
        percentage={20}
      />
    </div>
  );
};
