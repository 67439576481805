import  { useEffect, useState } from "react";
import "../assets/style.css";
import { END_POINT } from "config/environment";
import {useLocation} from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from 'react-router-dom';
import { openNotification } from "utils/notification";
import axios from "axios";
import { useGetUserInfo } from "hooks/useGetUserInfo";

  
export const GoogleCallback = () => {
  const location = useLocation();
  const signIn = useSignIn();
  const navigate = useNavigate();
  const { userInfo, token } = useGetUserInfo();
  const [renderCount, setRenderCount] = useState(0);


  useEffect(() => {
    setRenderCount(prevCount => prevCount + 1);
  },[]);

  useEffect(() => {
    //if (renderCount === 2) {
        const handleAuthCallbackWrapper = async () => {
            await handleAuthCallback();
          };
          handleAuthCallbackWrapper();
    //}
  }, []);


  const handleAuthCallback = async () => {
    const queryParams = new URLSearchParams(location.search);
    const keyValue = queryParams.get('state');
    if(keyValue){
      try {
      const response = await axios.get(`${END_POINT.AUTH_URL}/auth/callback${location.search}`, {
          headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
          }
      });
  
      const data = await response.data;
  
      if (signIn({
          token: data.token,
          tokenType: "Bearer",
          authState: data,
          expiresIn: 180,
      })) {
          openNotification({
          title: "Success",
          state: "success",
          description: "Logged in successfully",
          duration: 4.5,
          });
      }
  
      navigate('/');
      } catch (error: any) {
  
      if (error?.response && error?.response?.status === 403) {
          openNotification({
              title: 'Error',
              state: 'error',
              description: error?.response.data.message,
              duration: 4.5,
          });
          navigate('/sign-up');
      } else {
          openNotification({
          title: 'Error',
          state: 'error',
          description: 'Something went wrong!',
          duration: 4.5,
          });
          navigate('/');
      }
      
      } finally {
      }
    }else{
        try {
            const response = await axios.get(`${END_POINT.BASE_URL}/v1/oauth/google-callback${location.search}`, {
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
              }
            });
        
            const data = await response.data;
              openNotification({
                title: "Success",
                state: "success",
                description: "Authorized successfully",
                duration: 4.5,
              });
            navigate('/');
          } catch (error: any) {
              openNotification({
                title: 'Error',
                state: 'error',
                description: 'Something went wrong!',
                duration: 4.5,
              });
              navigate('/');
            
            
          } finally {
          }
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
        <ScaleLoader color="var(--app-color-primary)" />
      </div>
  );
};
