import { Form, Modal, Select } from "antd";
import { AppButton } from "components/button/AppButton";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { openNotification } from "utils/notification";
import { TUpdatePackagePlan } from "../types";
import { useUpdatePackagePlan } from "../hooks/useUpdatePackagePlan";
import { QUERY_KEY_FOR_TEAM_MEMBERS } from "../hooks/useGetMembers";
import { QUERY_KEY_FOR_TEAM_PLANS, useGetPackagePlans } from "../hooks/useGetPackagePlans";

export const AttachUserPlan = ({ open, handleClose, user_id }: any) => {
  const { token } = useGetUserInfo();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useUpdatePackagePlan();
  const { data: planData, isLoading: loadingPlan } = useGetPackagePlans();
  const [packagePlan, setPackagePlan] = useState(null);
  const handleSubmit = () => {

    const props: TUpdatePackagePlan = {
      user_id: user_id,
      id: packagePlan,
      operation: 'asign-package-plan',
      token,
    };
    mutate(props, {
      onError: (err: any) => {
        let errorTitle = "Error";
        let errorDescription = "An error occurred, please try again.";

        if (err.response && err.response.data &&  err.response.data.error) {
          errorTitle = "Error: " + err.response.data.error;
          errorDescription = err.response.data.description || "Please try again.";
        }

        openNotification({
          title: errorTitle,
          state: "error",
          description: errorDescription,
          duration: 6,
        });
      },
      onSuccess: (res: any) => {
        openNotification({
          title: "Success",
          state: "success",
          description: "Successfull",
          duration: 4.5,
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_FOR_TEAM_MEMBERS],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_FOR_TEAM_PLANS],
        });
        handleClose();
      },
    });
  };

  return (
    <Modal
      title="Select plan"
      open={open}
      onCancel={() => handleClose(false)}
      footer={null}
      width="30rem"
      style={{ top: 20 }}
    >
      <div>
        <Form.Item name="plan">
          <Select
            className="SelectTag w-full"
            onChange={(e)=>setPackagePlan(e)}
            options={planData?.map((plan) => ({
              value: plan.id,
              label: plan.package.name,
            }))}
            placeholder="Select"
          ></Select>
        </Form.Item>
           
        <div className="flex justify-end">
          <AppButton type="submit" isLoading={isLoading} handleClick={()=>handleSubmit()} />
        </div>
      </div>
    </Modal>
  );
};
