export const adminRoutePath = {
  home: `/admin/`,
  sign_in: `/admin/sign-in`,
  sign_up: `/admin/sign-up`,
  users: `/admin/users`,
  roles: `admin/users/roles`,
  forgot_password: `/admin/forgot-password`,
  reset_password: `/admin/password-reset`,
  jobs: `/admin/jobs`,
  jobDetails: (id?: number | string) => ({
    format: `/admin/jobs/:id`,
    path: `/admin/jobs/${id}`,
  }),
  talents: `/admin/talents`,
  talentDetails: (id?: number | string) => ({
    format: `/admin/talents/:id`,
    path: `/admin/talents/${id}`,
  }),
  recruiters: `/admin/recruiters`,
  recruiterDetails: (id?: number | string) => ({
    format: `/admin/recruiter/:id`,
    path: `/admin/recruiter/${id}`,
  }),
  folderDetails: (id?: number | string) => ({
    format: `/admin/folders/:id`,
    path: `/admin/folders/${id}`,
  }),
  pricing: `/admin/pricing`,
  message: `/admin/message:id`,
  messages: `/admin/message`,
  settings: `/admin/settings`,
  finance: `/admin/finance`,
  meetingSettings: `/admin/meeting-settings`,
  invites: `/admin/invites`,
  team: `/admin/team`,
};
