import { Tabs } from "antd";
import { DashboardLayout } from "admin/components/layout/components/DashboardLayout";
import { Navbar } from "admin/components/layout/components/Navbar";
import { Package } from "./components/Package";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { PaymentGateways } from "./components/PaymentGateways";
import { BillingHistory } from "./components/BillingHistory";

export const AdminFinance = () => {
  const { userInfo } = useGetUserInfo();
  return (
    <DashboardLayout>
      <Navbar title="Finance" description="" />
      <Tabs defaultActiveKey="1" className="" size="large">
        <Tabs.TabPane tab="Subscription Packages" key="1">
          <Package />
        </Tabs.TabPane>
        
        <Tabs.TabPane tab="Transactions" key="2">
          <BillingHistory />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Payment Gateways" key="3">
          <PaymentGateways />
        </Tabs.TabPane>
      </Tabs>
    </DashboardLayout>
  );
};
