import { Skeleton, Modal } from "antd";
import { useGetSectors } from "features/jobs/hooks/useGetSectors";
import { IWorkHistory } from "features/settings/types";
import React, { useState } from "react";

export const WorkHistory: React.FC<{
  data: IWorkHistory[];
  volunteer : IWorkHistory[];
  isLoading?: boolean;
}> = ({ data, volunteer, isLoading }) => {
  const { data: sectorData } = useGetSectors();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);

  const handleEyeIconClick = (item: IWorkHistory) => {
    const sector = sectorData?.find((sec) => sec.id === item.sector_id);
    setModalContent(
      <div>
        <h1>
          <span className="font-medium pr-2">Sector:</span>
          <span> {sector?.name} </span>
        </h1>
        <p className="pt-1 text-justify">
          <span className="font-medium">Description:</span>
          <span className="text-sm pl-2">{item.description}</span>
        </p>
      </div>
    );
    setIsModalVisible(true);
  };

  return (
    <>
    <h3 className=" font-bold text-lg pt-8">Work History</h3>
      <div className="mt-6 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-8 gap-x-4 gap-y-3">
        <div className="md:col-span-2 lg:col-span-4">
          <Skeleton active loading={isLoading}>
            {data.map((item) => (
              <div className="border bg-white rounded-md px-3 py-4 mb-4 cursor-pointer hover:border-secondary">
                <div className="flex justify-between items-start">
                  <div className="capitalize">
                    <h5 className="text-xs font-semibold text-primary">
                      {item.company}
                    </h5>
                    <h4 className="text-base font-medium text-primary pb-3 pt-1">
                      {item.title}
                    </h4>
                  </div>
                  <i
                    className="ri-eye-line cursor-pointer text-lg hover:text-primary"
                    onClick={() => handleEyeIconClick(item)}
                  ></i>
                </div>
                <div
                  className="flex md:flex-row flex-col gap-y-3 md:gap-x-5 text-sm"
                  style={{ color: "#667085" }}
                >
                  <div className="flex items-center gap-x-1">
                    <i className="ri-map-pin-line"></i>
                    <span className="capitalize">{item.type}</span>
                  </div>
                  <div className="flex items-center gap-x-1">
                    <i className="ri-time-line"></i>
                    <div>
                      <span className="pr-2">{item.start_date} To</span>
                      <span>
                        {item.is_current === 0
                          ? item.end_date
                          : "Currently working here"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Skeleton>
        </div>

        <Modal
          title="Work History Details"
          footer={null}
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          style={{ top: 10 }}
        >
          {modalContent}
        </Modal>
      </div>

      <h3 className=" font-bold text-lg pt-8">Volunteer Experience</h3>
      <div className="mt-6 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-8 gap-x-4 gap-y-3">
        
        <div className="md:col-span-2 lg:col-span-4">
          <Skeleton active loading={isLoading}>
            {volunteer.map((item: IWorkHistory) => (
              <div className="border bg-white rounded-md px-3 py-4 mb-4 cursor-pointer hover:border-secondary">
                <div className="flex justify-between items-start">
                  <div className="capitalize">
                    <h5 className="text-xs font-semibold text-primary">
                      {item.company}
                    </h5>
                    <h4 className="text-base font-medium text-primary pb-3 pt-1">
                      {item.title}
                    </h4>
                  </div>
                  <i
                    className="ri-eye-line cursor-pointer text-lg hover:text-primary"
                    onClick={() => handleEyeIconClick(item)}
                  ></i>
                </div>
                <div
                  className="flex md:flex-row flex-col gap-y-3 md:gap-x-5 text-sm"
                  style={{ color: "#667085" }}
                >
                  <div className="flex items-center gap-x-1">
                    <i className="ri-map-pin-line"></i>
                    <span className="capitalize">{item.type}</span>
                  </div>
                  <div className="flex items-center gap-x-1">
                    <i className="ri-time-line"></i>
                    <div>
                      <span className="pr-2">{item.start_date} To</span>
                      <span>
                        {item.is_current === 0
                          ? item.end_date
                          : "Currently working here"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Skeleton>
        </div>
      </div>
    </>
  );
};
