import axios from "axios";
import { useMutation } from "react-query";
import { IUpdateDocument } from "../types";
import { END_POINT } from "config/environment";

export const updateDocuments = async (props: IUpdateDocument) => {
  const url = `${END_POINT.BASE_URL}/v1/save-document`;
  const config = {
    headers: {
      Accept: "application/json",
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${props.token}`,
    },
  };
  // necessary to make immediate changes when in  a central place when schema changes
  const data: any = {
    talent_id: props.talent_id,
    birt_certificate: props.birt_certificate,
    primary_school_certificate: props.primary_school_certificate,
    nysc_certificate: props.nysc_certificate
  };
  const response = await axios.post(url, data, config);
  return response;
};

export const useUpdateDocuments = () => {
  return useMutation(updateDocuments);
};
