import { Dropdown, Menu, Progress } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { AppButton } from "components/button/AppButton";
import { routePath } from "config/routeMgt/routePath";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SendInvite } from "./SendInvite";
import { AddToFolderForm } from "features/folders/components/AddToFolderForm";
import { ReportTalent } from "./ReportTalent";
import { useFetchTalent } from "../hooks/useFetchTalent";
import { usePagination } from "hooks/usePagination";
import { ITalent } from "../types";
import userDp from "assets/images/placeholderImage.jpeg";
import { useGlobalContext } from "stateManagers/GlobalContext";
import { END_POINT } from "config/environment";
import { useGetUserInfo } from "hooks/useGetUserInfo";

export const TalentsTable = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [inviteModal, setInviteModal] = useState(false);
  const [talentId, setTalentId] = useState<string>();
  const [addToFolder, setAddToFolder] = useState(false);
  const { filterTalent } = useGlobalContext();
  const [reportModaL, setReportModaL] = useState(false);
  const { pagination, onChange } = usePagination();
  const { token, userInfo } = useGetUserInfo();
  const navigate = useNavigate();

  const { data, isFetching } = useFetchTalent({
    pagination,
    experience: filterTalent.experience,
    gender: filterTalent.gender,
    sector: filterTalent.sector,
  });

  const getCurrentJob = (workHistories: any[]) => {
    return workHistories.find(job => job.is_current === 1);
  };

  const handleClear = () => {
    setSelectedRowKeys([]);
    setTalentId(undefined);
    setAddToFolder(false);
  };

  const sendInvite = (id: string) => {
    setInviteModal(true);
    setTalentId(id);
  };

  const handleAddToFolder = (id: string) => {
    setAddToFolder(true);
    setTalentId(id);
  };
  const sendMessage =(id: string)=>{
      const requestData = {
        to_user_id: id,
      };
    
      fetch(`${END_POINT.BASE_URL}/v1/creactchatlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(requestData)
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong!');
      })
      .then((data) => {
        console.log(data);
        navigate(`/message?id=${data.id}`);
      })
      .catch((error) => console.error(error));
    
  }

  const hasSelected = selectedRowKeys.length > 0;

  const columns: ColumnsType<ITalent> = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, val) => (
        <Link
          to={routePath.talentDetails(val.id).path}
          className="font-medium text-primary"
        >
          {val.first_name} {val.last_name}
        </Link>
      ),
    },
    {
      title: "Present Job Role",
      
      render: (_, val) => (
        <span>{getCurrentJob(val?.work_histories)?.title ?? null}</span>
      )
    },
    {
      title: "Present Employer",
      render: (_, val) => (
        <span>{getCurrentJob(val?.work_histories)?.company ?? null}</span>
      )
    },
    {
      title: "Visibility Score",
      dataIndex: "visibilityScore",
      render: (_, val) => (
        <div className="flex justify-center items-center">
          <Progress type="dashboard" size={35} percent={val.visibility_score} />
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, val) => (
        <div
          style={{ background: "#F2F4F7" }}
          className="inline-flex items-center justify-center gap-2 bg-red-300 rounded-xl px-2"
        >
          <i
            className="ri-checkbox-blank-circle-fill"
            style={{ fontSize: 10 }}
          ></i>
          <span className="text-sm pb-1">Processing</span>
        </div>
      ),
    },
    {
      title: "Avatar",
      dataIndex: "avatar",
      render: (_, val) => (
        <div>
          <img
            src={val?.dp ? val?.dp : userDp}
            alt="avatar"
            className="rounded-full h-10 w-10 object-cover object-top"
          />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",

      render: (_, val) => (
        <div>
          <Dropdown
            trigger={["click"]}
            disabled={selectedRowKeys.length > 0}
            overlay={
              <Menu>
                <Menu.Item key="1">
                  <Link to={routePath.talentDetails(val.id).path}>View profile</Link>
                </Menu.Item>
                <Menu.Item key="2" onClick={() => handleAddToFolder(val.id)}>
                  Add to folder
                </Menu.Item>
                <Menu.Item key="3" onClick={() => sendInvite(val.id)}>
                  Send meeting invite
                </Menu.Item>
                <Menu.Item key="4" onClick={() => sendMessage(val.id)}>
                  Send message
                </Menu.Item>
              </Menu>
            }
          >
            <i className="ri-more-2-fill text-lg cursor-pointer"></i>
          </Dropdown>
        </div>
      ),
    },
  ];

  const IdToBePassed = (): string[] => {
    if (selectedRowKeys.length > 0) {
      return selectedRowKeys;
    }
    if (!talentId) {
      return [];
    }
    return [talentId];
  };
  return (
    <div>
      <SendInvite
        id={talentId}
        open={inviteModal}
        handleClose={() => setInviteModal(false)}
      />
      <AddToFolderForm
        arrayId={IdToBePassed()}
        open={addToFolder}
        handleClose={() => handleClear()}
      />
      <ReportTalent
        open={reportModaL}
        handleClose={() => setReportModaL(false)}
      />
      {hasSelected && (
        <div className="mt-7 flex items-center gap-3">
          <button
            className="button"
            style={{ background: "#D92D20" }}
            onClick={() => setReportModaL(true)}
          >
            Report
          </button>
          <AppButton
            label="Add to folder"
            variant="transparent"
            handleClick={() => setAddToFolder(true)}
          />
        </div>
      )}

      <Table
        className="bg-white rounded-md shadow border mt-8"
        columns={columns}
        dataSource={data?.data.map((item) => ({ ...item, key: item.id }))}
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys: React.Key[], rows) => {
            setSelectedRowKeys(rows.map((row) => row.id));
          },
        }}
        scroll={{ x: 550 }}
        loading={isFetching}
        pagination={{ ...pagination, total: data?.total }}
        onChange={onChange}
      />
    </div>
  );
};
