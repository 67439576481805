import axios from "axios";
import { useMutation } from "react-query";
import { IUpdateDocument } from "../types";
import { END_POINT } from "config/environment";

interface ManageSubscription {
    token: string;
}
export const cancelSubscription = async (props: ManageSubscription) => {
  const url = `${END_POINT.BASE_URL}/v1/cancel-subscription`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };
  const response = await axios.get(url, config);
  return response;
};

export const useCancelSubscription = () => {
  return useMutation(cancelSubscription);
};
