import { Form, Input } from "antd";
import { AppButton } from "components/button/AppButton";
import avatar from "assets/images/default.webp";
import { textInputValidationRules } from "utils/formHelpers/validations";
import { QUERY_KEY_FOR_RECRUITER_PROFILE, useGetRecruiterProfile } from "../hooks/useGetRecruiterProfile";
import { ChangeEvent, useEffect, useState } from "react";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useUpdateRecruiterProfile } from "../hooks/useUpdateRecruiterProfile";
import { openNotification } from "utils/notification";
import { useQueryClient } from "react-query";
import { IRecruiterProfile } from "../types";
import { useSignIn } from "react-auth-kit";
import { FileWithPreview } from "features/settings/types";
import { resizeImageToSquare } from "utils/resizephoto";

export const Profile = () => {
  const { data, isLoading } = useGetRecruiterProfile();
  const { userInfo, token } = useGetUserInfo();
  const [form] = Form.useForm();
  const { mutate, isLoading: loadUpdate } = useUpdateRecruiterProfile();
  const queryClient = useQueryClient()
  const [file, setFile] = useState<FileWithPreview | null>(null);
  const [resizedBlob, setResizedBlob] = useState<Blob | null>(null);
  const signIn = useSignIn();
  
  useEffect(() => {
    form.setFieldsValue({
      first_name: data?.data.first_name,
      last_name: data?.data.last_name,
      rc_number: data?.data.rc_number,
      company_name: data?.data.company_name,
    });
  }, [form, data]);

  const handleSubmit = (data: any) => {

    const props: IRecruiterProfile = {
      first_name: data.first_name,
      last_name: data.last_name,
      company_name: data.company_name,
      rc_number: data.rc_number,
      token,
    };

    mutate(props, {
        onError: (err: any) => {
          openNotification({
            title: "Error",
            state: "error",
            description: "Error occurred try again",
            duration: 4.5,
          });
        },
        onSuccess: (res: any) => {
          if(
            signIn({
              token: token,
              tokenType: "Bearer",
              authState: {"token": token,"user":res.data},
              expiresIn: 180,
            }))
            {
          openNotification({
            title: "Success",
            state: "success",
            description: "Profile Updated Successful",
            duration: 4.5,
          });
          form.resetFields();
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_FOR_RECRUITER_PROFILE],
          });
        }
        },
      }
    );
  };


  return (
    <>
      <Form form={form} disabled={isLoading} onFinish={handleSubmit}>
        <div className="flex justify-between mb-4">
          <div>
            <h1 className="font-semibold text-lg text-accent">Personal info</h1>
            <h3 className="text-sm">Update your details here.</h3>
          </div>
          <div className="flex items-center gap-x-3">
            <AppButton type="button" label="Cancel" variant="transparent" />
            <AppButton type="submit" label="Save" isLoading={loadUpdate} />
          </div>
        </div>
        <hr />
        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Full Name</h3>
          <div className="col-span-3">
            <div className="flex gap-x-3">
              <Form.Item name="first_name" rules={textInputValidationRules}>
                <Input placeholder="First name" />
              </Form.Item>
              <Form.Item name="last_name" rules={textInputValidationRules}>
                <Input placeholder="Last name" />
              </Form.Item>
            </div>
          </div>
        </div>
        <hr />
        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Email Address</h3>
          <Form.Item
            // name="email"
            className="col-span-2"
          >
            <Input placeholder={userInfo?.email} disabled />
          </Form.Item>
        </div>
        <hr />
      </Form>
    </>
  );
};