import axios from "axios";
import { TCertification } from "../types";
import { useQuery } from "react-query";
import { END_POINT } from "config/environment";
import { IGeneralProps } from "types";

export const QUERY_KEY_FOR_SINGLE_CERTIFICATION = "get-single-certification";

export const getSingleCertification = async (
  props: IGeneralProps
): Promise<TCertification> => {
  const id = props.id;
  const url = `${END_POINT.BASE_URL}/v1/get-certification/${id}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  const res = await axios.get(url, config);
  const item = res.data;

  const data: TCertification = {
    id: item.id,
    talent_id: item.talent_id,
    title: item.title,
    issuer: item.issuer,
    link: item.link,
    file_path: item.file_path,
    issued_date: item.startDate,
    expiry_date: item.endDate,
  };
  return data;
};

export const useGetSingleCertification = ({ id, token }: IGeneralProps) => {
  const queryData = useQuery([QUERY_KEY_FOR_SINGLE_CERTIFICATION, id], () =>
    getSingleCertification({
      id,
      token,
    })
  );

  return queryData;
};
