import axios from "axios";
import { useQuery } from "react-query";
import { END_POINT } from "config/environment";
import { IGeneralProps } from "types";
import { TalentProfile } from "features/settings/types";

export const QUERY_KEY_FOR_TALENT_DETAILS = "talent-details";

const getSingleTalent = async (
  props: IGeneralProps
): Promise<TalentProfile> => {
  const id = props.id;
  const url = `${END_POINT.BASE_URL}/v1/administrator/talents/${id}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  const res = await axios.get(url, config);
  const item = res.data;
//   console.log(item);
  
  const data: TalentProfile = { ...item };

  return data;
};

export const useGetSingleTalent = ({ id, token }: IGeneralProps) => {
  const queryData = useQuery([QUERY_KEY_FOR_TALENT_DETAILS, id], () =>
    getSingleTalent({
      id,
      token,
    })
  );

  return queryData;
};
