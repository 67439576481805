import axios from "axios";
import { useQuery } from "react-query";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { END_POINT } from "config/environment";
import { ISectorProps } from "../types";

export const QUERY_KEY_FOR_JOBS = "sectors";

interface IGetDataProps {
  token?: string;
}

const getData = async (props: IGetDataProps): Promise<ISectorProps[]> => {
  const url = `${END_POINT.BASE_URL}/v1/sectors`;

  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  const res = await axios.get(url, config);
  const fetchedData = res.data;
//   console.log(res);
  
  const data: ISectorProps[] = fetchedData.map(
    (item: ISectorProps): ISectorProps => ({ ...item })
  );

  return data;
};

export const useGetSectors = () => {
  const { token } = useGetUserInfo();
  const queryData = useQuery(
    [QUERY_KEY_FOR_JOBS],
    () =>
      getData({
        token,
      }),
    {
      onError: (err: any) => {},
      onSuccess: (data) => {},
    }
  );

  return queryData;
};
