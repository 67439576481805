import React, { useState } from "react";
import { Form, Input, Select } from "antd";
import { AppButton } from "components/button/AppButton";
import { QUERY_KEY_FOR_TALENT_PROFILE, useGetTalentProfile, } from "features/settings/hooks/useGetTalentProfile";
import { useEffect } from "react";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { IUpdateDocument } from "features/settings/types";
import { useUpdateDocuments } from "features/settings/hooks/useUpdateTalentDocuments";
import { openNotification } from "utils/notification";
import { useQueryClient } from "react-query";
import DragNDrop from "utils/DragNdrop";

export const Documents = () => {
    const { data, isLoading: isGetTPLoading } = useGetTalentProfile();
    const [form] = Form.useForm();
    const { token, userInfo } = useGetUserInfo();
    const queryClient = useQueryClient();
    const { mutate, isLoading: isUpdateTPLoading } = useUpdateDocuments();
    const [bcertificate, setBcertificate] = useState<File | null>(null);
    const [pcertificate, setPcertificate] = useState<File | null>(null);
    const [ncertificate, setNcertificate] = useState<File | null>(null);
    const [editb, setEditb] = useState(false);
    const [editp, setEditp] = useState(false);
    const [editn, setEditn] = useState(false);
    
    /***
     * 
     * birt_certificate :item.birt_certificate,
        primary_school_certificate :item.primary_school_certificate,
        nysc_certificate :item.nysc_certificate,

     */
    
    const handleSubmit = () => {
        const props: IUpdateDocument = {
            talent_id: userInfo?.talent?.id,
            birt_certificate: bcertificate,
            primary_school_certificate :pcertificate,
            nysc_certificate :ncertificate,
            token,
        };
        mutate(props, {
          onError: (err: any) => {
            openNotification({
                title: "Error",
                state: "error",
                description: "Error occurred try again",
                duration: 4.5,
            });
          },
          onSuccess: (res: any) => {
            openNotification({
                title: "Success",
                state: "success",
                description: "Uploaded Successful",
                duration: 4.5,
            });
            form.resetFields();
            queryClient.invalidateQueries({
                queryKey: [QUERY_KEY_FOR_TALENT_PROFILE],
            });

            setEditb(false);
            setEditp(false);
            setEditn(false);
          },
        });
    };


  return (
    <div>
        <div className="flex justify-between mb-4">
            <div>
                <h4 className="font-semibold text-lg text-primary">
                    Documents
                </h4>
                <h3 className="text-sm">Upload your documents here.</h3>
            </div>
            {/*<div className="flex items-center gap-x-3">
                <AppButton type="button" label="Cancel" variant="transparent" />
                <AppButton
                    type="submit"
                    label="Save"
                    isLoading={isUpdateTPLoading}
                />
            </div>*/}
        </div>

      <hr />
        <div className="flex flex-col mt-12">

            <div className="flex flex-col py-5 md:flex-row">
                <div className="w-full md:w-1/4">
                    <h3 className="text-lg font-medium mb-2">Birth Certificate</h3>
                </div>
                <div className="w-full md:w-3/4">
                    <div className="lg:w-[400px]">
                        {
                            data?.data.certificates && data?.data?.certificates?.birt_certificate ?
                            <>
                                {
                                    editb ?
                                    <>
                                        <DragNDrop onFileSelected={setBcertificate} width="100%"/>

                                        <div className="flex gap-x-3 justify-end py-4">
                                            <AppButton type="button" label="Cancel" variant="transparent"  handleClick={()=> setEditb(false)}/>
                                            <AppButton
                                                label="Save"
                                                isLoading={isUpdateTPLoading}
                                                handleClick={()=>handleSubmit()}
                                            />
                                        </div>
                                    </>
                                    :
                                    <div className="relative">
                                        <div className="flex items-center justify-center py-4 space-x-1">
                                            <img
                                                src={data?.data?.certificates?.birt_certificate}
                                                width={100}
                                                height={200}
                                            />
                                            <i
                                            className="ri-edit-2-fill cursor-pointer text-xl text-primary hover:text-primary"
                                            onClick={() => setEditb(true)}
                                            ></i>
                                        </div>
                                    </div>


                                }
                            </>
                            
                            :
                            <>
                                <DragNDrop onFileSelected={setBcertificate} width="100%"/>
                            {
                                bcertificate &&
                                <div className="flex gap-x-3 justify-end py-4">
                                    <AppButton type="button" label="Cancel" variant="transparent" />
                                    <AppButton
                                        label="Save"
                                        isLoading={isUpdateTPLoading}
                                        handleClick={()=>handleSubmit()}
                                    />
                                </div>
                            }
                            </>
                        }
                    </div>
                </div>
            </div>

            <div className="flex flex-col py-5 md:flex-row">
                <div className="w-full md:w-1/4">
                    <h3 className="text-lg font-medium mb-2">Primary School Certificate</h3>
                </div>
                <div className="w-full md:w-3/4">
                    <div className="lg:w-[400px]">
                        {
                           data?.data.certificates && data?.data?.certificates?.primary_school_certificate ?
                            <>
                                {
                                    editp ?
                                    <>
                                        <DragNDrop onFileSelected={setPcertificate} width="100%"/>

                                        <div className="flex gap-x-3 justify-end py-4">
                                            <AppButton type="button" label="Cancel" variant="transparent"  handleClick={()=> setEditp(false)}/>
                                            <AppButton
                                                label="Save"
                                                isLoading={isUpdateTPLoading}
                                                handleClick={()=>handleSubmit()}
                                            />
                                        </div>
                                    </>
                                    :
                                    <div className="relative">
                                        <div className="flex items-center justify-center py-4 space-x-1">
                                            <img
                                                src={data?.data?.certificates?.primary_school_certificate}
                                                width={100}
                                                height={200}
                                            />
                                            <i className="ri-edit-2-fill absolute bottom-0 right-40 cursor-pointer text-xl text-primary hover:text-primary" onClick={() => setEditp(true)}></i>
                                        </div>
                                    </div>

                                }
                            </>
                            
                            :
                            <>
                                <DragNDrop onFileSelected={setPcertificate} width="100%"/>
                            {
                                pcertificate &&
                                <div className="flex gap-x-3 justify-end py-4">
                                    <AppButton type="button" label="Cancel" variant="transparent" />
                                    <AppButton
                                        label="Save"
                                        isLoading={isUpdateTPLoading}
                                        handleClick={()=>handleSubmit()}
                                    />
                                </div>
                            }
                            </>
                        }
                    </div>
                    
                </div>
            </div>

            <div className="flex flex-col py-5 md:flex-row">
                <div className="w-full md:w-1/4">
                    <h3 className="text-lg font-medium mb-2">NYSC Certificate</h3>
                </div>
                <div className="w-full md:w-3/4">
                    <div className="lg:w-[400px]">
                        {
                            data?.data.certificates && data?.data.certificates?.nysc_certificate ?
                            <>
                                {
                                    editn ?
                                    <>
                                        <DragNDrop onFileSelected={setNcertificate} width="100%"/>

                                        <div className="flex gap-x-3 justify-end py-4">
                                            <AppButton type="button" label="Cancel" variant="transparent"  handleClick={()=> setEditn(false)}/>
                                            <AppButton
                                                label="Save"
                                                isLoading={isUpdateTPLoading}
                                                handleClick={()=>handleSubmit()}
                                            />
                                        </div>
                                    </>
                                    :
                                    <div className="relative">
                                        <div className="flex items-center justify-center py-4 space-x-1">
                                            <img
                                                src={data?.data?.certificates?.nysc_certificate}
                                                width={100}
                                                height={200}
                                            />
                                            <i className="ri-edit-2-fill absolute bottom-0 right-40 cursor-pointer text-xl text-primary hover:text-primary" onClick={() => setEditn(true)}></i>
                                        </div>
                                    </div>

                                }
                            </>
                            
                            :
                            <>
                                <DragNDrop onFileSelected={setNcertificate} width="100%"/>
                            {
                                ncertificate &&
                                <div className="flex gap-x-3 justify-end py-4">
                                    <AppButton type="button" label="Cancel" variant="transparent" />
                                    <AppButton
                                        label="Save"
                                        isLoading={isUpdateTPLoading}
                                        handleClick={()=>handleSubmit()}
                                    />
                                </div>
                            }
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};
