    import React, { useState } from "react";
    import {
        QUERY_KEY_FOR_TALENT_PROFILE,
        useGetTalentProfile,
    } from "features/settings/hooks/useGetTalentProfile";
    import { AddCertification } from "./AddCertification";
    import { TCertification } from "features/settings/types";
    import { useDelete } from "hooks/useDelete";
    import { Popconfirm, Skeleton } from "antd";

    const deleteEndPointUrl = "delete-certification/";

    export const Certifications: React.FC<{
        data: TCertification[];
        isLoading?: boolean;
    }> = ({ data, isLoading }) => {

        const [addHistory, setAddHistory] = useState(false);
        const [id, setId] = useState<number>();
        const { removeData } = useDelete({
            queryKey: QUERY_KEY_FOR_TALENT_PROFILE,
            deleteEndPointUrl,
        });

        const handleAddCertification = () => {
            setId(undefined);
            setAddHistory(true);
        };

        const handleCertification = (id: number) => {
            setId(id);
            setAddHistory(true);
        };


    return (
        <div>
            <AddCertification
                id={id}
                open={addHistory}
                handleClose={() => setAddHistory(false)}
            />

            <div className="mt-6 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-8 gap-x-4 gap-y-3">
                <div className="lg:col-span-2">
                    <h4 className="font-semibold text-lg text-primary">
                        Certifications
                    </h4>
                    <h3>Add your professional Certifications</h3>
                </div>
                <div className="md:col-span-2 lg:col-span-4">
                    <Skeleton active loading={isLoading}>
                        {data.map((item) => (
                        <div className="border bg-white rounded-md px-3 py-4 mb-4 hover:border-secondary">
                            <div className="flex justify-between items-start">
                                <div>
                                    <h3 className="text-base font-semibold text-primary">
                                    Title: {item.title}
                                    </h3>
                                    <h4 className="text-base font-medium text-primary pb-3 pt-1">
                                    Issued by: {item.issuer}
                                    </h4>

                                    <div className="flex flex-col">
                                        <div>
                                        Date Issued: <span className="pr-2">{item.issued_date}</span>
                                        </div>

                                        <div>
                                        Expiry Date: <span className="pr-2">{item.expiry_date}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex items-center gap-x-3">
                                    {/*<i
                                    onClick={() => handleCertification(item.id)}
                                    className="ri-pencil-fill cursor-pointer text-lg hover:text-primary"
                                    ></i>*/}
                                    <Popconfirm
                                        okButtonProps={{
                                            style: { backgroundColor: '#f5222d', color: '#ffffff' },
                                        }} 
                                        title="Delete Certification"
                                        description="Are you sure to delete this Certification?"
                                        onConfirm={() => removeData(item.id)}
                                    >
                                    <i className="ri-delete-bin-line text-red-600 cursor-pointer text-base hover:text-red-800"></i>
                                    </Popconfirm>
                                </div>
                            </div>
                        </div>
                        ))}
                    </Skeleton>
                    <button
                        onClick={handleAddCertification}
                        className="text-primary font-bold text-base flex items-center gap-1 hover:text-accent"
                    >
                        <i className="ri-add-line"></i> <span>Add New</span>
                    </button>
                </div>
            </div>
        </div>
    );
};