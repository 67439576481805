import axios from "axios";
import { useMutation } from "react-query";
import { IGateways } from "../types";
import { END_POINT } from "config/environment";

export const update = async (props: IGateways) => {
  const url = `${END_POINT.BASE_URL}/v1/administrator/payment-gateways/${props.id}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  const response = await axios.put(url, props, config);
  return response;
};

export const useUpdateGateway = () => {
  return useMutation(update);
};
