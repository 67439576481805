import axios from "axios";
import { IWorkHistory } from "../types";
import { useQuery } from "react-query";
import { END_POINT } from "config/environment";
import { IGeneralProps } from "types";

export const QUERY_KEY_FOR_SINGLE_WORK_HISTORY = "get-single-work";

export const getSingleWork = async (
  props: IGeneralProps
): Promise<IWorkHistory> => {
  const id = props.id;
  const url = `${END_POINT.BASE_URL}/v1/get-work-history/${id}`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  const res = await axios.get(url, config);


  const item = res.data;

  const data: IWorkHistory = {
    id: item.id,
    talent_id: item.talent_id,
    title: item.title,
    sector_id: item.sector_id,
    company: item.company,
    type: item.type,
    start_date: item.start_date,
    end_date: item.end_date,
    is_current: item.is_current,
    description: item.description,
    months: item.months,
  };
  return data;
};

export const useGetSingleWork = ({ id, token }: IGeneralProps) => {
  const queryData = useQuery([QUERY_KEY_FOR_SINGLE_WORK_HISTORY, id], () =>
    getSingleWork({
      id,
      token,
    })
  );

  return queryData;
};
