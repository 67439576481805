import { Dropdown, Menu } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useGetMembers } from "../hooks/useGetMembers";
import { ITeamMembers } from "../types";

export const MemberTable = () => {
  const { data, isLoading } = useGetMembers();
  const columns: ColumnsType<ITeamMembers> = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, val) => <span>{val?.user?.name}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (_, val) => <span>{val?.user?.email}</span>,
    },
  
    {
      title: "status",
      dataIndex: "status",
      render: (_, val) => (
        <div className="inline-flex items-center justify-center gap-2 bg-green-100 rounded-xl px-2">
          <i
            className="ri-checkbox-blank-circle-fill text-green-700"
            style={{ fontSize: 10 }}
          ></i>
          <span className="text-sm pb-1 text-green-700">Enabled</span>
        </div>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",

      render: (_, val) => (
        <div>
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                <Menu.Item key="2">Disable</Menu.Item>
              </Menu>
            }
          >
            <i className="ri-more-2-fill text-lg cursor-pointer"></i>
          </Dropdown>
        </div>
      ),
    },
  ];
  console.log(data);
  return (
  
    
    <>
      <Table
        className="bg-white rounded-md shadow border mt-8"
        columns={columns}
        dataSource={data}
        scroll={{ x: 550 }}
        loading={isLoading}
      />
    </>
  );
};
