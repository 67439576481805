import { Modal, Skeleton } from "antd";
import { AppButton } from "components/button/AppButton";
import { TalentProfile } from "features/settings/types";
import { useState } from "react";
import { useGetRecruiterInvites } from "../hooks/useGetRecruiterInvites";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { ITalentInvites } from "features/invites/types";
import { getStatusText } from "../utils";
import { useSendJobOffer } from "../hooks/useSendJobOffer";
import { openNotification } from "utils/notification";
import { LoadingOutlined } from "@ant-design/icons";

export const InviteHistory: React.FC<{
  data: TalentProfile["data"] | null;
}> = ({ data }) => {
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedInviteIndex, setSelectedInviteIndex] = useState(-1);
  const { token } = useGetUserInfo();
  const { mutate } = useSendJobOffer();
  const { data: inviteData, isLoading } = useGetRecruiterInvites({
    token,
    id: data?.id as unknown as string,
  });

  const handleOpenDetails = (index: number) => {
    setSelectedInviteIndex(index);
    setOpenDetails(true);
  };

  const onSubmit = (data: number) => {
    openNotification({
      state: "info",
      title: "Wait a second ...",
      description: <LoadingOutlined />,
    });
    mutate(
      { listing_invite_id: data, token },
      {
        onError: (err: any) => {
          openNotification({
            title: "Error",
            state: "error",
            description: "Error occurred try again",
            duration: 4.5,
          });
        },
        onSuccess: (res: any) => {
          openNotification({
            title: "Success",
            state: "success",
            description: "Job offer sent successfully",
            duration: 4.5,
          });
        },
      }
    );
  };

  return (
    <Skeleton loading={isLoading} active>
      {inviteData?.map((item: ITalentInvites, index) => (
        <>
          <div className="border rounded-md shadow px-3 py-4 my-4 gap-y-5 flex md:flex-row flex-col justify-between">
            <div className="capitalize">
              <h5 className="text-sm font-semibold text-primary">
                {getStatusText(item.status)}
              </h5>
              <h1 className="text-base font-medium  pb-3 pt-1">
                {item.listing.title}
              </h1>
              <div
                className="flex items-center gap-x-1"
                style={{ color: "#667085" }}
              >
                <i className="ri-time-line"></i>
                <span>{item.listing.expiry_date}</span>
              </div>
            </div>

            <div className="flex flex-col justify-between">
              <div>
                <AppButton
                  label="Details"
                  handleClick={() => handleOpenDetails(index)}
                />
              </div>
              <h4
                onClick={() => onSubmit(item.id)}
                className="text-primary font-semibold pt-2 cursor-pointer hover:text-secondary"
              >
                Send Job Offer
              </h4>
            </div>
          </div>
          {/* details */}
          <Modal
            open={openDetails}
            title="Invite Details"
            style={{ top: 15 }}
            width="40rem"
            footer={false}
            onCancel={() => setOpenDetails(false)}
          >
            {selectedInviteIndex !== -1 && (
              <div>
                <div className="flex flex-col gap-4">
                  <div>
                    <h4>Title</h4>
                    <h3 className="text-base font-medium">
                      {inviteData[selectedInviteIndex].listing.title}
                    </h3>
                  </div>
                  <div>
                    <h4>Sender</h4>
                    <h3 className="text-base font-medium text-primary">
                      {inviteData[selectedInviteIndex].listing.company_name}
                    </h3>
                  </div>

                  <div>
                    <h3 className="text-base pb-1">Description</h3>
                    <p className="text-sm">
                      {inviteData[selectedInviteIndex].listing.description}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </Modal>
        </>
      ))}
    </Skeleton>
  );
};
