import axios from "axios";
import { END_POINT } from "config/environment";


export const sendTokenToServer = async (props: any) => {
    const url = `${END_POINT.BASE_URL}/v1/sync-fcm-token`;

    const config = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${props.authToken}`,
        },
        };
    const data = { ...props };
    const response = await axios.post(url, data, config);
    return response;
};