import { useCreatePackage } from "admin/finance/hooks/useCreatePackage";
import { IPackage } from "admin/finance/types";
import { Form, Input, InputNumber, Modal, Radio, Select, Checkbox } from "antd";
import { AppButton } from "components/button/AppButton";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useQueryClient } from "react-query";
import { IdentifierProps } from "types";
import { generalValidationRules } from "utils/formHelpers/validations";
import { openNotification } from "utils/notification";
import { QUERY_KEY_FOR_PACKAGE } from "../hooks/useGetPackage";
import { useState } from "react";

export const AddPackage = ({ open, handleClose }: IdentifierProps) => {
  const [form] = Form.useForm();
  const { token } = useGetUserInfo();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useCreatePackage();
  const [useEnterprise, setUseEnterprise] = useState(false);
  const [accountType, setAccountType] = useState<string | undefined>();

  const handleAccountTypeChange = (e: any) => {
    setAccountType(e.target.value);
    if (e.target.value !== "recruiter") {
      setUseEnterprise(false);
    }
  };

  const handleUseEnterpriseChange = (e: any) => {
    setUseEnterprise(e.target.checked);
  };

  const handleSubmit = (data: any) => {
    const props: IPackage = {
      name: data.name,
      description: data.description,
      monthly: data.monthly,
      quarterly: data.quarterly,
      biannually: data.biannually,
      annually: data.annually,
      account_type: data.account_type,
      token,
      use_enterprise: data.use_enterprise,
      enterprise_discount: data.enterprise_discount,
    };

    mutate(props, {
      onError: (err: any) => {
        let errorTitle = "Error";
        let errorDescription = "An error occurred, please try again.";

        if (err.response && err.response.data && err.response.data.error) {
          errorTitle = "Error: " + err.response.data.error;
          errorDescription =
            err.response.data.description || "Please try again.";
        }

        openNotification({
          title: errorTitle,
          state: "error",
          description: errorDescription,
          duration: 6,
        });
      },
      onSuccess: (res: any) => {
        openNotification({
          title: "Success",
          state: "success",
          description: "Package Created Successfully",
          duration: 4.5,
        });
        form.resetFields();
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_FOR_PACKAGE],
        });
        handleClose();
      },
    });
  };

  return (
    <Modal
      title="Add Package"
      open={open}
      onCancel={() => handleClose(false)}
      footer={null}
      width="45rem"
      style={{ top: 10 }}
    >
      <div>
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={handleSubmit}
          form={form}
          initialValues={{ is_current: false }}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5">
            <Form.Item name="name" label="Name" rules={generalValidationRules}>
              <Input placeholder="Enter Package Name" />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={generalValidationRules}
            >
              <Input placeholder="Enter Package Description" />
            </Form.Item>

            <Form.Item
              name="type"
              label="Billing Cycle"
              rules={generalValidationRules}
            >
              <Select allowClear placeholder="Billing Cycle">
                <option value="monthly">Monthly</option>
                <option value="quarterly">Quarterly</option>
                <option value="biannually">Biannually</option>
                <option value="annually">Annually</option>
              </Select>
            </Form.Item>

            <Form.Item
              name="amount"
              label="Amount"
              rules={generalValidationRules}
            >
              <InputNumber<number>
                className="w-full"
                formatter={(value) =>
                  `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) =>
                  value?.replace(/\₦\s?|(,*)/g, "") as unknown as number
                }
              />
            </Form.Item>

            <Form.Item
              name="account_type"
              label="Account Type"
              className="-mt-3 col-span-2"
              rules={generalValidationRules}
            >
              <Radio.Group onChange={handleAccountTypeChange}>
                <Radio value="talent">Talent</Radio>
                <Radio value="recruiter">Recruiter</Radio>
                <Radio value="enterprise">Enterprise</Radio>
              </Radio.Group>
            </Form.Item>

            {accountType === "recruiter" && (
              <Form.Item
                name="use_enterprise"
                valuePropName="checked"
                className="col-span-2"
              >
                <Checkbox onChange={handleUseEnterpriseChange}>
                  Available to Enterprise
                </Checkbox>
              </Form.Item>
            )}

            {useEnterprise && (
              <Form.Item
                name="enterprise_discount"
                label="Enterprise Discount in Percentage"
                rules={generalValidationRules}
                className="col-span-2"
              >
                <InputNumber<number>
                  className="w-full"
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) =>
                    value?.replace("%", "") as unknown as number
                  }
                />
              </Form.Item>
            )}
          </div>
          <div className="flex">
            <AppButton type="submit" isLoading={isLoading} />
          </div>
        </Form>
      </div>
    </Modal>
  );
};
